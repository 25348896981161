import {buildCompanyDetails} from "../factory/companyDetailsFactory";
import {COMPANY_DETAILS} from "../reducers/companyDetailsReducer";
import {getCompanyDetails} from "../util/APIHelper/queries";

export const findCompanyDetails = companyId => async dispatch => {
    try {
        let response = await getCompanyDetails(companyId);

        if (response.data) {
            let companyInfo = response.data.companyInfo;
            let companyDetailsObj = buildCompanyDetails(companyInfo.companyId, companyInfo.companyName,
                companyInfo.logo, companyInfo.countryShortCode, companyInfo.language, companyInfo.username, companyInfo.password, companyInfo.email, companyInfo.adminId, companyInfo.accountPlan, companyInfo.currency, companyInfo.currencyCode,
                companyInfo.isDeliveryOnly, companyInfo.address, companyInfo.fixedPickupPhone);

            dispatch({
                type: COMPANY_DETAILS,
                payload: companyDetailsObj
            })
        }
    } catch (e) {
        console.error(e);
        let companyDetailsObj = buildCompanyDetails(-1, "", -1, -1, -1, -1, -1, -1, -1 , -1);
        dispatch({
            type: COMPANY_DETAILS,
            payload: companyDetailsObj
        })

    }
}


export const COMPANY_DETAILS = "COMPANY_DETAILS";

export default (state = null, action) => {
    switch (action.type) {
        case COMPANY_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Selector from "../../common/components/selector";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import AddressInput from "../../common/components/addressInput";
import TimeInput from "../../common/components/timeInput";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import EmailInput from "../../common/components/emailInput";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import DateInput from "../../common/components/dateInput";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import TextAreaInput from "../../common/components/textAreaInput";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import ValueInput from "../../common/components/valueInput";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {useDispatch} from "react-redux";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";
import {HANDLER_ADDITIONAL_ADDRESS} from "../../../reducers/additionalAddressInfo";
import {HANDLER_ORDER_NUMBER} from "../../../reducers/orderNumberReducer";
import RememberInfo from "../../common/components/rememberInfo";
import {HANDLER_REMEMBER_INFO} from "../../../reducers/rememberInfoReducer";
import {HANDLER_TOTAL_ORDER_COST} from "../../../reducers/totalOrderCostReducer";


const CustomOrderInput = ({configJSON, loading}) => {
    const {t} = useTranslation();
    return (
        <>
            <div>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.PICK_UP_FORM)}/>
                    <NameInput config={{handlerType: HANDLER_ORDER_NUMBER, required: false, placeholder: "Order Number"}}/>
                    <NameInput config={{handlerType: HANDLER_RESTAURANT_NAME, required: true, placeholder: "Name", prepopulate: true}}/>
                    <NameInput config={{handlerType: HANDLER_RESTAURANT_PHONE_NUMBER, required: true, placeholder: "Phone No", prepopulate: true}}/>
                    <AddressInput config={{handlerType: HANDLER_RESTAURANT_ADDRESS, required: true, placeholder: "Address", prepopulate: true}}/>
                    <RememberInfo config={{handlerType: HANDLER_REMEMBER_INFO, required: false, label: "Remember Pick-up information"}}/>
                </Card>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.DELIVER_TO)}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_NAME, required: true, placeholder: "Name"}}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_PHONE_NUMBER, required: true, placeholder: "Phone number"}}/>
                    <AddressInput config={{handlerType: HANDLER_CUSTOMER_ADDRESS, required: true, placeholder: "Address"}}/>
                    <TextAreaInput config={{handlerType: HANDLER_DELIVERY_INSTRUCTION, required: true, placeholder: "Order Details"}}/>
                </Card>
                <Card>
                    <CardTitle title="Information for Delivery Driver"/>
                    <TimeInput config={{handlerType: HANDLER_EXPECTED_PICKUP_TIME, required: true, placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME)}}/>
                    <ValueInput config={{handlerType: HANDLER_TOTAL_ORDER_COST, required: true, placeholder: "Order price"}}/>

                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && t(FORM_LOCALE_KEYS.CHECKOUT)}
                </button>
            </div>
        </>
    )
}

export default CustomOrderInput;

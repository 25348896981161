import React from "react";
import {dateTimeFormattedAsString} from "../../../util/defaultTimeHelper";

const DetailsSection = ({address, name, phone, email, time, date}) => {
    return (
        <div className="secondary-text">
            <div className="d-flex flex-direction-row">
                <i className="d-flex align-items-center bi bi-geo-alt"></i>
                <span className="d-flex align-items-center fw-bold ms-2">{address}</span>
            </div>
            {name && <span className="ms-4 secondary-text">{name}</span>}
            <span className="ms-2 secondary-text"><i className="bi bi-telephone me-1"></i>{phone}</span>
            {email && <span className="ms-4 secondary-text text-truncate"><i className="bi bi-envelope me-1"></i>{email}</span>}
            <div className="d-flex flex-direction-row mt-2">
                <i className="d-flex align-items-center bi bi-clock"></i>
                <span className="d-flex align-items-center fw-bold ms-2">{dateTimeFormattedAsString(date,time)}</span>
            </div>
        </div>
    )
}

export default DetailsSection;

export const HANDLER_DELIVERY_FEE = "HANDLER_DELIVERY_FEE";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_DELIVERY_FEE:
            return action.payload;
        default:
            return state;
    }
}


export const HANDLER_RESTAURANT_ADDRESS = "HANDLER_RESTAURANT_ADDRESS";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_RESTAURANT_ADDRESS:
            return action.payload;
        default:
            return state;
    }
}



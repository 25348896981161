import React from "react";
import {useSelector} from "react-redux";

const CompanyLogo = () => {
    const companyDetails = useSelector(state => state.companyDetails);

    if (companyDetails == null) return <></>;

    const companyLogo = companyDetails.getCompanyLogo();
    const companyName = companyDetails.getCompanyName();
    return (
        <div>
            <img src={companyLogo} className="rounded mx-auto d-block companyLogo" alt={companyName}/>
        </div>
    )
}

export default CompanyLogo;

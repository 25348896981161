import React, {useEffect} from "react";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import PickupDetailsSection from "../../common/paidComponents/pickupDetailsSection";
import DeliveryDetailsSection from "../../common/paidComponents/deliveryDetailsSection";
import {CustomOrderDetails} from "./customOrderDetails";
import StripeWrapper from "../../common/paymentComponent/stripeWrapper";
import {formDataMapper} from "../../../util/formDataMapper";
import {ensureRequiredFields} from "../../../util/formBuilderHelper";
import useStateMapper from "../../customHooks/stateMapperHook";
import {storeInCache} from "../../../util/localCache";
import {useDispatch, useSelector} from "react-redux";
import {HANDLER_ORDER_NUMBER} from "../../../reducers/orderNumberReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";


const CustomOrderPaymentPageBuilder = ({configJSON, visible}) => {
    const [stateMapper, mailDataGenerator, updateLocationInfoInData] = useStateMapper();
    const companyDetails = useSelector(state => state.companyDetails);
    const estimatedInfo = useSelector(state => state.estimatedInfo);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(()=>{
        if(visible) {
            let handlers = configJSON;
            let data = {};
            handlers.map((prop) => {
                let dataPropName = formDataMapper("simpleForm", prop);
                if (dataPropName) data[dataPropName] = stateMapper(prop);
            });
            data["totalOrderCost"] = estimatedInfo.getChargeAmountInDollar();
            data = ensureRequiredFields(data);
            data = updateLocationInfoInData(data);
            storeInCache(data["orderNumber"], JSON.stringify(data));
            storeInCache("orderHeader", companyDetails.getConstructedHeaderKey());
            dispatch({
                type: HANDLER_ORDER_NUMBER,
                payload: data["orderNumber"]
            });
        }
    },[visible]);
    return (
        <div className="">
            <Card>
                <CardTitle title={t(FORM_LOCALE_KEYS.PICKUP_DETAILS)}></CardTitle>
                <PickupDetailsSection/>
                <hr className="hr-line"/>
                <CardTitle title={t(FORM_LOCALE_KEYS.DELIVERY_DETAILS)}/>
                <DeliveryDetailsSection/>
                <hr className="hr-line"/>
                <CardTitle title={t(FORM_LOCALE_KEYS.ORDER_DETAILS)}/>
                <CustomOrderDetails />
            </Card>
            <StripeWrapper/>
        </div>
    )
}

export default CustomOrderPaymentPageBuilder;
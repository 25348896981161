export const HANDLER_RESTAURANT_PHONE_NUMBER = "HANDLER_RESTAURANT_PHONE_NUMBER";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_RESTAURANT_PHONE_NUMBER:
            return action.payload;
        default:
            return state;
    }
}


import React from "react";
import CompanyLogo from "./components/companyLogo";
import CompanyName from "./components/companyName";
import FormBuilder from "./formBuilder";
import OrderAlerts from "./components/orderAlerts";
import {useSelector} from "react-redux";
import LoadingPage from "./loadingPage";
import AccessDeniedPage from "./accessDeniedPage";
import CompanyDetailsNotFoundPage from "./companyDetailsNotFoundPage";

const FormPageWrapper = ({FullFormConfigJSON, emailTemplateFunc, orderPlacementBtnText, preProcessFunc = null}) => {
    const companyDetails = useSelector(state => state.companyDetails);

    if(companyDetails === null) return <LoadingPage FullFormConfigJSON={FullFormConfigJSON} orderPlacementBtnText={orderPlacementBtnText}/>

    if(companyDetails !== null && companyDetails?.companyId === -1) return <CompanyDetailsNotFoundPage/>

    if(companyDetails !== null && !companyDetails.hasBrandedAccess()) return <AccessDeniedPage/>


    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-6 offset-lg-3 pt-5">
                    <CompanyLogo/>
                    <CompanyName/>
                    <FormBuilder 
                        formJSON={FullFormConfigJSON} 
                        emailTemplateFunc={emailTemplateFunc} 
                        orderPlacementBtnText={orderPlacementBtnText}
                        preProcessFunc={preProcessFunc}
                    />
                    <OrderAlerts/>
                </div>
            </div>
        </div>
    )
}

export default FormPageWrapper;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";

import {
    DATE_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    LAYOUT_TANGLE_WITH_NEXT,
    REMEMBER_SECTION,
    sectionTitleJSONCreator,
    SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { HANDLER_ORDER_NUMBER } from "../../reducers/orderNumberReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { HANDLER_REMEMBER_INFO } from "../../reducers/rememberInfoReducer";
import { useTranslation } from "react-i18next";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";
import { HANDLER_EXPECTED_DELIVERY_TIME } from "../../reducers/expectedDeliveryTimeReducer";
import { courierEmailTemplate1 } from "../../util/emailTemplates/courierEmailTemplate1";
import { HANDLER_TOTAL_ORDER_COST } from "../../reducers/totalOrderCostReducer";
import { HANDLER_PAYMENT_METHOD } from "../../reducers/paymentMethodReducer";
import { HANDLER_EXPECTED_DELIVERY_DATE } from "../../reducers/expectedDeliveryDateReducer";

// for whiteswade@gmail.com

const OrderForm27 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormSpecialJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_FORM)),
            sectionFields: [
                
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER, null, true),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true),
                fieldJSONCreator(REMEMBER_SECTION, t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO), '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.ORDER_NUMBER), 'ex. 1234', true, LAYOUT_DEFAULT, HANDLER_ORDER_NUMBER),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TIME_INPUT_FIELD, "Time For Collection", '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_EXPECTED_DELIVERY_TIME),
                fieldJSONCreator(DATE_INPUT_FIELD, "Date For Collection", '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_DELIVERY_DATE),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Payment Option", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_PAYMENT_METHOD, [
                    {label: "Paid", value: "credit_card"},
                    {label: "Cash On Delivery", value: "cash"},
                ]),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Amount (if cash on delivery)", '', false, LAYOUT_DEFAULT, HANDLER_TOTAL_ORDER_COST),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    const template = onlyDispatcherMail(courierEmailTemplate1);

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormSpecialJson}
                             emailTemplateFunc={template}/>
        </>
    )
}

export default OrderForm27;

import {HANDLER_ORDER_NUMBER} from "../reducers/orderNumberReducer";
import {HANDLER_CUSTOMER_NAME} from "../reducers/customerNameReducer";
import {HANDLER_RESTAURANT_NAME} from "../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../reducers/restaurantAddressReducer";
import {HANDLER_RESTAURANT_EMAIL} from "../reducers/restaurantEmailReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../reducers/restaurantPhoneNumberReducer";
import {HANDLER_ORDER_ITEMS} from "../reducers/orderItemsReducer";
import {HANDLER_DELIVERY_FEE} from "../reducers/deliveryFeeReducer";
import {HANDLER_TIPS} from "../reducers/tipsReducer";
import {HANDLER_TOTAL_ORDER_COST} from "../reducers/totalOrderCostReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../reducers/deliveryInstructionReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../reducers/expectedDeliveryTimeReducer";
import {HANDLER_DELIVERY_ADDRESS_TYPE} from "../reducers/deliveryAddressTypeReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../reducers/expectedPickupTimeReducer";
import {HANDLER_PICKUP_ADDRESS_TYPE} from "../reducers/pickupAddressTypeReducer";
import {HANDLER_EXPECTED_PICKUP_DATE} from "../reducers/expectedPickUpDateReducer";
import {HANDLER_RESTAURANT_CONTACT_NAME} from "../reducers/restaurantContactNameReducer";
import {HANDLER_PICKUP_COMPANY_NAME} from "../reducers/pickUpCompanyNameReducer";
import {HANDLER_RESTAURANT_LAST_NAME} from "../reducers/restaurantLastNameReducer";
import {HANDLER_SERVICE_TYPE} from "../reducers/serviceTypeReducer";
import {HANDLER_ITEM_TYPE} from "../reducers/itemTypeReducer";
import {HANDLER_ITEM_SIZE} from "../reducers/itemSizeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../reducers/numberOfItemsReducer";
import {HANDLER_PAYMENT_METHOD} from "../reducers/paymentMethodReducer";
import {HANDLER_TAX} from "../reducers/taxReducer";
import {HANDLER_AREA_NAME} from "../reducers/customerAreaNameReducer";
import {HANDLER_BLOCK_NUMBER} from "../reducers/customerBlockNumberReducer";
import {HANDLER_FLAT_NUMBER} from "../reducers/customerFlatNumberReducer";
import {HANDLER_ROAD_NUMBER} from "../reducers/customerRoadNumberReducer";
import {HANDLER_ADDITIONAL_ADDRESS} from "../reducers/additionalAddressInfo";
import {HANDLER_VILLA_NUMBER} from "../reducers/customerVillaNumberReducer";
import { HANDLER_CUSTOMER_LAST_NAME } from "../reducers/customerLastNameReducer";
import { HANDLER_CUSTOMER_TAIL_NUMBER } from "../reducers/customerTailNumberReducer";
import { HANDLER_NUMBER_OF_EXTRA_ITEMS } from "../reducers/numberOfExtraItemsReducer";

export const formDataMapper = (formName, handlerName) => {
    switch (handlerName) {
        case HANDLER_ORDER_NUMBER:
            return "orderNumber";
        case HANDLER_CUSTOMER_NAME:
            return "customerName";
        case HANDLER_CUSTOMER_LAST_NAME:
            return "customerLastName";
        case HANDLER_CUSTOMER_EMAIL:
            return "customerEmail";
        case HANDLER_CUSTOMER_PHONE_NUMBER:
            return "customerPhoneNumber";
        case HANDLER_CUSTOMER_TAIL_NUMBER:
            return "customerTailNumber"
        case HANDLER_PICKUP_COMPANY_NAME:
            return "pickUpCompanyName";
        case HANDLER_CUSTOMER_ADDRESS:
            return "customerAddress";
        case HANDLER_RESTAURANT_NAME:
            return "restaurantName";
        case HANDLER_RESTAURANT_LAST_NAME:
            return "restaurantLastName";
        case HANDLER_RESTAURANT_CONTACT_NAME:
            return "restaurantContactName";
        case HANDLER_RESTAURANT_EMAIL:
            return "restaurantEmail";
        case HANDLER_RESTAURANT_ADDRESS:
            return "restaurantAddress";
        case HANDLER_RESTAURANT_PHONE_NUMBER:
            return "restaurantPhoneNumber";
        case HANDLER_ORDER_ITEMS:
            return "orderItem";
        case HANDLER_DELIVERY_FEE:
            return "deliveryFee";
        case HANDLER_TIPS:
            return "tips";
        case HANDLER_SERVICE_TYPE:
            return "serviceType";
        case HANDLER_ITEM_TYPE:
            return "itemType";
        case HANDLER_ITEM_SIZE:
            return "itemSize";
        case HANDLER_NUMBER_OF_ITEMS:
            return "numberOfItems";
        case HANDLER_TOTAL_ORDER_COST:
            return "totalOrderCost";
        case HANDLER_DELIVERY_INSTRUCTION:
            return "deliveryInstruction";
        case HANDLER_EXPECTED_DELIVERY_DATE:
            return "expectedDeliveryDate";
        case HANDLER_EXPECTED_DELIVERY_TIME:
            return "expectedDeliveryTime";
        case HANDLER_EXPECTED_PICKUP_TIME:
            return "expectedPickupTime";
        case HANDLER_EXPECTED_PICKUP_DATE:
            return "expectedPickupDate";
        case HANDLER_DELIVERY_ADDRESS_TYPE:
            return "delivery_address_type";
        case HANDLER_PICKUP_ADDRESS_TYPE:
            return "pickup_address_type";
        case HANDLER_PAYMENT_METHOD:
            return "paymentMethod";
        case HANDLER_AREA_NAME:
            return "areaName";
        case HANDLER_BLOCK_NUMBER:
            return "blockNumber";
        case HANDLER_FLAT_NUMBER:
            return "flatNumber";
        case HANDLER_ROAD_NUMBER:
            return "roadNumber";
        case HANDLER_ADDITIONAL_ADDRESS:
            return "additionalAddress";
        case HANDLER_VILLA_NUMBER:
            return "villaNumber";
        case HANDLER_TAX:
            return "tax";
        case HANDLER_NUMBER_OF_EXTRA_ITEMS:
            return "numberOfExtraItems";
    }
}

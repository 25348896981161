import {getStripeConnectStatus} from "../../util/APIHelper/queries";
import {stripeConnectDetails} from "../../factory/paymentDetailsFactory";
import {STRIPE_DETAILS} from "../../reducers/stripeDetailsReducer";

export const checkStripeConnectionStatus = (adminId) => async dispatch => {
    try {
        let response = await getStripeConnectStatus(adminId);
        if(response.data) {
            let stripeDetails = stripeConnectDetails(response.data.dispatcherId, response.data.accountId, response.data.stripeEmail, response.data.connectStatus);
            dispatch({
                type: STRIPE_DETAILS,
                payload: stripeDetails
            })
        }
    }catch (e) {
        dispatch({
            type: STRIPE_DETAILS,
            payload: null
        })
    }
}

import React, {useState} from "react";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import AddressInput from "../../common/components/addressInput";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import TimeInput from "../../common/components/timeInput";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_EXPECTED_PICKUP_DATE} from "../../../reducers/expectedPickUpDateReducer";
import TextAreaInput from "../../common/components/textAreaInput";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import DateInput from "../../common/components/dateInput";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";
import {HANDLER_TOTAL_ORDER_COST} from "../../../reducers/totalOrderCostReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {useSelector} from "react-redux";
import RememberInfo from "../../common/components/rememberInfo";
import {HANDLER_REMEMBER_INFO} from "../../../reducers/rememberInfoReducer";


const OrderDetailsInputBuilder = ({configJSON, loading}) => {
    const {t} = useTranslation();
    const companyInfo = useSelector(state => state.companyDetails);
    const deliveryOnly = companyInfo?.isDeliveryOnly();
    const deliveryAddress = companyInfo?.getDeliveryOnlyAddress();
    const deliveryPhone = companyInfo?.getDeliveryOnlyPhone();
    const companyName = companyInfo?.getCompanyName();
    return (
        <>
            <div>
                 <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.PICK_UP_FORM)}/>
                    <NameInput deliveryOnly={deliveryOnly} info={companyName} config={{handlerType: HANDLER_RESTAURANT_NAME, required: true, placeholder: t("Name of business")}}/>
                    <NameInput deliveryOnly={deliveryOnly} info={deliveryPhone} config={{handlerType: HANDLER_RESTAURANT_PHONE_NUMBER, required: true, placeholder: t("Phone Number")}}/>
                    <AddressInput deliveryOnly={deliveryOnly} info={deliveryAddress} config={{handlerType: HANDLER_RESTAURANT_ADDRESS, required: true, placeholder: t("Address")}}/>
                    <DateInput config={{handlerType: HANDLER_EXPECTED_PICKUP_DATE, required: true, placeholder: t("Pickup date")}}/>
                     <RememberInfo config={{handlerType: HANDLER_REMEMBER_INFO, required: false,label:t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO) ,placeholder: t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO)}}/>
                </Card>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.DELIVER_TO)}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_NAME, required: true, placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_RECIPIENT)}}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_PHONE_NUMBER, required: true, placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_RECIPIENT)}}/>
                    <AddressInput config={{handlerType: HANDLER_CUSTOMER_ADDRESS, required: true, placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_RECIPIENT)}}/>
                    <TextAreaInput config={{handlerType: HANDLER_DELIVERY_INSTRUCTION, required: true, placeholder: t(FORM_LOCALE_KEYS.DELIVERY_NOTE)}}/>
                </Card>
                <Card>
                    <CardTitle title={t("Item and Service Type")}/>
                    <NameInput config={{handlerType: HANDLER_ITEM_TYPE, required: true, placeholder: t("Item Type")}}/>
                    <NameInput config={{handlerType: HANDLER_ITEM_SIZE, required: true, placeholder: t("Item Size")}}/>
                    <NameInput config={{handlerType: HANDLER_NUMBER_OF_ITEMS, required: true, placeholder: t("Number of Items")}}/>
                    <NameInput config={{handlerType: HANDLER_TOTAL_ORDER_COST, required: true, placeholder: t("Amount")}}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && t(FORM_LOCALE_KEYS.CHECKOUT)}
                </button>
            </div>
        </>
    )
}

export default OrderDetailsInputBuilder;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    fieldJSONCreator, LAYOUT_DEFAULT, sectionTitleJSONCreator, TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD, VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { HANDLER_CUSTOMER_NAME } from "../../reducers/customerNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { HANDLER_TOTAL_ORDER_COST } from "../../reducers/totalOrderCostReducer";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { OrderEmailTemplate4B } from "../../util/emailTemplates/orderEmailTemplate4B";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";
import { useDeliveryCompanyInfos } from "../customHooks/deliveryComapnyInfosHook";
import { HANDLER_NUMBER_OF_EXTRA_ITEMS } from "../../reducers/numberOfExtraItemsReducer";

// for eduardo@enviapp.com.gt

const OrderForm34 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [pickupName, pickupAddress, pickupPhone] = useDeliveryCompanyInfos();

    const orderForm4BJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_FORM)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Nombre de recogida", '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, null, true, {initialValue: pickupName}),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Telefono", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER, null, true, {initialValue: pickupPhone}),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Direccion", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true, {initialValue: pickupAddress}),
                fieldJSONCreator(TEXTAREA_FIELD, "Referencia de dirección y notas adicionales", '', true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Nombre de cliente", '', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Teléfono de cliente", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Dirección", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXTAREA_FIELD, "Referencia de dirección y notas adicionales", '', true, LAYOUT_DEFAULT, HANDLER_NUMBER_OF_EXTRA_ITEMS),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Total a cobrar", '00', true, LAYOUT_DEFAULT, HANDLER_TOTAL_ORDER_COST),
            ]
        },
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, []);

    const template = onlyDispatcherMail(OrderEmailTemplate4B)

    const preProcessFunc = (data) => {
        data['deliveryInstruction'] = `recogida: ${data["deliveryInstruction"]}\n; cliente: ${data["numberOfExtraItems"]}`;
        delete data['numberOfExtraItems'];
        return data;
    }

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderForm4BJson} emailTemplateFunc={template} preProcessFunc={preProcessFunc}/>
        </>
    )
}

export default OrderForm34;

import React from "react";

const SectionTitle = ({config}) => {
    if (typeof config === "undefined") return null;

    return (
        <h5 className="pt-4 pb-1">{config.title}</h5>
    )
}

export default SectionTitle;

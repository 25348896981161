export const HANDLER_FLAT_NUMBER = "HANDLER_FLAT_NUMBER";

export default (state = "", action) => {
    switch (action.type) {
        case HANDLER_FLAT_NUMBER:
            return action.payload;
        default:
            return state;
    }
}

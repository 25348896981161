import {ClientSecret, EstimatedInfo, StripeDetails} from "../model/paymentDetailsModels";

export const buildClientSecret = (clientSecret) => {
    return new ClientSecret(clientSecret);
}

export const buildEstimation = (distance, chargeAmount, applicationFee, distanceUnit, maxDistanceLimit) => {
    return new EstimatedInfo(distance, chargeAmount, applicationFee, distanceUnit, maxDistanceLimit);
}

export const stripeConnectDetails = (adminId, accountId, stripeEmail, connectStatus) => {
    return new StripeDetails(adminId, accountId, stripeEmail, connectStatus);
}


export class ClientSecret {
    constructor(clientSecret) {
        this.clientSecret = clientSecret
    }

    getClientSecret() {
        return this.clientSecret;
    }
}

export class StripeDetails{
    constructor(adminId, accountId, stripeEmail, connectionStatus) {
        this.adminId = adminId;
        this.accountId = accountId;
        this.stripeEmail = stripeEmail;
        this.connectionStatus = connectionStatus;
    }

    getAccountId() {
        return this.accountId;
    }

    getAdminId() {
        return this.adminId;
    }

    isConnected() {
        return this.connectionStatus === "CONNECTED";
    }
}

export class EstimatedInfo {
    constructor(distance = 0, chargeAmount, applicationFee, distanceUnit = "Miles", maxDistanceLimit = 0) {
        this.distance = Number(distance.toFixed(2));
        this.chargeAmount = Number(chargeAmount);
        this.applicationFee = Number(applicationFee);
        this.distanceUnit = distanceUnit;
        this.maxDistanceLimit = Number(maxDistanceLimit)
    }

    getApplicationFee() {
        return this.applicationFee;
    }

    getChargeAmountInCents() {
        return this.chargeAmount;
    }

    getChargeAmountInDollar() {
        return Number(Number(this.chargeAmount/100).toFixed(2));
    }

    getDistanceUnit() {
        return this.distanceUnit
    }

    isWithinLimit() {
        if(isNaN(this.maxDistanceLimit)) return true;
        if(this.maxDistanceLimit <= 0) return true;
        return this.distance <= this.maxDistanceLimit;
    }
}

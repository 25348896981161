import React, {useEffect, useRef, useState} from "react";
import FieldLabel from "./fieldLabel";
import {useDispatch, useSelector} from "react-redux";
import {getFromCache} from "../../../util/localCache";
import PinDropMap from "../../../util/addressPlugin/address.pin.plug";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {PICK_UP_LOCATION_LAT_LNG} from "../../../reducers/pickUpLocationReducer";
import {DELIVERY_LOCATION_LAT_LNG} from "../../../reducers/deliveryLocationReducer";

const delayedAction = (update) => {
    return () => {
        setTimeout(()=>{
            update();
        }, 500)
    }
}

const addressPlugIn = new PinDropMap();
const AddressInput = ({config, deliveryOnly = false, info}) => {
    const [predictions, setPredictions] = useState([]);
    const companyDetails = useSelector(state => state.companyDetails);
    let countryCode = "";
    if (companyDetails) countryCode = companyDetails.getCountryCode();
    const dispatch = useDispatch();
    const [input, setInput] = useState('');
    const [selectedPlaceDetail, addSelectedPlaceDetail] = useState({})
    const predictionsRef = useRef();
    const inputFieldRef = useRef();
    const inputParentRef = useRef();
    const autoCompleteService = new window.google.maps.places.AutocompleteService();
    const restrictCountry = config?.additionalFunction?.restrictCountry ?? true;

    useEffect(() => {
        addressPlugIn.pinDropLocationHandler.findCountryDefaultPin(countryCode);
        if(inputParentRef.current.childElementCount === 1) {
            addressPlugIn.addBtn(config.handlerType, function (lat, lng, shortAddr) {
                inputFieldRef.current.value = shortAddr;
                if(config.handlerType === HANDLER_RESTAURANT_ADDRESS) {
                    dispatch({
                        type: PICK_UP_LOCATION_LAT_LNG,
                        payload: {lat: lat, lng: lng}
                    })
                } else {
                    dispatch({
                        type: DELIVERY_LOCATION_LAT_LNG,
                        payload: {lat: lat, lng: lng}
                    })
                }
                dispatch({
                    type: config.handlerType,
                    payload: shortAddr
                })
            })
        }
    }, []);

    useEffect(()=>{
        if(countryCode){
            addressPlugIn.pinDropLocationHandler.findCountryDefaultPin(countryCode);
        }
    },[countryCode])

    useEffect(() => {
        let destructor = undefined;
        if (config.prepopulate || config?.additionalFunction?.initialValue) {
            const update = createInitialUpdater(config.prepopulate, config?.additionalFunction?.initialValue);
            const delayedUpdate = delayedAction(update);
            update();
            document.getElementById("externalOrderForm").addEventListener("reset", delayedUpdate);
            destructor = () => document.getElementById("externalOrderForm").removeEventListener("reset", delayedUpdate);
        } else if(deliveryOnly) {
            inputFieldRef.current.value = info;
            setInput(info);
            dispatch({type: config.handlerType, payload: info});
            inputFieldRef.current.disabled = true;
        }
        return destructor;
    }, [config?.prepopulate, config?.additionalFunction?.initialValue, deliveryOnly]);

    useEffect(
        () => {
            if(!deliveryOnly) {
                try {
                    const componentRestrictions = {}
                    if(restrictCountry) {
                        componentRestrictions['country'] = countryCode;
                    }
                    autoCompleteService.getPlacePredictions({
                        input,
                        componentRestrictions,
                    }, predictions => {
                        setPredictions(predictions);
                    });
                    if (input === "" || input == null) setPredictions([]);
                } catch (err) {
                    console.error(err);
                }
                dispatch({
                    type: config.handlerType,
                    payload: input
                })
            }
        }, [input, companyDetails]
    )

    const createInitialUpdater = (cachePopulate, initialValue) => () => {
        let value = "";
        if(cachePopulate && getFromCache(config.handlerType)) value = value || getFromCache(config.handlerType);
        if(initialValue) value = value || initialValue
        inputFieldRef.current.value = value
        dispatch({type: config.handlerType, payload: inputFieldRef.current.value});
    }

    const handleAutoCompleteSelectedDirectPaste = (fromDescription) => {
        inputFieldRef.current.value = fromDescription;
        dispatch({
            type: config.handlerType,
            payload: fromDescription
        })
        setPredictions([]);
    }

    return (
        <div>
            <FieldLabel config={config}/>
            <div className="d-flex" ref={inputParentRef}>
                <textarea id={config.handlerType} ref={inputFieldRef} className="form-control" onChange={(e) => {
                    if (e.currentTarget.value !== input) setInput(e.currentTarget.value)
                }}
                          placeholder={config.placeholder} required={config.required || false} rows={1}/>
            </div>
            <div ref={predictionsRef} className="list-group shadow-sm rounded">
                {predictions && predictions.map((prediction, ind) => <li
                    className="list-group-item list-group-item-action" key={ind}
                    onClick={() => handleAutoCompleteSelectedDirectPaste(prediction.description)}> {prediction.description} </li>)
                }
            </div>
        </div>
    )
}

export default AddressInput;

import React from 'react'
import PaidFormLogoSection from './paidFormLogoSection';
import Card from './card';

export default function StripeNotConnected({children}) {
    return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <PaidFormLogoSection showEdit={false}
                                 backToEdit={() => {}}/>
            <div className="col-sm-6 offset-sm-3">
                <Card>
                    <h4 className="text-center">Stripe not connected for this account</h4>
                    <h6 className="text-center ">Please contact provider for further information</h6>
                </Card>
            </div>
            <div aria-disabled={true} disabled={true}>
                <form className={`col-sm-6 offset-sm-3 pb-5`} onSubmit={() => {}}
                      aria-disabled={true} aria-readonly={true}>
                    {children}
                </form>
            </div>
        </div>
    );
}

import React, {useRef} from "react";
import {useDispatch} from "react-redux";

const RememberInfo = ({config}) => {
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    if (typeof config === "undefined") {
        return null;
    }

    const inputChanged = (ev) => {
        dispatch({type: config.handlerType, payload: ev.target.checked});
    }

    const labelClicked = (ev) => {
        inputRef.current.checked = !inputRef.current.checked;
        dispatch({type: config.handlerType, payload: inputRef.current.checked});
    }

    return (
        <div className="form-check">
            <input ref={inputRef} className="form-check-input" type="checkbox" value="" onChange={inputChanged}
                   required={config.required || false}/>
            <label className="form-check-label" onClick={labelClicked}>
                {config.label}
            </label>
        </div>
    )
}

export default RememberInfo;

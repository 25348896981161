export const HANDLER_BLOCK_NUMBER = "HANDLER_BLOCK_NUMBER";

export default (state = "", action) => {
    switch (action.type) {
        case HANDLER_BLOCK_NUMBER:
            return action.payload;
        default:
            return state;
    }
}

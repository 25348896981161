export const HANDLER_RESTAURANT_NAME = "HANDLER_RESTAURANT_NAME";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_RESTAURANT_NAME:
            return action.payload;
        default:
            return state;
    }
}

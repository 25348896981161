export const HANDLER_PAYMENT_METHOD = "HANDLER_PAYMENT_METHOD";

export default (state = "cash", action) => {
    switch (action.type) {
        case HANDLER_PAYMENT_METHOD:
            return action.payload;
        default:
            return state;
    }
}

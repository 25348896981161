import React from "react";

const HalfSpaceSingleInputGrid = ({targetComponent}) => {
    return (
        <div className="col-6 me-5">
            {targetComponent}
        </div>
    )
}

export default HalfSpaceSingleInputGrid;

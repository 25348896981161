import React, {useEffect, useRef} from "react";
import SectionTitle from "./components/sectionTitle";
import SectionWrapper from "./layouts/sectionWrapper";
import {ensureRequiredFields, getAllHandlers} from "../../util/formBuilderHelper";
import {formDataMapper} from "../../util/formDataMapper";
import {useDispatch, useSelector} from "react-redux";
import useStateMapper from "../customHooks/stateMapperHook";
import {submitOrder} from "../../actions/orderSubmitAction";
import {ORDER_SUBMIT_STATE, ORDER_SUBMIT_STATE_PENDING} from "../../reducers/orderSubmitStateReducer";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import useStateCachingHook from "../customHooks/stateCachingHook";
import {PICK_UP_LOCATION_LAT_LNG} from "../../reducers/pickUpLocationReducer";
import {DELIVERY_LOCATION_LAT_LNG} from "../../reducers/deliveryLocationReducer";

const FormBuilder = ({formJSON, emailTemplateFunc, orderPlacementBtnText, preProcessFunc = null}) => {
    const [stateMapper, mailDataGenerator, updateLocationInfoInData] = useStateMapper();
    const updateCache = useStateCachingHook(formJSON);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const companyDetails = useSelector(state => state.companyDetails);
    const companyInfo = useSelector(state => state.companyDetails);
    const orderSubmitState = useSelector(state => state.orderSubmitState);
    const formRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({type: ORDER_SUBMIT_STATE, payload: ORDER_SUBMIT_STATE_PENDING})
        let handlers = getAllHandlers(formJSON);
        let data = {};
        let mailData = [];
        handlers.map((prop) => {
            let dataPropName = formDataMapper("simpleForm", prop);
            if (dataPropName) data[dataPropName] = stateMapper(prop);
        });
        if(preProcessFunc) {
            data = preProcessFunc(data);
        }
        data = ensureRequiredFields(data);
        data = updateLocationInfoInData(data);
        if (typeof data["deliveryInstruction"] === 'undefined') {
            data["deliveryInstruction"] = "";
        }
        if (typeof data["deliveryInstruction"] !== 'undefined') {
            if (typeof data["areaName"] !== 'undefined' && data["areaName"] !== "") {
                data["deliveryInstruction"] += "\nArea name: " + data["areaName"];
            }
            if (typeof data["blockNumber"] !== 'undefined' && data["blockNumber"] !== "") {
                data["deliveryInstruction"] += "\nBlock number: " + data["blockNumber"];
            }
            if (typeof data["flatNumber"] !== 'undefined' && data["flatNumber"] !== "") {
                data["deliveryInstruction"] += "\nFlat number: " + data["flatNumber"];
            }
            if (typeof data["roadNumber"] !== 'undefined' && data["roadNumber"] !== "") {
                data["deliveryInstruction"] += "\nRoad number: " + data["roadNumber"];
            }
            if (typeof data["additionalAddress"] !== 'undefined' && data["additionalAddress"] !== "") {
                data["deliveryInstruction"] += "\nAdditional address: " + data["additionalAddress"];
            }
            if (typeof data["villaNumber"] !== 'undefined' && data["villaNumber"] !== "") {
                data["deliveryInstruction"] += "\nVilla number: " + data["villaNumber"];
            }
        }
        mailData = mailDataGenerator(emailTemplateFunc, data["orderNumber"], data);
        updateCache();
        dispatch(submitOrder(data, companyInfo.getConstructedHeaderKey(), mailData));
    }

    const getFormSections = (formJSON) => {
        return formJSON.map((section) => {
            let header = null;
            if (section.sectionHeader) {
                header = <SectionTitle config={section.sectionHeader}/>
            }
            return (
                <>
                    {header}
                    <SectionWrapper sectionFieldsJSON={section.sectionFields}/>
                </>
            )
        })
    }

    useEffect(() => {
        if (orderSubmitState === null) {
            formRef.current.reset();
            dispatch({
                type: PICK_UP_LOCATION_LAT_LNG,
                payload: null
            })
            dispatch({
                type: DELIVERY_LOCATION_LAT_LNG,
                payload: null
            })
        }
    }, [orderSubmitState])

    useEffect(() => {
        if (companyDetails !== null)
            i18next.changeLanguage(companyDetails.getLanguage());
    }, [companyDetails?.getLanguage()]);

    return (
        <form onSubmit={handleSubmit} ref={formRef} id="externalOrderForm">
            {getFormSections(formJSON)}
            <div className="text-center place-order-btn">
                <button type="submit" className="btn my-4 fw-bold shadow"
                        disabled={orderSubmitState === ORDER_SUBMIT_STATE_PENDING}>{orderPlacementBtnText || t(FORM_LOCALE_KEYS.PLACE_ORDER)}</button>
            </div>
        </form>
    )
}

export default FormBuilder;

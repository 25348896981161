import paymentAPIClient from "./paymentAPIClient";

export const paymentPostJSON = (url, data) => {
    return paymentAPIClient.post(url, data);
}

export const paymentPostJSONWithHeader = (url, data, header) => {
    return paymentAPIClient.post(url, data, header);
}

export const paymentGetJSON = (url) => {
    return paymentAPIClient.get(url);
}
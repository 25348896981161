import React from "react";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import AddressInput from "../../common/components/addressInput";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import TimeInput from "../../common/components/timeInput";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import TextAreaInput from "../../common/components/textAreaInput";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import DateInput from "../../common/components/dateInput";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import EmailInput from "../../common/components/emailInput";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import ValueInput from "../../common/components/valueInput";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {useSelector} from "react-redux";
import RememberInfo from "../../common/components/rememberInfo";
import {HANDLER_REMEMBER_INFO} from "../../../reducers/rememberInfoReducer";
import PaidFormError from "../../common/paidComponents/paidFormError"

const CustomOrderDetailsInputBuilder = ({configJSON, loading}) => {
    const {t} = useTranslation();
    const companyInfo = useSelector(state => state.companyDetails);
    const deliveryOnly = companyInfo?.isDeliveryOnly();
    const deliveryAddress = companyInfo?.getDeliveryOnlyAddress();
    const deliveryPhone = companyInfo?.getDeliveryOnlyPhone();
    const companyName = companyInfo?.getCompanyName();
    return (
        <>
            <div>
                 <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.PICK_UP_FORM)}/>
                    <NameInput deliveryOnly={deliveryOnly} info={companyName} config={{handlerType: HANDLER_RESTAURANT_NAME, required: true, placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_PICKUP)}}/>
                    <NameInput deliveryOnly={deliveryOnly} info={deliveryPhone} config={{handlerType: HANDLER_RESTAURANT_PHONE_NUMBER, required: true, placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_PICKUP)}}/>
                    <AddressInput deliveryOnly={deliveryOnly} info={deliveryAddress} config={{handlerType: HANDLER_RESTAURANT_ADDRESS, required: true, placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_PICKUP)}}/>
                    <TimeInput config={{handlerType: HANDLER_EXPECTED_PICKUP_TIME, required: true, placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME)}}/>
                     <RememberInfo config={{handlerType: HANDLER_REMEMBER_INFO, required: false,label:t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO) ,placeholder: t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO)}}/>
                </Card>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.DELIVER_TO)}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_NAME, required: true, placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_RECIPIENT)}}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_PHONE_NUMBER, required: true, placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_RECIPIENT)}}/>
                    <EmailInput config={{handlerType: HANDLER_CUSTOMER_EMAIL, required:false, placeholder: t(FORM_LOCALE_KEYS.EMAIL_ADDRESS_OF_RECIPIENT) + " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"}}/>
                    <AddressInput config={{handlerType: HANDLER_CUSTOMER_ADDRESS, required: true, placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_RECIPIENT)}}/>
                    <div>
                        <div className="w-100">
                            <label className="form-label"></label>
                            <DateInput config={{handlerType: HANDLER_EXPECTED_DELIVERY_DATE, required: true, placeholder:t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE)}}/>
                        </div>
                    </div>
                    <TimeInput config={{handlerType: HANDLER_EXPECTED_DELIVERY_TIME, required: true, placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME)}}/>
                    <TextAreaInput config={{handlerType: HANDLER_DELIVERY_INSTRUCTION, required: true, placeholder: "Istruzioni e comunicazioni"}}/>
                    <ValueInput config={{handlerType: HANDLER_TIPS, required: false, placeholder: t(FORM_LOCALE_KEYS.DELIVERY_TIPS)}}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && "Vai alla Cassa"}
                </button>
            </div>
            <div className="mt-1">
                <PaidFormError />
            </div>
        </>
    )
}

export default CustomOrderDetailsInputBuilder;

import React, {useEffect, useRef, useState} from "react";
import {HANDLER_TOTAL_ORDER_COST} from "../../../reducers/totalOrderCostReducer";
import {useDispatch} from "react-redux";
import {HANDLER_PAYMENT_METHOD} from "../../../reducers/paymentMethodReducer";

export const PAYMENT_METHOD_FIELD = "PAYMENT_METHOD_FIELD";

const PaymentType = () => {
    const alreadyPaidRef = useRef(null);
    const codRef = useRef(null);
    const [showTotal, setShowTotal] = useState(true);
    const dispatch = useDispatch();

    const handlePaidClick = () => {
        dispatch({
            type: HANDLER_PAYMENT_METHOD,
            payload: "credit_card"
        });

        dispatch({
            type: HANDLER_TOTAL_ORDER_COST,
            payload: 0
        });

        setShowTotal(false);
    }

    const handleCODClick = () => {
        dispatch({
            type: HANDLER_PAYMENT_METHOD,
            payload: "cash"
        });

        setShowTotal(true);
    }

    const handleTotalChange = (ev) => {
        console.log(ev.currentTarget.value);
        dispatch({
            type: HANDLER_TOTAL_ORDER_COST,
            payload: ev.currentTarget.value
        })
    }

    return (
        <div className="d-flex align-items-center justify-content-start flex-wrap custom-method-input">
            <div className="d-flex align-items-center justify-content-between custom-method-input">
                <div className="pe-2 d-flex align-items-baseline">
                    <input className="cur-pointer" id="payment_method_already_paid" ref={alreadyPaidRef}
                           name="payment_method" type="radio" onClick={handlePaidClick} value="already_paid"
                           />
                    <label className="ps-2 cur-pointer" htmlFor="payment_method_already_paid">Already Paid</label>
                </div>
                <div className="d-flex align-items-baseline">
                    <input className="cur-pointer" id="payment_method_COD" ref={codRef} name="payment_method"
                           type="radio" value="COD" checked={showTotal} onClick={handleCODClick}/>
                    <label className="ps-2 cur-pointer" htmlFor="payment_method_COD">Cash on Delivery</label>
                </div>
            </div>
            <div className={!showTotal ? "d-none" : ""}>
                <input type="number" step={0.1} min={0} className="form-control" placeholder="Enter total amount"
                       onChange={handleTotalChange}></input>
            </div>
        </div>
    )
}

export default PaymentType;

import React, {useState, useEffect} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import {clearFromCache, getFromCache} from "../../util/localCache";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ORDER_SUBMIT_STATE_FAILED, submitOrder} from "../../actions/orderSubmitAction";
import {ORDER_SUBMIT_STATE, ORDER_SUBMIT_STATE_PENDING} from "../../reducers/orderSubmitStateReducer";
import PaymentSuccessful from "../common/paymentComponent/paymentSuccessful";

const PaymentStatus = () => {
    const stripe = useStripe();
    const params = useParams()
    const [message, setMessage] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {

        if (!stripe) {
            return;
        }

        // Retrieve the "payment_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        );

        // Retrieve the PaymentIntent
        try {
            stripe
                .retrievePaymentIntent(clientSecret)
                .then(({paymentIntent}) => {
                    // Inspect the PaymentIntent `status` to indicate the status of the payment
                    // to your customer.
                    //
                    // Some payment methods will [immediately succeed or fail][0] upon
                    // confirmation, while others will first enter a `processing` state.
                    //
                    // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                    switch (paymentIntent?.status) {
                        case 'succeeded':
                            setMessage(<PaymentSuccessful/>);
                            dispatch({type: ORDER_SUBMIT_STATE, payload: ORDER_SUBMIT_STATE_PENDING})
                            let orderData = getFromCache(params.orderId);
                            let header = getFromCache("orderHeader");
                            if (orderData && header) dispatch(submitOrder(JSON.parse(orderData), header, []));
                            else {
                                dispatch({type: ORDER_SUBMIT_STATE, payload: { STATE:ORDER_SUBMIT_STATE_FAILED, errMsg: "Order placement failed. The Order is already placed/expired."}})
                            }
                            break;

                        case 'processing':
                            setMessage("Payment processing. We'll update you when payment is received.");
                            break;

                        case 'requires_payment_method':
                            // Redirect your user back to your payment page to attempt collecting
                            // payment again
                            setMessage('Payment failed. Please try another payment method.');
                            break;

                        default:
                            setMessage('Something went wrong.');
                            break;
                    }
                    clearFromCache(params.orderId);
                    clearFromCache("orderHeader");
                });
        }catch (e) {
            console.error(e);
            setMessage("Something went wrong.")
        }

    }, [stripe]);


    return message;
};

export default PaymentStatus;
import React, {useEffect, useState} from "react";
import "../../../stylesheets/paidForm.css";
import OrderDetailsInputBuilder from "../../common/paidFormBuilders/orderDetailsInputBuilder";
import {findCompanyDetails} from "../../../actions/companyDetailsAction";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {HANDLER_ORDER_NUMBER} from "../../../reducers/orderNumberReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import estimatePayment from "../../../actions/paymentActions/paymentEstimationAction";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import {HANDLER_PAYMENT_METHOD} from "../../../reducers/paymentMethodReducer";
import {CLIENT_SECRET} from "../../../reducers/clientSecretReducer";
import {ESTIMATED_INFORMATION} from "../../../reducers/estimatedInfoReducer";
import {clearFromCache} from "../../../util/localCache";
import {checkStripeConnectionStatus} from "../../../actions/paymentActions/checkStripeConnection";
import {HANDLER_DELIVERY_FEE} from "../../../reducers/deliveryFeeReducer";
import {retrieveClientSecret} from "../../../actions/paymentActions/clientSecretAction";
import PaidFormLogoSection from "../../common/paidComponents/paidFormLogoSection";
import Card from "../../common/paidComponents/card";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import CustomInputBuilder from "./customInputBuilder";
import useStateMapper from "../../customHooks/stateMapperHook";
import CustomPaymentPageBuilder from "./customPaymentPageBuilder";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";
import {HANDLER_PICKUP_COMPANY_NAME} from "../../../reducers/pickUpCompanyNameReducer";
import {HANDLER_RESTAURANT_EMAIL} from "../../../reducers/restaurantEmailReducer";

const configJSON = [HANDLER_ORDER_NUMBER, HANDLER_PICKUP_COMPANY_NAME, HANDLER_RESTAURANT_NAME, HANDLER_RESTAURANT_PHONE_NUMBER, HANDLER_RESTAURANT_EMAIL, HANDLER_RESTAURANT_ADDRESS, HANDLER_EXPECTED_PICKUP_TIME, HANDLER_CUSTOMER_NAME, HANDLER_CUSTOMER_PHONE_NUMBER, HANDLER_CUSTOMER_EMAIL, HANDLER_CUSTOMER_ADDRESS, HANDLER_EXPECTED_DELIVERY_TIME, HANDLER_EXPECTED_DELIVERY_DATE,
    HANDLER_DELIVERY_INSTRUCTION, HANDLER_TIPS, HANDLER_DELIVERY_FEE, HANDLER_PAYMENT_METHOD, HANDLER_ITEM_TYPE, HANDLER_ITEM_SIZE, HANDLER_SERVICE_TYPE, HANDLER_NUMBER_OF_ITEMS]

const CustomPaidForm7 = () => {
    const [showPayment, setShowPayment] = useState(false);
    const [stateMapper] = useStateMapper();
    const [showSpinner, setShowSpinner] = useState(false);
    const companyInfo = useSelector(state => state.companyDetails);
    const stripeDetails = useSelector(state => state.stripeDetails);
    const estimatedInfo = useSelector(state => state.estimatedInfo);
    const tips = stateMapper(HANDLER_TIPS);
    const dispatch = useDispatch();
    const params = useParams();
    const deliveryLocation = useSelector(state => state.deliveryLocationInfo);
    const pickupLocation = useSelector(state => state.pickupLocationInfo);
    const getEstimateData = () => {
        let data = {
            from: stateMapper(HANDLER_RESTAURANT_ADDRESS),
            to: stateMapper(HANDLER_CUSTOMER_ADDRESS),
            tip: tips
        };

        if (deliveryLocation !== null) {
            data.to = `${deliveryLocation.lat},${deliveryLocation.lng}`
        }

        if (pickupLocation !== null) {
            data.from = `${pickupLocation.lat},${pickupLocation.lng}`;
        }

        return data;
    }
    const handleSubmit = (ev) => {
        ev.preventDefault();
        dispatch(estimatePayment(companyInfo.companyId, getEstimateData()));
        setShowSpinner(true);
        dispatch({
            type: HANDLER_PAYMENT_METHOD,
            payload: "credit_card"
        });
    }

    const handleBackToEdit = (ev) => {
        ev.preventDefault();
        dispatch({
            type: CLIENT_SECRET,
            payload: null
        })
        dispatch({
            type: ESTIMATED_INFORMATION,
            payload: null
        })
        clearFromCache(stateMapper(HANDLER_ORDER_NUMBER));
        setShowPayment(false);
    }

    useEffect(() => {
        dispatch({
            type: HANDLER_SERVICE_TYPE,
            payload: "Same day rush + $20"
        })
    })

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, [params.id])

    useEffect(() => {
        if (companyInfo) dispatch(checkStripeConnectionStatus(companyInfo.getAdminId()));
    }, [companyInfo])

    useEffect(() => {
        if (estimatedInfo) {
            let data = {
                dispatcherId: companyInfo.getAdminId(),
                chargeAmount: estimatedInfo.getChargeAmountInCents(),
                applicationFee: estimatedInfo.getApplicationFee(),
                currency: companyInfo.getCurrencyISOCode()
            }
            dispatch(retrieveClientSecret(data));
            dispatch({
                type: HANDLER_DELIVERY_FEE,
                payload: Number(Number(estimatedInfo.getChargeAmountInDollar() - stateMapper(HANDLER_TIPS)).toFixed(2))
            })
            setShowPayment(true);
            setShowSpinner(false);
        }
    }, [estimatedInfo, companyInfo])

    if (stripeDetails === null || companyInfo === null) return <></>;

    if (!stripeDetails.isConnected()) return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <PaidFormLogoSection showEdit={showPayment}
                                 backToEdit={handleBackToEdit}/>
            <div className="col-sm-6 offset-sm-3">
                <Card>
                    <h4 className="text-center">Stripe not connected for this account</h4>
                    <h6 className="text-center ">Please contact provider for further information</h6>
                </Card>
            </div>
            <div aria-disabled={true} disabled={true}>
                <form className={`col-sm-6 offset-sm-3 pb-5 ${showPayment ? "d-none" : ""}`} onSubmit={handleSubmit}
                      aria-disabled={true} aria-readonly={true}>
                    <OrderDetailsInputBuilder visible={!showPayment} loading={showSpinner}/>
                </form>
            </div>
        </div>
    );

    return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <PaidFormLogoSection showEdit={showPayment} backToEdit={handleBackToEdit}/>
            <div className="container-md">
                <form className={`col-lg-6 offset-lg-3 pb-5 ${showPayment ? "d-none" : ""}`} onSubmit={handleSubmit}>
                    <CustomInputBuilder visible={!showPayment} loading={showSpinner}/>
                </form>
            </div>
            <div id="paymentSection" className={`container-md ${showPayment ? "" : "d-none"}`}>
                <div className="col-lg-6 offset-lg-3">
                    <CustomPaymentPageBuilder configJSON={configJSON} visible={showPayment}/>
                </div>
            </div>
        </div>
    )
}

export default CustomPaidForm7;

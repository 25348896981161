export const PAID_FORM_ERROR_SET = "PAID_FORM_ERROR_SET";
export const PAID_FORM_ERROR_CLEAR = "PAID_FORM_ERROR_CLEAR";

const initialState = {show: false, message: null};

const PaidFormErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAID_FORM_ERROR_SET:
            return {show: true, message: action.payload};
        case PAID_FORM_ERROR_CLEAR:
            return initialState
        default:
            return state;
    }
}

export default PaidFormErrorReducer;

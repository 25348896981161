export const HANDLER_TOTAL_ORDER_COST = "HANDLER_TOTAL_ORDER_COST";

export default (state = 0, action) => {
    switch (action.type) {
        case HANDLER_TOTAL_ORDER_COST:
            return action.payload;
        default:
            return state;
    }
}

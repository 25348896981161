export const ORDER_SUBMIT_STATE = "ORDER_SUBMIT_STATE";
export const ORDER_SUBMIT_STATE_PENDING = "ORDER_SUBMIT_STATE_PENDING";
export const ORDER_SUBMIT_STATE_SUCCESS = "ORDER_SUBMIT_STATE_SUCCESS";
export const ORDER_SUBMIT_STATE_FAILED = "ORDER_SUBMIT_STATE_FAILED";

export default (state = null, action) => {
    switch (action.type) {
        case ORDER_SUBMIT_STATE:
            return action.payload;
        default:
            return state;
    }
}

import React, {useEffect, useState} from "react";
import FieldLabel from "./fieldLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch} from "react-redux";
import {dateFormatFix, twoDigit} from "../../../util/defaultTimeHelper";

const DateInput = ({config}) => {
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(new Date());

    const dateChange = (date) => {
        let dateTemp = twoDigit(date.getDate()) + "/" + twoDigit(date.getMonth() + 1) + "/" + date.getFullYear();
        let dateStr = dateFormatFix(dateTemp);
        dispatch({type: config.handlerType, payload: dateStr});
        setSelectedDate(date);
    }

    useEffect(() => {
        dateChange(selectedDate);
    }, [])

    return (
        <div>
            <FieldLabel config={config}/>
            <DatePicker className="form-control" selected={selectedDate} onChange={(date) => dateChange(date)}/>
        </div>
    )
}

export default DateInput;

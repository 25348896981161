import {postEmailWithoutSignature, postEmailWithSignature} from "../util/APIHelper/queries";

export const sendEmailWithSignature = (data) => async dispatch => {
    try {
        let response = await postEmailWithSignature(data);
    } catch (e) {
        console.error("EMAIL SENDING FAILED");
    }
}

export const sendEmailWithoutSignature = (data) => async dispatch => {
    try {
        let response = await postEmailWithoutSignature(data);
    } catch (e) {
        console.error("EMAIL SENDING FAILED");
    }
}

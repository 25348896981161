export const HANDLER_CUSTOMER_LAST_NAME = "HANDLER_CUSTOMER_LAST_NAME";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_CUSTOMER_LAST_NAME:
            return action.payload;
        default:
            return state;
    }
}

export const DELIVERY_LOCATION_LAT_LNG = "DELIVERY_LOCATION_LAT_LNG";

export default (state = null, action) => {
    switch (action.type) {
        case DELIVERY_LOCATION_LAT_LNG:
            return action.payload;
        default:
            return state;
    }
}

import React from "react";

const Image = ({imgSrc}) => {
    return (
        <div className="custom-img">
            <img src={imgSrc}/>
        </div>
    )
}

export default Image;

import React, {useEffect} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import StripePaymentElem from "./stripePaymentElem";
import {useSelector} from "react-redux";
import Card from "../paidComponents/card";
import {storeInCache} from "../../../util/localCache";


const StripeWrapper = ({}) => {
    //get the client secret from the reducer
    const stripeDetails = useSelector(state => state.stripeDetails);
    const orderID = useSelector(state => state.orderNumber);
    const companyInfo = useSelector(state => state.companyDetails);
    const stripePromise = loadStripe('pk_live_goDpxfRC7Z3f2CVPmuUsAX6Q00GAYHagBy', {
        stripeAccount: stripeDetails.getAccountId()
    });
    const secret = useSelector(state => state.clientSecret);

    useEffect(()=>{
        if(stripeDetails)
        storeInCache("stripeAccountId",stripeDetails.getAccountId())
    },[stripeDetails]);

    if(secret === null) return (
        <Card>
            <div className="d-flex justify-content-center">
                <div className="spinner-border p-3" role="status"></div>
            </div>
        </Card>
    );

    const options = {
        // passing the client secret obtained in step 2
        clientSecret: secret.getClientSecret(),
        // Fully customizable with appearance API.
        appearance: {/*...*/},
        locale: companyInfo.getLanguage()
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <StripePaymentElem orderID={orderID}/>
        </Elements>
    );
}

export default StripeWrapper;

import {getJSON, postJSON, postSecureJSONWithCustomKey} from "./requests";
import {getCompanyDetailsURL, getOrderPostURL, getSendEmailURL, getSendEmailWithoutSignature} from "./constructAPIReq";
import {paymentGetJSON, paymentPostJSON} from "./paymentRequests";
import {getPaymentConnectionStatusURL, getPaymentIntentURL} from "./constructPaymentAPIReq";
import {getEstimateURL} from "./constructEstimateAPIReq";
import {postEstimateReq, postEstimateReqWithHeader} from "./estimateRequests";

export const getCompanyDetails = async (companyID) => await getJSON(getCompanyDetailsURL(companyID));
export const postOrderDetails = async (data, key) => await postSecureJSONWithCustomKey(getOrderPostURL(), data, key);
export const postEmailWithSignature = async (data) => await postJSON(getSendEmailURL(), data);
export const postEmailWithoutSignature = async (data) => await postJSON(getSendEmailWithoutSignature(), data);

export const postPaymentIntentGettingInfo = async (data) => await paymentPostJSON(getPaymentIntentURL(), data);
export const getStripeConnectStatus = async (id) => await paymentGetJSON(getPaymentConnectionStatusURL(id));

export const postEstimatePayment = async (companyId, data) => await postEstimateReq(getEstimateURL(companyId), data);
export const postEstimatePaymentWithHeader = async (data, header) => await postEstimateReqWithHeader(getEstimateURL(), data, header);

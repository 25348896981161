import { useSelector } from "react-redux";

export const useDeliveryCompanyInfos = () => {
    const companyDetails = useSelector(state => state.companyDetails);

    if(companyDetails === null || companyDetails?.companyId === -1 || !companyDetails.isDeliveryOnly()) 
        return ["","",""];

    return [companyDetails.getCompanyName(), companyDetails.getDeliveryOnlyAddress(), companyDetails.getDeliveryOnlyPhone()];
}

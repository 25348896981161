import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    sectionTitleJSONCreator,
    SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {ADDRESS_INPUT_WITH_W3W} from "../common/components/addressInputWithW3W";
import {HANDLER_PAYMENT_METHOD} from "../../reducers/paymentMethodReducer";
import {HANDLER_TIPS} from "../../reducers/tipsReducer";
import {orderFormEmailTemplate6} from "../../util/customEmailTemplates/orderFormEmailTemplate6"
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";

const OrderForm6 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderForm6 = [
        {
            sectionHeader: sectionTitleJSONCreator("From"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Name of place", 'Pick up place name', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, undefined, false),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), 'ex. John Doe', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'Track your order', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Pick up description"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Notes", "Notes", true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Payment Options", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_PAYMENT_METHOD, [
                    {label: "Cash On Delivery", value: "cash"},
                    {label: "Credit Card", value: "credit_card"},
                ]),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Would you like to tip your driver?", "00", false, LAYOUT_HALF_SPACED_SINGLE, HANDLER_TIPS)
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderForm6} emailTemplateFunc={orderFormEmailTemplate6}/>
        </>
    )
}

export default OrderForm6;

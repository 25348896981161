import React from "react";
import CompanyLogo from "./components/companyLogo";
import CompanyName from "./components/companyName";
import Card from "./paidComponents/card";

const StarterAccessDeniedPage = () => {
    return (
        <div className="container paidForm">
            <div className="row">
                <div className="col-md-6 offset-md-3 pt-5">
                    <CompanyLogo/>
                    <CompanyName/>
                    <Card>
                        <h4 className="text-center">This form is not available to Starter Plan</h4>
                        <h6 className="text-center ">Please upgrade your plan to use this form</h6>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default StarterAccessDeniedPage;

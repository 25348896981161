import React from 'react';

const PaymentSuccessful = () => {
    return (
        <div>
            <div className="text-size-48 feedbackIcon">
                <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="45.7678" cy="45.9999" r="42.7678" stroke="#33C896" strokeWidth="5"
                            strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M28.8699 47.611L39.1674 57.9086L39.1009 57.842L62.3333 34.6096" stroke="#33C896"
                          strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className="fs-5 fw-bold m-3">Payment Received Successfully!</div>
        </div>
    )
}

export default PaymentSuccessful;

import React from "react";
import useStateMapper from "../../customHooks/stateMapperHook";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import DetailsSection from "./detailsSection";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";

const DeliveryDetailsSection = () => {
    const [stateMapper] = useStateMapper();
    const deliveryAddr = stateMapper(HANDLER_CUSTOMER_ADDRESS);
    const deliveryName = stateMapper(HANDLER_CUSTOMER_NAME);
    const deliveryPhone = stateMapper(HANDLER_CUSTOMER_PHONE_NUMBER);
    const deliveryTime = stateMapper(HANDLER_EXPECTED_DELIVERY_TIME);
    const deliveryDate = stateMapper(HANDLER_EXPECTED_DELIVERY_DATE);
    const deliveryEmail = stateMapper(HANDLER_CUSTOMER_EMAIL);
    return (
        <DetailsSection phone={deliveryPhone} name={deliveryName} email={deliveryEmail} time={deliveryTime} address={deliveryAddr} date={deliveryDate}/>
    )
}

export default DeliveryDetailsSection;

export const HANDLER_AREA_NAME = "HANDLER_AREA_NAME";

export default (state = "", action) => {
    switch (action.type) {
        case HANDLER_AREA_NAME:
            return action.payload;
        default:
            return state;
    }
}

import React from "react";
import CompanyLogo from "./components/companyLogo";
import CompanyName from "./components/companyName";
import FormBuilder from "./formBuilder";
import OrderAlerts from "./components/orderAlerts";

const LoadingPage = ({FullFormConfigJSON, orderPlacementBtnText}) => {
    return (
        <div className="loadingContainer" aria-disabled={true} disabled={true}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3 pt-5">
                        <CompanyLogo/>
                        <CompanyName/>
                        <FormBuilder formJSON={FullFormConfigJSON} orderPlacementBtnText={orderPlacementBtnText}/>
                        <OrderAlerts/>
                    </div>
                </div>
            </div>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default LoadingPage;

import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Selector from "../../common/components/selector";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import AddressInput from "../../common/components/addressInput";
import TimeInput from "../../common/components/timeInput";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import EmailInput from "../../common/components/emailInput";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import DateInput from "../../common/components/dateInput";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {useDispatch} from "react-redux";
import RememberInfo from "../../common/components/rememberInfo";
import {HANDLER_REMEMBER_INFO} from "../../../reducers/rememberInfoReducer";
import TextAreaInput from "../../common/components/textAreaInput";


const CustomInputBuilder = ({configJSON, loading}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: HANDLER_SERVICE_TYPE, payload: "Carro"});
    }, [])
    return (
        <>
            <div>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.PICK_UP_FORM)}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_NAME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_PICKUP),
                        prepopulate: true
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_PHONE_NUMBER,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_PICKUP),
                        prepopulate: true
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_RESTAURANT_ADDRESS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_PICKUP),
                        prepopulate: true
                    }}/>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_PICKUP_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME),
                    }}/>
                    <RememberInfo config={{
                        handlerType: HANDLER_REMEMBER_INFO,
                        required: false,
                        label: t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO)
                    }}/>
                </Card>
                <Card>
                    <CardTitle title={"Drop off"}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_NAME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_RECIPIENT)
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_PHONE_NUMBER,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_RECIPIENT)
                    }}/>
                    <EmailInput config={{
                        handlerType: HANDLER_CUSTOMER_EMAIL,
                        required: false,
                        placeholder: t(FORM_LOCALE_KEYS.EMAIL_ADDRESS_OF_RECIPIENT) + " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_CUSTOMER_ADDRESS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_RECIPIENT)
                    }}/>
                    <div>
                        <div className="w-100">
                            <label className="form-label"></label>
                            <DateInput config={{
                                handlerType: HANDLER_EXPECTED_DELIVERY_DATE,
                                required: true,
                                placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE)
                            }}/>
                        </div>
                    </div>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_DELIVERY_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME)
                    }}/>
                </Card>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.ITEM_AND_SERVICE_TYPE)}/>
                    <Selector config={{
                        handlerType: HANDLER_ITEM_TYPE, required: true, placeholder: t(FORM_LOCALE_KEYS.ITEM_TYPE), selectionOptions: [{
                            label: t(FORM_LOCALE_KEYS.DOCUMENTS),
                            value: t(FORM_LOCALE_KEYS.DOCUMENTS)
                        }, {label: t(FORM_LOCALE_KEYS.PARCELS), value: t(FORM_LOCALE_KEYS.PARCELS)},
                            {
                                label: t("Hot/Cold Items"),
                                value: t("Hot/Cold Items")
                            }, {label: t(FORM_LOCALE_KEYS.OTHER_ITEMS), value: t(FORM_LOCALE_KEYS.OTHER_ITEMS)}]
                    }}/>
                    <Selector config={{
                        handlerType: HANDLER_ITEM_SIZE, required: true, placeholder: t(FORM_LOCALE_KEYS.ITEM_SIZE), selectionOptions: [{label: t("Small Items(Below 5Kg)"), value: t("Small Items(Below 5Kg)")},
                            {label: t("Medium Items(Between 5-10 Kgs)"), value: t("Medium Items(Between 5-10 Kgs)")},
                            {label: t("Large Items(10 Kg+)"), value: t("Large Items(10 Kg+)")},
                            {label: t("Others (Please describe in delivery note)"), value: t("Others (Please describe in delivery note)")}
                        ]
                    }}/>
                    <TextAreaInput config={{handlerType: HANDLER_DELIVERY_INSTRUCTION, required: false, placeholder: t(FORM_LOCALE_KEYS.DELIVERY_NOTE) + " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"}}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && t(FORM_LOCALE_KEYS.CHECKOUT)}
                </button>
            </div>
        </>
    )
}

export default CustomInputBuilder;

import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    DATE_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    LAYOUT_TANGLE_WITH_NEXT,
    REMEMBER_SECTION,
    sectionTitleJSONCreator,
    SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {HANDLER_ORDER_NUMBER} from "../../reducers/orderNumberReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {HANDLER_EXPECTED_PICKUP_DATE} from "../../reducers/expectedPickUpDateReducer";
import {HANDLER_ITEM_TYPE} from "../../reducers/itemTypeReducer";
import {ADDRESS_INPUT_WITH_W3W} from "../common/components/addressInputWithW3W";
import {courierEmailTemplate2} from "../../util/customEmailTemplates/orderFormEmailTemplate5";

const OrderForm5 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_FORM)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "Enter name or company name", true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), 'Enter number', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.PICK_UP_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Item Description", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_ITEM_TYPE,
                    [{
                        label: t(FORM_LOCALE_KEYS.DOCUMENTS),
                        value: t(FORM_LOCALE_KEYS.DOCUMENTS)
                    }, {label: "Parcel - Fits in car", value: "Parcel - Fits in car"},
                        {
                            label: "Food / Drink",
                            value: "Food / Drink"
                        },
                        {label: "Laundry", value: "Laundry"},
                        {label: "Flowers", value: "Flowers"},
                        {label: "Clothing Items", value: "Clothing Items"},
                        {label: "Other - Fits in car", value: "Other - Fits in car"}]),
                fieldJSONCreator(DATE_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_DATE), '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_EXPECTED_PICKUP_DATE),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_TIME),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "Enter name or company name", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), 'Enter mobile number', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
                fieldJSONCreator(REMEMBER_SECTION, <span>Accept <a target="_blank" href="https://quiqdelivery.co.uk/policies/terms-of-service">Terms & Conditions</a></span>, '', true, LAYOUT_DEFAULT, "NONE")
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={courierEmailTemplate2} orderPlacementBtnText={"Book Now"}/>
        </>
    )
}

export default OrderForm5;

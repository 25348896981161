import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import FieldLabel from "./fieldLabel";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";
import {HANDLER_NUMBER_OF_EXTRA_ITEMS} from "../../../reducers/numberOfExtraItemsReducer";


const ValueInput = ({config}) => {
    const dispatch = useDispatch();
    const [stepSize, setStepSize] = useState(0.01)
    const valRef = useRef(null);
    

    const inputChanged = (ev) => {
        let val = ev.target.value;
        if (config.additionalFunction) {
            val = config.additionalFunction(val);
        }

        if (config.additionalText) {
            val = config.additionalText + val;
        }

        dispatch({type: config.handlerType, payload: val});
    }

    useEffect(() => {
        if (config?.handlerType === HANDLER_NUMBER_OF_ITEMS || 
            config?.handlerType === HANDLER_ITEM_SIZE ||
            config?.handlerType === HANDLER_NUMBER_OF_EXTRA_ITEMS
        ) {
            setStepSize(1);
        }

        const f = document.getElementById("externalOrderForm");
        if(f) f.addEventListener("reset", resetInput);
        return () => {if(f) f.removeEventListener("reset", resetInput)};
    }, []);

    if (typeof config === "undefined") {
        return null;
    }

    const resetInput = () => {
        valRef.current.value = "";
        dispatch({type: config.handlerType, payload: ''});
    }

    return (
        <div>
            <FieldLabel config={config}/>
            <input type="number" ref={valRef} min={0} step={stepSize} placeholder={config.placeholder || "00"} className="form-control"
                   aria-describedby="value" onChange={inputChanged} required={config.required || false}/>
        </div>
    )
}

export default ValueInput;

export const getConfigWithAuth = () => {
    return {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer API_AUTH_KEY`,
        },
        dataType: "application/json"
    }
}

export const getConfigWithoutAuth = () => {
    return {dataType: "application/json"}
}

export const getCustomConfigWithAuth = (key) => {
    return {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${key}`,
        },
        dataType: "application/json"
    }
}

import React, {useEffect, useState} from "react";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {useDispatch, useSelector} from "react-redux";
import {HANDLER_RESTAURANT_EMAIL} from "../../../reducers/restaurantEmailReducer";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import AddressInput from "../../common/components/addressInput";
import TimeInput from "../../common/components/timeInput";
import EmailInput from "../../common/components/emailInput";
import DateInput from "../../common/components/dateInput";
import TextAreaInput from "../../common/components/textAreaInput";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";
import RememberInfo from "../../common/components/rememberInfo";
import {HANDLER_REMEMBER_INFO} from "../../../reducers/rememberInfoReducer";
import Selector from "../../common/components/selector";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import ValueInput from "../../common/components/valueInput";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";


const OrderDetailsInputBuilder = ({configJSON, loading}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: HANDLER_SERVICE_TYPE, payload: "Carro"});
    }, [])
    return (
        <>
            <div>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.PICK_UP_FORM)}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_NAME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_PICKUP),
                        prepopulate: true
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_PHONE_NUMBER,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_PICKUP),
                        prepopulate: true
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_RESTAURANT_ADDRESS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_PICKUP),
                        prepopulate: true
                    }}/>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_PICKUP_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME),
                    }}/>
                    <RememberInfo config={{
                        handlerType: HANDLER_REMEMBER_INFO,
                        required: false,
                        label: t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO)
                    }}/>
                </Card>
                <Card>
                    <CardTitle title={"Drop off"}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_NAME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_RECIPIENT)
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_PHONE_NUMBER,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_RECIPIENT)
                    }}/>
                    <EmailInput config={{
                        handlerType: HANDLER_CUSTOMER_EMAIL,
                        required: false,
                        placeholder: t(FORM_LOCALE_KEYS.EMAIL_ADDRESS_OF_RECIPIENT) + " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_CUSTOMER_ADDRESS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_RECIPIENT)
                    }}/>
                    <div>
                        <div className="w-100">
                            <label className="form-label"></label>
                            <DateInput config={{
                                handlerType: HANDLER_EXPECTED_DELIVERY_DATE,
                                required: true,
                                placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE)
                            }}/>
                        </div>
                    </div>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_DELIVERY_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME)
                    }}/>
                </Card>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.ITEM_AND_SERVICE_TYPE)}/>
                    <Selector config={{
                        handlerType: HANDLER_ITEM_TYPE, required: true, placeholder: t(FORM_LOCALE_KEYS.ITEM_TYPE), selectionOptions: [{
                            label: "Comida",
                            value: "Comida"
                        }, {label: "Pizza", value: "Pizza"},
                            {
                                label: "Documentos",
                                value: "Documentos"
                            }, {label: "Paquetes en caja", value: "Paquetes en caja"}, {label: "Paquetes XL (No caben en moto)", value: "Paquetes XL (No caben en moto)"}, {label: "Paquete frágil", value: "Paquete frágil"},
                            {label: "Otros", value: "Otros"}]
                    }}/>
                    <Selector config={{
                        handlerType: HANDLER_ITEM_SIZE, required: true, placeholder: t(FORM_LOCALE_KEYS.ITEM_SIZE), selectionOptions: [{label: t(FORM_LOCALE_KEYS.SMALL_ITEM), value: t(FORM_LOCALE_KEYS.SMALL_ITEM)},
                            {label: t(FORM_LOCALE_KEYS.MEDIUM_ITEMS), value: t(FORM_LOCALE_KEYS.MEDIUM_ITEMS)},
                            {label: t(FORM_LOCALE_KEYS.LARGE_ITEMS), value: t(FORM_LOCALE_KEYS.LARGE_ITEMS)}
                        ]
                    }}/>
                    <Selector config={{
                        handlerType: HANDLER_SERVICE_TYPE,
                        required: true,
                        placeholder: 'Tipo de vehículo',
                        selectionOptions: [
                            {value: "Carro", label: "Carro"},
                            {value: "Moto", label: "Moto"},
                            {value: "Scooter", label: "Scooter"}
                        ]
                    }}/>
                    <ValueInput config={{
                        handlerType: HANDLER_NUMBER_OF_ITEMS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.NUM_OF_ITEMS)
                    }}/>
                    <ValueInput config={{
                        handlerType: HANDLER_TIPS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.DRIVER_TIPS)
                    }}/>
                    <TextAreaInput config={{handlerType: HANDLER_DELIVERY_INSTRUCTION, required: false, placeholder: t(FORM_LOCALE_KEYS.DELIVERY_NOTE) + " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"}}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && t(FORM_LOCALE_KEYS.CHECKOUT)}
                </button>
            </div>
        </>
    )
}

export default OrderDetailsInputBuilder;

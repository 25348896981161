import {Elements} from "@stripe/react-stripe-js";
import PaymentStatus from "./paymentSuccessfulGeneral";
import {loadStripe} from "@stripe/stripe-js";
import Card from "../common/paidComponents/card";
import PaymentSuccessful from "../common/paymentComponent/paymentSuccessful";
import OrderAlerts from "../common/components/orderAlerts";
import {useDispatch, useSelector} from "react-redux";
import {ORDER_SUBMIT_STATE, ORDER_SUBMIT_STATE_PENDING} from "../../reducers/orderSubmitStateReducer";
import {useEffect} from "react";
import {getFromCache} from "../../util/localCache";
import {submitOrder} from "../../actions/orderSubmitAction";
import {useParams} from "react-router-dom";

const PaymentSuccessWrapper = () => {
    const stripePromise = loadStripe('pk_live_goDpxfRC7Z3f2CVPmuUsAX6Q00GAYHagBy', {
        stripeAccount: getFromCache("stripeAccountId")
    });
    const orderSubmitState = useSelector(state => state.orderSubmitState);
    return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <div className="col-sm-6 offset-sm-3">
                <Elements stripe={stripePromise}>
                    <Card>
                        <div className="text-center">
                            <PaymentStatus/>
                        </div>
                        {orderSubmitState === ORDER_SUBMIT_STATE_PENDING && <div className="text-center">
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-success" role="status"/>
                            </div>
                            <br/>
                            <span>Placing Order</span>
                        </div>}
                    </Card>
                    <OrderAlerts/>
                </Elements>
            </div>
        </div>
    )
}

export default PaymentSuccessWrapper;
export const CLIENT_SECRET = "CLIENT_SECRET";

export default (state = null, action) => {
    switch (action.type) {
        case CLIENT_SECRET:
            return action.payload;
        default:
            return state;
    }
}

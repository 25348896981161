import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Selector from "../../common/components/selector";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import AddressInput from "../../common/components/addressInput";
import TimeInput from "../../common/components/timeInput";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import EmailInput from "../../common/components/emailInput";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import DateInput from "../../common/components/dateInput";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import TextAreaInput from "../../common/components/textAreaInput";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import ValueInput from "../../common/components/valueInput";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {useDispatch} from "react-redux";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";
import {HANDLER_ADDITIONAL_ADDRESS} from "../../../reducers/additionalAddressInfo";
import {HANDLER_PICKUP_COMPANY_NAME} from "../../../reducers/pickUpCompanyNameReducer";
import {HANDLER_RESTAURANT_EMAIL} from "../../../reducers/restaurantEmailReducer";


const CustomInputBuilder = ({configJSON, loading}) => {
    const {t} = useTranslation();
    return (
        <>
            <div>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.PICK_UP_FORM)}/>
                    <NameInput config={{
                        handlerType: HANDLER_PICKUP_COMPANY_NAME,
                        required: false,
                        placeholder: "Company Name"
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_NAME,
                        required: true,
                        placeholder: "Contact Full Name"
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_PHONE_NUMBER,
                        required: true,
                        placeholder: "Contact Phone #"
                    }}/>
                    <EmailInput config={{
                        handlerType: HANDLER_RESTAURANT_EMAIL,
                        required: false,
                        placeholder: "Contact Email" + " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_RESTAURANT_ADDRESS,
                        required: true,
                        placeholder: "Pickup Address"
                    }}/>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_PICKUP_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME)
                    }}/>
                </Card>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.DELIVER_TO)}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_NAME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_RECIPIENT)
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_PHONE_NUMBER,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_RECIPIENT)
                    }}/>
                    <EmailInput config={{
                        handlerType: HANDLER_CUSTOMER_EMAIL,
                        required: false,
                        placeholder: t(FORM_LOCALE_KEYS.EMAIL_ADDRESS_OF_RECIPIENT) + " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_CUSTOMER_ADDRESS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_RECIPIENT)
                    }}/>
                    <div>
                        <div className="w-100">
                            <label className="form-label"></label>
                            <DateInput config={{
                                handlerType: HANDLER_EXPECTED_DELIVERY_DATE,
                                required: true,
                                placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE)
                            }}/>
                        </div>
                    </div>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_DELIVERY_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME)
                    }}/>
                    <ValueInput config={{
                        handlerType: HANDLER_TIPS,
                        required: false,
                        placeholder: t(FORM_LOCALE_KEYS.DELIVERY_TIPS)
                    }}/>
                    <Selector config={{
                        handlerType: HANDLER_ITEM_TYPE, required: true, placeholder: 'Item Type', selectionOptions: [{
                            label: t(FORM_LOCALE_KEYS.DOCUMENTS),
                            value: t(FORM_LOCALE_KEYS.DOCUMENTS)
                        },  {label: "Small Boxes", value: "Small Boxes"},
                            {label: t(FORM_LOCALE_KEYS.PARCELS), value: t(FORM_LOCALE_KEYS.PARCELS)},
                            {
                                label: t(FORM_LOCALE_KEYS.MEDICAL_ITEMS),
                                value: t(FORM_LOCALE_KEYS.MEDICAL_ITEMS)
                            }, {label: t(FORM_LOCALE_KEYS.OTHER_ITEMS), value: t(FORM_LOCALE_KEYS.OTHER_ITEMS)}]
                    }}/>
                    <Selector config={{
                        handlerType: HANDLER_ITEM_SIZE, required: true, placeholder: 'Item Size', selectionOptions: [
                            {label: "0-10 lbs", value: "0-10 lbs"},
                            {label: "10-40 lbs", value: "10-40 lbs"},
                            {label: "40+ lbs", value: "40+ lbs"}
                        ]
                    }}/>
                    <Selector config={{
                        handlerType: HANDLER_SERVICE_TYPE,
                        required: true,
                        placeholder: 'Service Type',
                        selectionOptions: [
                            {label: "ASAP- 1-3 HOURS", value: "ASAP- 1-3 HOURS"},
                            {label: "Same Day", value: "Same Day"},
                            {label: "24 Hour", value: "24 Hour"}
                        ]
                    }}/>
                    <ValueInput config={{
                        handlerType: HANDLER_NUMBER_OF_ITEMS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.NUM_OF_ITEMS)
                    }}/>
                    <TextAreaInput config={{handlerType: HANDLER_DELIVERY_INSTRUCTION, required: false, placeholder: t(FORM_LOCALE_KEYS.DELIVERY_NOTE) +  " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"}}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && "Get Quote"}
                </button>
            </div>
        </>
    )
}

export default CustomInputBuilder;

import React, {useEffect} from "react";
import useStateMapper from "../../customHooks/stateMapperHook";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {useSelector} from "react-redux";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {HANDLER_TOTAL_ORDER_COST} from "../../../reducers/totalOrderCostReducer";
import {storeInCache} from "../../../util/localCache";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_DELIVERY_FEE} from "../../../reducers/deliveryFeeReducer";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";


export const OrderDetails = () => {
    const [stateMapper] = useStateMapper();
    const deliveryDetails = stateMapper(HANDLER_DELIVERY_INSTRUCTION);
    const orderTotal = stateMapper(HANDLER_TOTAL_ORDER_COST);
    const estimatedInfo = useSelector(state => state.estimatedInfo);
    const companyDetails = useSelector(state => state.companyDetails);

    const {t} = useTranslation();

    const getUnit = () => {
        switch (estimatedInfo.getDistanceUnit()) {
            case "mile":
                return "Mile(s)";
            case "km":
                return "KM"
            default:
                return "Mile(s)";
        }
    }

    if (estimatedInfo === null) return <></>;

    return (
        <>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>{t(FORM_LOCALE_KEYS.DISTANCE)}:</span>
                <div>
                    {estimatedInfo.distance} {getUnit()}
                </div>
            </div>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>Package Declaration:</span>
                <div>
                    {stateMapper(HANDLER_ITEM_TYPE)}
                </div>
            </div>

            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>Wight:</span>
                <div>
                    {stateMapper(HANDLER_ITEM_SIZE)}
                </div>
            </div>

            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>Pickup Note:</span>
                <div>
                    {stateMapper(HANDLER_SERVICE_TYPE)}
                </div>
            </div>

            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>{t(FORM_LOCALE_KEYS.DELIVERY_NOTE)}:</span>
                <div>
                    {deliveryDetails}
                </div>
            </div>
            <hr className="hr-line"/>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>{t(FORM_LOCALE_KEYS.DELIVERY_FEE)}</span>
                <span>{companyDetails.currency}{stateMapper(HANDLER_DELIVERY_FEE)}</span>
            </div>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>{t(FORM_LOCALE_KEYS.DELIVERY_TIPS)}</span>
                <span>{companyDetails.currency}{Number(stateMapper(HANDLER_TIPS)).toFixed(2)}</span>
            </div>

            <div className="d-flex flex-direction-row justify-content-between secondary-text fs-6">
                <span className="fw-bold">{t(FORM_LOCALE_KEYS.TOTAL)}</span>
                <span className="fw-bold">{companyDetails.currency}{Number(orderTotal).toFixed(2)}</span>
            </div>
        </>
    )
}



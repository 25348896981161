import React from "react";

const FieldLabel = ({config}) => {
    if(config == undefined || config?.label === null || config?.label === undefined) return <></>;

    return (
        <label className={`form-label ${config.required ? "required" : ""}`}>{config.label}</label>
    )
}

export default FieldLabel;

import {useDispatch} from "react-redux";
import FieldLabel from "./fieldLabel";
import React, { useEffect, useRef } from "react";

const TextAreaInput = ({config}) => {
    const dispatch = useDispatch();
    const textRef = useRef(null);

    useEffect(() => {
        const f = document.getElementById("externalOrderForm");
        if(f) f.addEventListener("reset", resetInput);
        return () => {if(f) f.removeEventListener("reset", resetInput)};
    }, []);

    if (typeof config === "undefined") {
        return null;
    }

    const inputChanged = (ev) => {
        dispatch({type: config.handlerType, payload: ev.target.value});
    }

    const resetInput = () => {
        textRef.current.value = "";
        dispatch({type: config.handlerType, payload: ''});
    }

    return (
        <div>
            <FieldLabel config={config}/>
            <textarea className="form-control" required={config.required || false} ref={textRef}
                      placeholder={config.placeholder} onChange={inputChanged}/>
        </div>
    )
}

export default TextAreaInput;
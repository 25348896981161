import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    DATE_INPUT_FIELD, EMAIL_INPUT_FIELD, fieldJSONCreator, LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE, sectionTitleJSONCreator,
    SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_CUSTOMER_NAME } from "../../reducers/customerNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { ADDRESS_INPUT_WITH_W3W } from "../common/components/addressInputWithW3W";
import { HANDLER_EXPECTED_DELIVERY_DATE } from "../../reducers/expectedDeliveryDateReducer";
import { HANDLER_ITEM_SIZE } from "../../reducers/itemSizeReducer";
import { HANDLER_ITEM_TYPE } from "../../reducers/itemTypeReducer";
import { OrderEmailTemplate2 } from "../../util/emailTemplates/orderEmailTemplate2";
import { HANDLER_CUSTOMER_EMAIL } from "../../reducers/customerEmailReducer";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";

// Becca@guestlinen.co.uk

const OrderForm25 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderForm4Json = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVERY_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Property Name", '', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'example@gmail.com', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, "Property Address", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Linen", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_ITEM_TYPE, [
                    {label: "Satin Stripe Linen", value: "Satin Stripe Linen"},
                    {label: "Plain Linen", value: "Plain Linen"},
                ]),
                fieldJSONCreator(TEXTAREA_FIELD, "Linen Requirements", t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
                fieldJSONCreator(DATE_INPUT_FIELD, "Delivery Date (Monday - Friday only)", '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_DELIVERY_DATE),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Key Box Code Information", '', true, LAYOUT_DEFAULT, HANDLER_ITEM_SIZE),
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    const template = onlyDispatcherMail(OrderEmailTemplate2);

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderForm4Json} emailTemplateFunc={template}/>
        </>
    )
}

export default OrderForm25;

import React, {useEffect, useRef, useState} from "react";
import FieldLabel from "./fieldLabel";
import "../../../stylesheets/timePicker.css";
import {useDispatch} from "react-redux";
import {
    check24HourTime,
    convertTo24HourFormat,
    defaultDeliveryTime,
    defaultPickUpTime,
    formatWicked24hToJs24h,
} from "../../../util/defaultTimeHelper";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";

const Time24hInput = ({config}) => {
    const timeRef = useRef();
    const dispatch = useDispatch();
    const [picker, setPicker] = useState(null);

    const setDefaultTime = (pickerObj) => {
        if (config.handlerType === HANDLER_EXPECTED_DELIVERY_TIME) pickerObj.val(convertTo24HourFormat(defaultDeliveryTime().time));
        else pickerObj.val(convertTo24HourFormat(defaultPickUpTime().time));
        handleChange();
    }

    useEffect(() => {
        let option = {
            twentyFour: true,
        }
        if (config.additionalFunction) {
            option = {...option, minutesInterval: 15}
        }
        let pickerObj = window.$("#" + config.handlerType).wickedpicker(option);
        setPicker(pickerObj);
        setDefaultTime(pickerObj)
        document.getElementById("externalOrderForm")?.addEventListener("reset", ()=>{
            setTimeout(()=> {
                setDefaultTime(pickerObj);
            }, 500);
        })
    }, [config?.placeholder, config?.handlerType]);

    const handleChange = () => {
        if(timeRef.current.value && picker) {
            let formattedTime = formatWicked24hToJs24h(timeRef.current.value);
            dispatch({type: config.handlerType, payload: formattedTime});
            picker.val(timeRef.current.value);
        }
    }

    const validate = () => {
        if(timeRef.current.value && picker) {
            if(!check24HourTime(timeRef.current.value)) {
                setDefaultTime(picker);
            }
        }
    }

    return (
        <div className="bootstrap-timepicker">
            <FieldLabel config={config}/>
            <input ref={timeRef} type="text" id={config.handlerType} name="timepicker" className="timepicker form-control"
                   required={config.required || false} onInput={handleChange} onChange={handleChange} onBlur={validate}/>
        </div>
    )
}

export default Time24hInput;

import React from "react";

export const Card = ({children}) => {
    return (
        <div className="bg-white paid-card">
            {children}
        </div>
    )
}

export default Card;

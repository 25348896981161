export const HANDLER_RESTAURANT_EMAIL = "HANDLER_RESTAURANT_EMAIL";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_RESTAURANT_EMAIL:
            return action.payload;
        default:
            return state;
    }
}

import useStateMapper from "./stateMapperHook";
import {useSelector} from "react-redux";
import {storeInCache} from "../../util/localCache";

export const useStateCachingHook = (json) => {
    const [stateMapper] = useStateMapper();
    const rememberState = useSelector(state => state.rememberInfo);
    const updateCache = () => {
        if (rememberState && json) {
            json.forEach((section) => {
                section.sectionFields.forEach((fields)=> {
                    if (fields.prepopulate) {
                        let val = stateMapper(fields.handlerType);
                        storeInCache(fields.handlerType, val);
                    }
                })
            })
        }
    }
    return updateCache;
}

export default useStateCachingHook;

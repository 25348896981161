import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    REMEMBER_SECTION,
    sectionTitleJSONCreator,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {HANDLER_ORDER_NUMBER} from "../../reducers/orderNumberReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {HANDLER_TOTAL_ORDER_COST} from "../../reducers/totalOrderCostReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_REMEMBER_INFO} from "../../reducers/rememberInfoReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {HANDLER_DELIVERY_FEE} from "../../reducers/deliveryFeeReducer";
import {RestaurantEmailTemplate2} from "../../util/emailTemplates/restaurantEmailTemplate2";
import {HANDLER_TIPS} from "../../reducers/tipsReducer";

const RestaurantOrderForm7 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const restaurantOrderForm2Json = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.CUSTOMER_NAME), 'ex. John Doe', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.INFO_FOR_DRIVER)),
            sectionFields: [
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_EXPECTED_PICKUP_TIME),
                fieldJSONCreator(VALUE_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_TIPS), '00', true, LAYOUT_DEFAULT, HANDLER_TIPS),
                fieldJSONCreator(VALUE_INPUT_FIELD, t(FORM_LOCALE_KEYS.CUSTOMER_PAYS), '00', true, LAYOUT_DEFAULT, HANDLER_TOTAL_ORDER_COST)
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={restaurantOrderForm2Json}
                             emailTemplateFunc={RestaurantEmailTemplate2}/>
        </>
    )
}

export default RestaurantOrderForm7;

import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    DATE_INPUT_FIELD,
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT, LAYOUT_TANGLE_WITH_NEXT,
    sectionTitleJSONCreator, TEXT_INPUT_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_ITEM_TYPE} from "../../reducers/itemTypeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../../reducers/numberOfItemsReducer";
import {courierEmailTemplate1} from "../../util/emailTemplates/courierEmailTemplate1";
import {ADDRESS_INPUT_WITH_W3W} from "../common/components/addressInputWithW3W";
import {HANDLER_CUSTOMER_LAST_NAME} from "../../reducers/customerLastNameReducer";
import {HANDLER_CUSTOMER_TAIL_NUMBER} from "../../reducers/customerTailNumberReducer";
import {HANDLER_NUMBER_OF_EXTRA_ITEMS} from "../../reducers/numberOfExtraItemsReducer";

// form for "Cockpit Catering Co"

const OrderForm17 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.CUSTOMER_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.FIRST_NAME), "John", true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.LAST_NAME), "Doe", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_LAST_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t("Customer Tail Number"), "", false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_TAIL_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'example@gmail.com', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVERY_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(DATE_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_DATE), '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_EXPECTED_DELIVERY_DATE),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_DELIVERY_TIME)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t("Items Information")),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t("Items Requested"), '', true, LAYOUT_DEFAULT, HANDLER_ITEM_TYPE),
                fieldJSONCreator(VALUE_INPUT_FIELD, t("Passenger Count"), '00', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_NUMBER_OF_ITEMS),
                fieldJSONCreator(VALUE_INPUT_FIELD, t("Crew Count"), '00', true, LAYOUT_DEFAULT, HANDLER_NUMBER_OF_EXTRA_ITEMS)
            ]

        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    const preProcessFunc = (data) => {
        data['deliveryInstruction'] = `Customer Tail Number: ${data["customerTailNumber"]}\n`;
        data['deliveryInstruction'] += `Passenger Count: ${data["numberOfItems"]}, Crew Count: ${data["numberOfExtraItems"]}`;
        delete data["customerTailNumber"];
        return data;
    }

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={courierEmailTemplate1} preProcessFunc={preProcessFunc}/>
        </>
    )
}

export default OrderForm17;

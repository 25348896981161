import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    DATE_INPUT_FIELD,
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE, LAYOUT_TANGLE_WITH_NEXT, sectionTitleJSONCreator, SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { HANDLER_CUSTOMER_NAME } from "../../reducers/customerNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { HANDLER_EXPECTED_PICKUP_TIME } from "../../reducers/expectedPickupTimeReducer";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { courierEmailTemplate1 } from "../../util/emailTemplates/courierEmailTemplate1";
import { ADDRESS_INPUT_WITH_W3W } from "../common/components/addressInputWithW3W";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_EXPECTED_PICKUP_DATE } from "../../reducers/expectedPickUpDateReducer";
import { HANDLER_ITEM_TYPE } from "../../reducers/itemTypeReducer";
import { HANDLER_EXPECTED_DELIVERY_TIME } from "../../reducers/expectedDeliveryTimeReducer";
import { HANDLER_ITEM_SIZE } from "../../reducers/itemSizeReducer";
import { HANDLER_SERVICE_TYPE } from "../../reducers/serviceTypeReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../reducers/customerEmailReducer";

// for josephphillips206@gmail.com, PNW Transport

const OrderForm20 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.FULL_NAME_OF_PICKUP), "ex. John Doe", true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_PICKUP), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'example@gmail.com', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.PICK_UP_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
                fieldJSONCreator(DATE_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_DATE), '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_EXPECTED_PICKUP_DATE),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_TIME),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVERY_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Location", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_ITEM_TYPE, [
                    {label: "Commercial", value: "Commercial"},
                    {label: "Residential", value: "Residential"},
                ]),
                fieldJSONCreator(TEXT_INPUT_FIELD, "If Commercial, Name of Location", "", false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_DELIVERY_TIME),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.ITEM_AND_SERVICE_TYPE)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Approximate Weight in lbs", "0 lbs", true, LAYOUT_DEFAULT, HANDLER_ITEM_SIZE),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, t(FORM_LOCALE_KEYS.SERVICE_TYPE), '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_SERVICE_TYPE, [
                    {label: "Standard Service", value: "Standard Service"},
                    {label: "Rush (1-3 hours)", value: "Rush (1-3 hours)"},
                ]),
                fieldJSONCreator(TEXTAREA_FIELD, "Description of freight, pallet space total, lift-gate required, additional information", 
                    "Description of freight:\nPallet space total:\nLift-gate required:" , true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
            ]
        },
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={courierEmailTemplate1}/>
        </>
    )
}

export default OrderForm20;

export const HANDLER_CUSTOMER_EMAIL = "HANDLER_CUSTOMER_EMAIL";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_CUSTOMER_EMAIL:
            return action.payload;
        default:
            return state;
    }
}



import {getConfigWithAuth, getConfigWithoutAuth, getCustomConfigWithAuth} from "./configBuilder.js";
import apiClient from "./apiClient"

export const getJSON = (url) => {
    return apiClient.get(url, getConfigWithoutAuth());
}

export const getSecuredJSON = (url) => {
    return apiClient.get(url, getConfigWithAuth());
}

export const postJSON = (url, data) => {
    return apiClient.post(url, data, getConfigWithoutAuth())
}

export const postSecuredJSON = (url, data) => {
    return apiClient.post(url, data, getConfigWithAuth())
}

export const postSecureJSONWithCustomKey = (url, data, key) => {
    return apiClient.post(url, data, getCustomConfigWithAuth(key))
}

import FieldLabel from "./fieldLabel";
import {What3wordsAutosuggest} from "@what3words/react-components";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

const W3wAddressInput = ({config}) => {
    const [w3wVal, setW3wVal] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: config.handlerType,
            payload: w3wVal
        })
    }, [w3wVal])

    return (
        <div>
            <FieldLabel config={config}/>
            <What3wordsAutosuggest api_key="FZ815J7E">
                <input className="form-control" id="w3w" type="text" value={w3wVal} onChange={(e) => {
                    setW3wVal(e.target.value)
                }}/>
            </What3wordsAutosuggest>
        </div>
    )
}

export default W3wAddressInput;
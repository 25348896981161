import React from "react";
export const ADDITIONAL_INFO_DIV = "ADDITIONAL_INFO_DIV";

const AdditionalInfo = ({config}) => {
    return (
        <div className="pt-3 text-center">
            {config.placeholder}
        </div>
    )
}

export default AdditionalInfo;

export const HANDLER_DELIVERY_ADDRESS_TYPE = "HANDLER_DELIVERY_ADDRESS_TYPE";

export default (state = "street", action) => {
    switch (action.type) {
        case HANDLER_DELIVERY_ADDRESS_TYPE:
            return action.payload;
        default:
            return state;
    }
}


import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkStripeConnectionStatus } from "../../actions/paymentActions/checkStripeConnection";
import useStateMapper from "./stateMapperHook";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_TIPS } from "../../reducers/tipsReducer";
import estimatePayment from "../../actions/paymentActions/paymentEstimationAction";
import { HANDLER_PAYMENT_METHOD } from "../../reducers/paymentMethodReducer";
import { CLIENT_SECRET } from "../../reducers/clientSecretReducer";
import { ESTIMATED_INFORMATION } from "../../reducers/estimatedInfoReducer";
import { clearFromCache } from "../../util/localCache";
import { HANDLER_ORDER_NUMBER } from "../../reducers/orderNumberReducer";
import { retrieveClientSecret } from "../../actions/paymentActions/clientSecretAction";
import { HANDLER_DELIVERY_FEE } from "../../reducers/deliveryFeeReducer";
import { PAID_FORM_ERROR_SET } from "../../reducers/paidFormErrorReducer";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { t } from 'i18next';

export const useStripePaidForm = (companyInfo) => {
    const dispatch = useDispatch();
    const [stateMapper] = useStateMapper();

    const deliveryLocation = useSelector(state => state.deliveryLocationInfo);
    const pickupLocation = useSelector(state => state.pickupLocationInfo);
    const stripeDetails = useSelector(state => state.stripeDetails);
    const estimatedInfo = useSelector(state => state.estimatedInfo);

    const [isEstimating, setIsEstimating] = useState(false);
    const [estimationComplete, setEstimationComplete] = useState(false);

    useEffect(() => {
        if (companyInfo) dispatch(checkStripeConnectionStatus(companyInfo.getAdminId()));
    }, [companyInfo])

    useEffect(() => {
        if (estimatedInfo) {
            let data = {
                dispatcherId: companyInfo.getAdminId(),
                chargeAmount: estimatedInfo.getChargeAmountInCents(),
                applicationFee: estimatedInfo.getApplicationFee(),
                currency: companyInfo.getCurrencyISOCode()
            }
            if(estimatedInfo.isWithinLimit()) {
                dispatch(retrieveClientSecret(data));
                dispatch({
                    type: HANDLER_DELIVERY_FEE,
                    payload: Number(Number(estimatedInfo.getChargeAmountInDollar() - stateMapper(HANDLER_TIPS)).toFixed(2))
                })
                setEstimationComplete(true);
            }
            else {
                dispatch({type: PAID_FORM_ERROR_SET, payload: t(FORM_LOCALE_KEYS.DISTANCE_ABOVE_LIMIT)});
            }
            setIsEstimating(false);
        }
    }, [estimatedInfo, companyInfo])

    const getEstimateData = () => {
        let data = {
            from: stateMapper(HANDLER_RESTAURANT_ADDRESS),
            to: stateMapper(HANDLER_CUSTOMER_ADDRESS),
            tip: stateMapper(HANDLER_TIPS),
        };

        if (deliveryLocation !== null) {
            data.to = `${deliveryLocation.lat},${deliveryLocation.lng}`
        }

        if (pickupLocation !== null) {
            data.from = `${pickupLocation.lat},${pickupLocation.lng}`;
        }

        return data;
    }

    const estimate = () => {
        dispatch(estimatePayment(companyInfo.companyId, getEstimateData()));
        setIsEstimating(true);
        dispatch({
            type: HANDLER_PAYMENT_METHOD,
            payload: "credit_card"
        });
    };

    const resetEstimation = () => {
        dispatch({
            type: CLIENT_SECRET,
            payload: null
        })
        dispatch({
            type: ESTIMATED_INFORMATION,
            payload: null
        })
        clearFromCache(stateMapper(HANDLER_ORDER_NUMBER));
        setEstimationComplete(false);
    }

    return [
        stripeDetails, 
        isEstimating,
        estimationComplete,
        estimate,
        resetEstimation
    ]
}


export const HANDLER_RESTAURANT_CONTACT_NAME = "HANDLER_RESTAURANT_CONTACT_NAME";

export default (state = "", action) => {
    switch (action.type) {
        case HANDLER_RESTAURANT_CONTACT_NAME:
            return action.payload;
        default:
            return state;
    }
}

import React, {useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import Card from "../paidComponents/card";
import CardTitle from "../paidComponents/cardTitle";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";

const StripePaymentElem = ({orderID, handlerList}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [showSpinner, setShowSpinner] = useState(false);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            if (!stripe || !elements) {
                return;
            }

            setShowSpinner(true);

            const {error} = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/paymentSuccess/${orderID}`,
                },
            });


            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                setShowSpinner(false);
                setErrorMessage(error.message);
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        }catch (e) {
            console.log(e);
            setShowSpinner(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Card>
                <CardTitle title={t(FORM_LOCALE_KEYS.SECURE_PAYMENT)}/>
                <div className="d-flex secondary-text mb-3">
                    <span>{t(FORM_LOCALE_KEYS.SECURE_PAYMENT_WITH_STRIPE)}</span>
                </div>
                <PaymentElement/>
            </Card>
            <div className="text-center my-4 mx-2">
                <button id="paymentBtn" type="submit" className="btn btnPay" disabled={!stripe || showSpinner} >
                    {showSpinner && <div className="spinner-border" role="status"/>}
                    {!showSpinner && "Pay total"}
                </button>
            </div>
            {/* Show error message to your customers */}
            {errorMessage && <div className="text-center p-4">{errorMessage}</div>}
        </form>
    )
}

export default StripePaymentElem;

const PAYMENT_INTENT = "/connect/platform/payment-intent";
const PAYMENT_CONNECTION = "/connect/status/external/";

export const getPaymentIntentURL = () => {
    return PAYMENT_INTENT;
}

export const getPaymentConnectionStatusURL = (id) => {
    return PAYMENT_CONNECTION + id;
}

import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    REMEMBER_SECTION,
    sectionTitleJSONCreator,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_REMEMBER_INFO} from "../../reducers/rememberInfoReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {RestaurantEmailTemplate3} from "../../util/customEmailTemplates/restaurantEmailTemplate3";

const RestaurantOrderForm3 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const restaurantOrderForm3Json = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_FORM) + " (معلومات المرسل)"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME) + " (الاسم)", '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO) + " (رقم الهاتف)", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER, null, true),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.ADDRESS) + " (العنوان)", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true),
                fieldJSONCreator(REMEMBER_SECTION, t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO) + " (تذكر معلومات المرسل)", '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO) + " (معلومات المستلم)"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.CUSTOMER_NAME) + " (الاسم)", 'ex. John Doe', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO) + " (رقم الهاتف)", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.ADDRESS) + " (العنوان)", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.INFO_FOR_DRIVER)),
            sectionFields: [
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME) + " (توقيت التسليم)", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_EXPECTED_PICKUP_TIME),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION) + " (معلومات الطلبية)", t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={restaurantOrderForm3Json}
                             emailTemplateFunc={RestaurantEmailTemplate3}/>
        </>
    )
}

export default RestaurantOrderForm3;

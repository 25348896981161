import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import de from '../resources/locale/de.json';
import en from '../resources/locale/en.json';
import es from '../resources/locale/es.json';
import fr from '../resources/locale/fr.json';
import it from '../resources/locale/it.json';
import pt from '../resources/locale/pt.json';
import ru from '../resources/locale/ru.json';
import tr from '../resources/locale/tr.json';
import bg from "../resources/locale/bg.json";

const resources = {
    de: {
        translation: de
    },
    en: {
        translation: en
    },
    es: {
        translation: es
    },
    fr: {
        translation: fr
    },
    it: {
        translation: it
    },
    pt: {
        translation: pt
    },
    ru: {
        translation: ru
    },
    tr: {
        translation: tr
    },
    bg: {
        translation: bg
    }
};


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;

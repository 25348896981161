export const HANDLER_REMEMBER_INFO = "HANDLER_REMEMBER_INFO";

export default (state = false, action) => {
    switch (action.type) {
        case HANDLER_REMEMBER_INFO:
            return action.payload;
        default:
            return state;
    }
}


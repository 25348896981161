const COMPANY_INFO_API = "/getCompanyInfobyIdentificationNumber/";
const SEND_EMAIL_API = "/sendEmail";
const SEND_EMAIL_WITHOUT_SIGNATURE = "/sendEmailWithoutSignature";
const POST_ORDER = "/Orders";

export function getCompanyDetailsURL(companyID) {
    return COMPANY_INFO_API + companyID;
}

export function getSendEmailURL() {
    return SEND_EMAIL_API;
}

export function getSendEmailWithoutSignature() {
    return SEND_EMAIL_WITHOUT_SIGNATURE;
}

export function getOrderPostURL() {
    return POST_ORDER;
}

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT, REMEMBER_SECTION, sectionTitleJSONCreator, 
    SELECTOR_INPUT_FIELD, TEXT_INPUT_FIELD, LAYOUT_HALF_SPACED_SINGLE,
    TEXTAREA_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { courierEmailTemplate1 } from "../../util/emailTemplates/courierEmailTemplate1";
import { ADDRESS_INPUT_WITH_W3W } from "../common/components/addressInputWithW3W";
import { HANDLER_ITEM_TYPE } from "../../reducers/itemTypeReducer";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../reducers/customerEmailReducer";
import { HANDLER_REMEMBER_INFO } from "../../reducers/rememberInfoReducer";
import { ADDITIONAL_INFO_DIV } from "../common/components/additionalInfo";

// for maikel@londonmedicallaboratory.co.uk, London Medical Laboratory

const OrderForm33 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator("Pharmacy/Clinic Information"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Pharmacy/Clinic name", "", true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, "Pharmacy/Clinic Address", "Kindly write the full address including postal code", true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Delivery information - London Medical Laboratory"),
            sectionFields: [
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, "London Medical Laboratory Address", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Pharmacy/Clinic phone number (for notification)", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER, null, true),
                fieldJSONCreator(EMAIL_INPUT_FIELD, "Pharmacy/Clinic Email (for notification)", 'example@gmail.com', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL, null, true),
                fieldJSONCreator(REMEMBER_SECTION, "Remember delivery information", '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Service Type"),
            sectionFields: [
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Service Type", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_ITEM_TYPE, [
                    {label: "Weekdays - 11:00 - 15:00", value: "Weekdays - 11:00 - 15:00"},
                    {label: "Weekdays - 15:00 - 18:00", value: "Weekdays - 15:00 - 18:00"},
                    {label: "Saturday - 11:00 - 15:00", value: "Saturday - 11:00 - 15:00"},
                ]),
                fieldJSONCreator(TEXTAREA_FIELD, "Additional notes or instructions", t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
                fieldJSONCreator(ADDITIONAL_INFO_DIV, "", (<ul style={{"text-align": "left"}}>
                    <li>Please note that you can book collections until 15:00 Monday to Friday. and 13:00 on Saturday.</li>
                    <li>THIS FORM IS FOR LONDON COLLECTIONS Only.</li>
                    <li>Last collection on Saturdays is at 15:00.</li>
                    <li>Collections may overlap due to Rain or traffic.</li>
                    </ul>))
            ]
        },
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={courierEmailTemplate1}/>
        </>
    )
}

export default OrderForm33;

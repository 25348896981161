import React from "react";
import {useSelector} from "react-redux";

const CompanyName = () => {
    const companyDetails = useSelector(state => state.companyDetails);

    if (companyDetails == null) return <></>;

    const companyName = companyDetails.getCompanyName();

    return (
        <h4 className="text-center fw-bold pt-2 pb-4">{companyName}</h4>
    )
}

export default CompanyName;

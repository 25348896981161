import {defaultDeliveryTime} from "../util/defaultTimeHelper";

export const HANDLER_EXPECTED_DELIVERY_TIME = "HANDLER_EXPECTED_DELIVERY_TIME";

export default (state = defaultDeliveryTime().time, action) => {
    switch (action.type) {
        case HANDLER_EXPECTED_DELIVERY_TIME:
            return action.payload;
        default:
            return state;
    }
}

import React from "react";

const DoubleInputGrid = ({firstComponent, secondComponent}) => {
    return (
        <>
            <div className="col-sm-6">
                {firstComponent}
            </div>
            <div className="col-sm-6">
                {secondComponent}
            </div>
        </>
    )
}

export default DoubleInputGrid;

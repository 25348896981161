import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Selector from "../../common/components/selector";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import AddressInput from "../../common/components/addressInput";
import TimeInput from "../../common/components/timeInput";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import EmailInput from "../../common/components/emailInput";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import DateInput from "../../common/components/dateInput";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import TextAreaInput from "../../common/components/textAreaInput";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import ValueInput from "../../common/components/valueInput";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {useDispatch} from "react-redux";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";
import {HANDLER_ADDITIONAL_ADDRESS} from "../../../reducers/additionalAddressInfo";
import Image from "../../common/components/image";
import locationImg from "../../../resources/Images/location.png";


const CustomInputBuilder = ({configJSON, loading}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch({type: HANDLER_RESTAURANT_ADDRESS, payload: "1306 e gun hill rd bronx ny 10469"});
    }, [])
    return (
        <>
            <div>
                <Card>
                    <CardTitle title={t(FORM_LOCALE_KEYS.PICK_UP_FORM)}/>
                    <Image imgSrc={locationImg}></Image>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_PICKUP_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME)
                    }}/>
                </Card>
                <Card>
                    <CardTitle title={"Drop off"}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_NAME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_RECIPIENT)
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_PHONE_NUMBER,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_RECIPIENT)
                    }}/>
                    <EmailInput config={{
                        handlerType: HANDLER_CUSTOMER_EMAIL,
                        required: false,
                        placeholder: t(FORM_LOCALE_KEYS.EMAIL_ADDRESS_OF_RECIPIENT) + " (" + t(FORM_LOCALE_KEYS.OPTIONAL) + ")"
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_CUSTOMER_ADDRESS,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_RECIPIENT)
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_ITEM_TYPE,
                        required: true,
                        placeholder: "Vehicle: Brand/Modal/Year"
                    }}
                    />
                    <div>
                        <div className="w-100">
                            <label className="form-label"></label>
                            <DateInput config={{
                                handlerType: HANDLER_EXPECTED_DELIVERY_DATE,
                                required: true,
                                placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE)
                            }}/>
                        </div>
                    </div>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_DELIVERY_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME)
                    }}/>

                    <NameInput config={{
                        handlerType: HANDLER_DELIVERY_INSTRUCTION,
                        required: true,
                        placeholder: "What is the problem?"
                    }}
                    />
                    <Selector config={{
                        handlerType: HANDLER_SERVICE_TYPE, required: true, placeholder: 'Item Type', selectionOptions: [{
                            label: "Spare Installation only if you Have the Spare",
                            value: "Spare Installation only if you Have the Spare"
                        }, {label: "Fuel Delivery", value: "Fuel Delivery"},
                            {
                                label: "Jump Start",
                                value: "Jump Start"
                            }, {label: "Any Other Services Please Call", value: "Any Other Services Please Call"}]
                    }}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && "See my price"}
                </button>
            </div>
        </>
    )
}

export default CustomInputBuilder;

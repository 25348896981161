import {dateFormatFix, defaultPickUpTime} from "../util/defaultTimeHelper";

export const HANDLER_EXPECTED_PICKUP_DATE = "HANDLER_EXPECTED_PICKUP_DATE";

export default (state = dateFormatFix(defaultPickUpTime().date), action) => {
    switch (action.type) {
        case HANDLER_EXPECTED_PICKUP_DATE:
            return action.payload;
        default:
            return state;
    }
}

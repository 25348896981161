import React, {useEffect, useState} from "react";
import useStateMapper from "../../customHooks/stateMapperHook";
import {useDispatch, useSelector} from "react-redux";
import {formDataMapper} from "../../../util/formDataMapper";
import {ensureRequiredFields} from "../../../util/formBuilderHelper";
import {storeInCache} from "../../../util/localCache";
import {HANDLER_ORDER_NUMBER} from "../../../reducers/orderNumberReducer";
import Card from "../../common/paidComponents/card";
import PickupDetailsSection from "../../common/paidComponents/pickupDetailsSection";
import CardTitle from "../../common/paidComponents/cardTitle";
import DeliveryDetailsSection from "../../common/paidComponents/deliveryDetailsSection";
import StripeWrapper from "../../common/paymentComponent/stripeWrapper";
import CustomOrderDetails from "./customOrderDetails";
import {HANDLER_ADDITIONAL_ADDRESS} from "../../../reducers/additionalAddressInfo";

const CustomPaymentPageBuilder = ({configJSON, visible}) => {
    const [stateMapper, mailDataGenerator] = useStateMapper();
    const companyDetails = useSelector(state => state.companyDetails);
    const estimatedInfo = useSelector(state => state.estimatedInfo);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(visible) {
            let handlers = configJSON;
            let data = {};
            handlers.map((prop) => {
                let dataPropName = formDataMapper("simpleForm", prop);
                if (dataPropName) data[dataPropName] = stateMapper(prop);
            });
            data["totalOrderCost"] = estimatedInfo.getChargeAmountInDollar();
            data = ensureRequiredFields(data);
            if (stateMapper(HANDLER_ADDITIONAL_ADDRESS) !== "") {
                data["deliveryInstruction"] += "   \nApartment Number: " + stateMapper(HANDLER_ADDITIONAL_ADDRESS);
            }
            storeInCache(data["orderNumber"], JSON.stringify(data));
            storeInCache("orderHeader", companyDetails.getConstructedHeaderKey());
            dispatch({
                type: HANDLER_ORDER_NUMBER,
                payload: data["orderNumber"]
            });
        }
    },[visible]);
    return (
        <div className="">
            <Card>
                <CardTitle title={"PICK-UP DETAILS"}></CardTitle>
                <PickupDetailsSection/>
                <hr className="hr-line"/>
                <CardTitle title={"DELIVERY DETAILS"}/>
                <DeliveryDetailsSection/>
                <hr className="hr-line"/>
                <CardTitle title={"ORDER SUMMERY"}/>
                <CustomOrderDetails/>
            </Card>
            <StripeWrapper/>
        </div>
    )
}

export default CustomPaymentPageBuilder;
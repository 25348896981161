import React from "react";
import useStateMapper from "../../customHooks/stateMapperHook";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import DetailsSection from "./detailsSection";

const PickupDetailsSection = () => {
    const [stateMapper] = useStateMapper();
    const pickUpAddress = stateMapper(HANDLER_RESTAURANT_ADDRESS);
    const pickUpPhone = stateMapper(HANDLER_RESTAURANT_PHONE_NUMBER);
    const pickUpName = stateMapper(HANDLER_RESTAURANT_NAME);
    const pickUpTime = stateMapper(HANDLER_EXPECTED_PICKUP_TIME);

    return (
        <DetailsSection address={pickUpAddress} time={pickUpTime} name={pickUpName} phone={pickUpPhone} date={null}/>
    )
}

export default PickupDetailsSection;

import {postEstimatePayment} from "../../util/APIHelper/queries";
import {buildEstimation} from "../../factory/paymentDetailsFactory";
import {ESTIMATED_INFORMATION} from "../../reducers/estimatedInfoReducer";

const estimatePayment = (companyId, data) => async dispatch => {
    try {
        let response = await postEstimatePayment(companyId, data);
        if(response.data) {
            let estimatedInfo = buildEstimation(response.data.distance, response.data.chargeAmount, response.data.applicationFee, response.data.distanceUnit, response.data.paidDistanceUpperLimit);
            dispatch({
                type: ESTIMATED_INFORMATION,
                payload: estimatedInfo
            })
        }
    } catch (e) {
        console.error(e);
        dispatch({
            type: ESTIMATED_INFORMATION,
            payload: null
        })
    }
}

export default estimatePayment;

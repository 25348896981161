export const PICK_UP_LOCATION_LAT_LNG = "PICK_UP_LOCATION_LAT_LNG";

export default (state = null, action) => {
    switch (action.type) {
        case PICK_UP_LOCATION_LAT_LNG:
            return action.payload;
        default:
            return state;
    }
}

const BRANDED_PREMIUM = "BRANDED_PREMIUM";
const BRANDED_PREMIUM_PLUS = "BRANDED_PREMIUM_PLUS";
const BRANDED_PREMIUM_WITHOUT_SMS = "BRANDED_PREMIUM_WITHOUT_SMS";
const BUSINESS_ADVANCED = "BUSINESS_ADVANCED";
const BUSINESS_ADVANCED_PLUS = "BUSINESS_ADVANCED_PLUS";
const STARTER = "STARTER";
const BRANDED_PREMIUM_MARKETING = 'BRANDED_PREMIUM_MARKETING';
const BRANDED_ELITE = "BRANDED_ELITE";

const allowedPlans = [
    BRANDED_PREMIUM,
    BRANDED_PREMIUM_PLUS,
    BRANDED_PREMIUM_WITHOUT_SMS,
    BUSINESS_ADVANCED,
    BUSINESS_ADVANCED_PLUS,
    BRANDED_PREMIUM_MARKETING,
    BRANDED_ELITE,
]

export class CompanyDetails {
    constructor(companyId, companyName, companyLogo, companyCountryCode, language, username, password, email, adminId, accountPlan, currency = "$", currencyCode = "USD", deliveryOnlyEnabled = false, deliveryAddress, deliveryPhone) {
        this.companyId = companyId;
        this.companyName = companyName;
        this.companyLogo = companyLogo;
        this.companyCountryCode = companyCountryCode;
        this.language = language;
        this.username = username;
        this.password = password;
        this.email = email;
        this.adminId = adminId;
        this.accountPlan = accountPlan;
        this.currency = currency;
        this.currencyCode = currencyCode;
        this.deliveryOnlyEnabled = deliveryOnlyEnabled;
        this.deliveryAddress = deliveryAddress;
        this.deliveryPhone = deliveryPhone;
    }

    getCompanyName() {
        return this.companyName;
    }

    getCompanyLogo() {
        return this.companyLogo;
    }

    getCountryCode() {
        return this.companyCountryCode;
    }

    getLanguage() {
        return this.language;
    }

    getEmail() {
        return this.email;
    }

    getAdminId() {
        return this.adminId
    }

    getConstructedHeaderKey() {
        return btoa(this.username + ":" + this.password);
    }

    getCurrencyISOCode() {
        if(this.currency === "C$") this.currencyCode = "cad";
        return this.currencyCode.toLowerCase();
    }

    isDeliveryOnly() {
        return this.deliveryOnlyEnabled || false;
    }

    getDeliveryOnlyAddress() {
        return this.deliveryAddress;
    }

    getDeliveryOnlyPhone() {
        return this.deliveryPhone;
    }

    isUnderStarterPlan() {
        return this.accountPlan === STARTER;
    }

    hasBrandedAccess () {
        return allowedPlans.includes(this.accountPlan)
    }
}

export const HANDLER_ADDITIONAL_ADDRESS = "HANDLER_ADDITIONAL_ADDRESS";

export default (state = "", action) => {
    switch (action.type) {
        case HANDLER_ADDITIONAL_ADDRESS:
            return action.payload;
        default:
            return state;
    }
}

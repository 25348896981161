import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import EXTERNAL_FORM_KEYS from "../../../resources/locale/keys";
import React, {useEffect, useRef, useState} from "react";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import {HANDLER_DELIVERY_ADDRESS_TYPE} from "../../../reducers/deliveryAddressTypeReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_PICKUP_ADDRESS_TYPE} from "../../../reducers/pickupAddressTypeReducer";
import AddressInput from "./addressInput";
import {What3wordsAutosuggest} from "@what3words/react-components";
import FieldLabel from "./fieldLabel";
import W3wAddressInput from "./w3wAddressInput";

export const ADDRESS_INPUT_WITH_W3W = "ADDRESS_INPUT_WITH_W3W";

const AddressInputWithW3W = ({config}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const defaultAddrTypeRef = useRef(null);
    const w3wAddrTypeRef = useRef(null);

    const [selectedAddrType, setSelectedAddrType] = useState("street");

    useEffect(() => {
        if (config.handlerType === HANDLER_CUSTOMER_ADDRESS) {
            dispatch({
                type: HANDLER_DELIVERY_ADDRESS_TYPE,
                payload: selectedAddrType
            })
        } else {
            dispatch({
                type: HANDLER_PICKUP_ADDRESS_TYPE,
                payload: selectedAddrType
            })
        }
    }, [selectedAddrType])


    return (
        <>
            {selectedAddrType === "street" && <AddressInput config={config}/>}
            {selectedAddrType === "what3words" && <W3wAddressInput config={config}/>}
            <div className="d-flex">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name={config.handlerType + "name"}
                           id={config.handlerType + "1"} ref={defaultAddrTypeRef}
                           onClick={() => {
                               setSelectedAddrType("street")
                           }} defaultChecked={true}/>
                    <label className="form-check-label" htmlFor={config.handlerType + "1"}>
                        {t(EXTERNAL_FORM_KEYS.STREET_ADDRESS)}
                    </label>
                </div>
                <div className="form-check ms-3">
                    <input className="form-check-input" type="radio" name={config.handlerType + "name"}
                           id={config.handlerType + "2"} ref={w3wAddrTypeRef}
                           onClick={() => {
                               setSelectedAddrType("what3words")
                           }}/>
                    <label className="form-check-label" htmlFor={config.handlerType + "2"}>
                        <a href="https://what3words.com" target="_blank">what3words</a>
                        <span>{` ${t(EXTERNAL_FORM_KEYS.ADDRESS)}`}</span>
                    </label>
                </div>
            </div>
        </>
    )
}

export default AddressInputWithW3W;


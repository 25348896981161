import {postOrderDetails} from "../util/APIHelper/queries";
import {sendEmailWithoutSignature, sendEmailWithSignature} from "./emailSendingAction";
import {ORDER_SUBMIT_STATE} from "../reducers/orderSubmitStateReducer";

export const ORDER_SUBMIT_STATE_SUCCESS = "ORDER_SUBMIT_STATE_SUCCESS";
export const ORDER_SUBMIT_STATE_FAILED = "ORDER_SUBMIT_STATE_FAILED";

export const submitOrder = (data, key, emailArr) => async dispatch => {
    try {
        let response = await postOrderDetails(data, key);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //Order placed successfully
                if (emailArr?.[0]) dispatch(sendEmailWithSignature(emailArr[0]));
                if (emailArr?.[1]) dispatch(sendEmailWithoutSignature(emailArr[1]));
                dispatch({type: ORDER_SUBMIT_STATE, payload: ORDER_SUBMIT_STATE_SUCCESS});
            } else {
                dispatch({type: ORDER_SUBMIT_STATE, payload: { STATE:ORDER_SUBMIT_STATE_FAILED, errMsg: response.data.response}});
            }
        }
        else throw new Error();
    } catch (e) {
        console.error(e);
        dispatch({type: ORDER_SUBMIT_STATE, payload: { STATE:ORDER_SUBMIT_STATE_FAILED, errMsg: "Order placement failed"}});
    }
}

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";

import {
    DATE_INPUT_FIELD,
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_TANGLE_WITH_NEXT, REMEMBER_SECTION, sectionTitleJSONCreator,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { HANDLER_ORDER_NUMBER } from "../../reducers/orderNumberReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { useTranslation } from "react-i18next";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { RestaurantEmailTemplateSpecial } from "../../util/emailTemplates/restaurantEmailTemplateSpecial";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";
import { HANDLER_EXPECTED_PICKUP_DATE } from "../../reducers/expectedPickUpDateReducer";
import { HANDLER_EXPECTED_PICKUP_TIME } from "../../reducers/expectedPickupTimeReducer";
import { HANDLER_CUSTOMER_NAME } from "../../reducers/customerNameReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../reducers/customerEmailReducer";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_TOTAL_ORDER_COST } from "../../reducers/totalOrderCostReducer";
import { HANDLER_EXPECTED_DELIVERY_TIME } from "../../reducers/expectedDeliveryTimeReducer";
import { HANDLER_REMEMBER_INFO } from "../../reducers/rememberInfoReducer";

// ryan.thiel@delv.ca

const OrderForm29 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
const formConfigJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_FORM)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.ORDER_NUMBER), 'ex. 1234', true, LAYOUT_DEFAULT, HANDLER_ORDER_NUMBER),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Business Name", 'Business', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, null, true),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true),
                fieldJSONCreator(DATE_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_DATE), '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_EXPECTED_PICKUP_DATE),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_TIME),
                fieldJSONCreator(REMEMBER_SECTION, t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO), '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.CUSTOMER_NAME), 'John Doe', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.CUSTOMER_EMAIL), 'test@example.com', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Customer Phone Number", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_DELIVERY_TIME),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Order Value", "", false, LAYOUT_DEFAULT, HANDLER_TOTAL_ORDER_COST),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    const template = onlyDispatcherMail(RestaurantEmailTemplateSpecial);

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={formConfigJson}
                             emailTemplateFunc={template}/>
        </>
    )
}

export default OrderForm29;

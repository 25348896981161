import React, {useEffect, useRef, useState} from "react";
import PaidFormLogoSection from "../../common/paidComponents/paidFormLogoSection";
import OrderDetailsInputBuilder from "./orderDetailsInputBuilder";
import OrderPaymentPageBuilder from "../../common/paidFormBuilders/orderPaymentPageBuilder";
import useStateMapper from "../../customHooks/stateMapperHook";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import estimatePayment from "../../../actions/paymentActions/paymentEstimationAction";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import {CLIENT_SECRET} from "../../../reducers/clientSecretReducer";
import {ESTIMATED_INFORMATION} from "../../../reducers/estimatedInfoReducer";
import {clearFromCache} from "../../../util/localCache";
import {HANDLER_ORDER_NUMBER} from "../../../reducers/orderNumberReducer";
import {findCompanyDetails} from "../../../actions/companyDetailsAction";
import {checkStripeConnectionStatus} from "../../../actions/paymentActions/checkStripeConnection";
import {retrieveClientSecret} from "../../../actions/paymentActions/clientSecretAction";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import OrderPaymentWithoutStripeBuilder from "./orderPaymentWithoutStripeBuilder";
import OrderAlerts from "../../common/components/orderAlerts";
import {ORDER_SUBMIT_STATE_FAILED, ORDER_SUBMIT_STATE_SUCCESS} from "../../../actions/orderSubmitAction";
import i18next from "i18next";
import {HANDLER_PAYMENT_METHOD} from "../../../reducers/paymentMethodReducer";
import {HANDLER_DELIVERY_FEE} from "../../../reducers/deliveryFeeReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";

const configJSON = [HANDLER_ORDER_NUMBER, HANDLER_RESTAURANT_NAME, HANDLER_RESTAURANT_PHONE_NUMBER, HANDLER_RESTAURANT_ADDRESS, HANDLER_EXPECTED_PICKUP_TIME, HANDLER_CUSTOMER_NAME, HANDLER_CUSTOMER_PHONE_NUMBER, HANDLER_CUSTOMER_EMAIL, HANDLER_CUSTOMER_ADDRESS, HANDLER_EXPECTED_DELIVERY_TIME, HANDLER_EXPECTED_DELIVERY_DATE,
    HANDLER_DELIVERY_INSTRUCTION, HANDLER_PAYMENT_METHOD, HANDLER_DELIVERY_FEE, HANDLER_TIPS]

const CustomFormWithoutPayment14 = () => {
    const [showPayment, setShowPayment] = useState(false);
    const [stateMapper] = useStateMapper();
    const [showSpinner, setShowSpinner] = useState(false);
    const companyInfo = useSelector(state => state.companyDetails);
    const estimatedInfo = useSelector(state => state.estimatedInfo);
    const tips = stateMapper(HANDLER_TIPS);
    const orderSubmitState = useSelector(state => state.orderSubmitState);
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const params = useParams();
    const deliveryLocation = useSelector(state => state.deliveryLocationInfo);
    const pickupLocation = useSelector(state => state.pickupLocationInfo);
    const getEstimateData = () => {
        let data = {
            from: stateMapper(HANDLER_RESTAURANT_ADDRESS),
            to: stateMapper(HANDLER_CUSTOMER_ADDRESS),
            tip: tips
        };

        if (deliveryLocation !== null) {
            data.to = `${deliveryLocation.lat},${deliveryLocation.lng}`
        }

        if (pickupLocation !== null) {
            data.from = `${pickupLocation.lat},${pickupLocation.lng}`;
        }

        return data;
    }
    const handleSubmit = (ev) => {
        ev.preventDefault();
        dispatch(estimatePayment(companyInfo.companyId, getEstimateData()));
        setShowSpinner(true);
        dispatch({
            type: HANDLER_PAYMENT_METHOD,
            payload: "cash"
        });
    }

    const handleBackToEdit = (ev) => {
        ev.preventDefault();
        dispatch({
            type: CLIENT_SECRET,
            payload: null
        })
        dispatch({
            type: ESTIMATED_INFORMATION,
            payload: null
        })
        clearFromCache(stateMapper(HANDLER_ORDER_NUMBER));
        setShowPayment(false);
    }

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, [params.id])

    useEffect(() => {
        if (companyInfo) dispatch(checkStripeConnectionStatus(companyInfo.getAdminId()));
    }, [companyInfo])

    useEffect(() => {
        if (estimatedInfo) {
            setShowPayment(true);
            setShowSpinner(false);
            dispatch({
                type: HANDLER_DELIVERY_FEE,
                payload: Number(Number(estimatedInfo.getChargeAmountInDollar() - stateMapper(HANDLER_TIPS)).toFixed(2))
            })
        }
    }, [estimatedInfo])

    useEffect(() => {
        if (orderSubmitState === ORDER_SUBMIT_STATE_SUCCESS || orderSubmitState?.STATE === ORDER_SUBMIT_STATE_FAILED) {
            setShowPayment(false);
        }
    }, [orderSubmitState])

    useEffect(() => {
        if (companyInfo !== null)
            i18next.changeLanguage(companyInfo.getLanguage());
    }, [companyInfo?.getLanguage()]);

    return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <PaidFormLogoSection showEdit={showPayment} backToEdit={handleBackToEdit}/>
            <div className="container-md">
                <form id="externalOrderForm" ref={formRef} className={`col-lg-6 offset-lg-3 pb-5 ${showPayment ? "d-none" : ""}`} onSubmit={handleSubmit}>
                    <OrderDetailsInputBuilder visible={!showPayment} loading={showSpinner}/>
                    <OrderAlerts/>
                </form>
            </div>
            <div id="paymentSection" className={`container-md ${showPayment ? "" : "d-none"}`}>
                <div className="col-lg-6 offset-lg-3">
                    <OrderPaymentWithoutStripeBuilder configJSON={configJSON} emailTemplateFunc={()=>{}} visible={false} firstPageFormRef={formRef}/>
                </div>
            </div>
        </div>
    )
}

export default CustomFormWithoutPayment14;

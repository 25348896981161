import React, {useEffect, useState} from "react";
import Card from "../paidComponents/card";
import CardTitle from "../paidComponents/cardTitle";
import PickupDetailsSection from "../paidComponents/pickupDetailsSection";
import DeliveryDetailsSection from "../paidComponents/deliveryDetailsSection";
import {OrderDetails} from "../paidComponents/orderDetails";
import {formDataMapper} from "../../../util/formDataMapper";
import {ensureRequiredFields} from "../../../util/formBuilderHelper";
import useStateMapper from "../../customHooks/stateMapperHook";
import {useDispatch, useSelector} from "react-redux";
import {ORDER_SUBMIT_STATE_FAILED, ORDER_SUBMIT_STATE_SUCCESS, submitOrder} from "../../../actions/orderSubmitAction";
import OrderAlerts from "../components/orderAlerts";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import i18next from "i18next";
import {PICK_UP_LOCATION_LAT_LNG} from "../../../reducers/pickUpLocationReducer";
import {DELIVERY_LOCATION_LAT_LNG} from "../../../reducers/deliveryLocationReducer";


const OrderPaymentWithoutStripeBuilder = ({configJSON, visible, emailTemplateFunc, firstPageFormRef}) => {
    const [stateMapper, mailDataGenerator, updateLocationInfoInData] = useStateMapper();
    const dispatch = useDispatch();
    const companyDetails = useSelector(state => state.companyDetails);
    const estimatedInfo = useSelector(state => state.estimatedInfo);
    const [loading, setLoading] = useState(false);
    const orderSubmitState = useSelector(state => state.orderSubmitState);
    const {t} = useTranslation();


    useEffect(() => {
        if (orderSubmitState === ORDER_SUBMIT_STATE_SUCCESS || orderSubmitState?.STATE === ORDER_SUBMIT_STATE_FAILED) {
            setLoading(false);
        }
        if (orderSubmitState === null) {
            dispatch({
                type: PICK_UP_LOCATION_LAT_LNG,
                payload: null
            })
            dispatch({
                type: DELIVERY_LOCATION_LAT_LNG,
                payload: null
            })
        }
    }, [orderSubmitState])

    const handleOrderSubmit = (ev) => {
        ev.preventDefault();
        setLoading(true);
        let handlers = configJSON;
        let data = {};
        handlers.map((prop) => {
            let dataPropName = formDataMapper("simpleForm", prop);
            if (dataPropName) data[dataPropName] = stateMapper(prop);
        });
        data["totalOrderCost"] = estimatedInfo.getChargeAmountInDollar();
        data = ensureRequiredFields(data);
        data = updateLocationInfoInData(data);
        const mailData = mailDataGenerator(emailTemplateFunc, data["orderNumber"], data);
        dispatch(submitOrder(data, companyDetails.getConstructedHeaderKey(), mailData));
        firstPageFormRef.current.reset();
    }

    return (
        <div className="">
            <Card>
                <CardTitle title={t(FORM_LOCALE_KEYS.PICKUP_DETAILS)}></CardTitle>
                <PickupDetailsSection/>
                <hr className="hr-line"/>
                <CardTitle title={t(FORM_LOCALE_KEYS.DELIVERY_DETAILS)}/>
                <DeliveryDetailsSection/>
                <hr className="hr-line"/>
                <CardTitle title={t(FORM_LOCALE_KEYS.ORDER_SUMMERY)}/>
                <OrderDetails/>
            </Card>
            <form onSubmit={handleOrderSubmit}>
                <div className="text-center my-4 mx-2">
                    <button id="paymentBtn" type="submit" className="btn btnPay" disabled={loading}>
                        {loading && <div className="spinner-border" role="status"/>}
                        {!loading && t(FORM_LOCALE_KEYS.PLACE_ORDER)}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default OrderPaymentWithoutStripeBuilder;
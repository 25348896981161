import React, {useState} from "react";
import Card from "../common/paidComponents/card";
import CardTitle from "../common/paidComponents/cardTitle";
import NameInput from "../common/components/nameInput";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import AddressInput from "../common/components/addressInput";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import TimeInput from "../common/components/timeInput";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import TextAreaInput from "../common/components/textAreaInput";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import DateInput from "../common/components/dateInput";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../reducers/expectedDeliveryDateReducer";
import EmailInput from "../common/components/emailInput";
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";
import ValueInput from "../common/components/valueInput";
import {HANDLER_TIPS} from "../../reducers/tipsReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";


const OrderDetailsInputBuilderGreek = ({configJSON, loading}) => {
    const {t} = useTranslation();
    return (
        <>
            <div>
                <Card>
                    <CardTitle title={"ΣΗΜΕΊΟ ΠΑΡΑΛΑΒΗΣ"}/>
                    <NameInput config={{handlerType: HANDLER_RESTAURANT_NAME, required: true, placeholder: "Όνομα και Επώνυμο"}}/>
                    <NameInput config={{handlerType: HANDLER_RESTAURANT_PHONE_NUMBER, required: true, placeholder: "Τηλέφωνο"}}/>
                    <AddressInput config={{handlerType: HANDLER_RESTAURANT_ADDRESS, required: true, placeholder: "Διεύθυνση φόρτωσης"}}/>
                    <TimeInput config={{handlerType: HANDLER_EXPECTED_PICKUP_TIME, required: true, placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME)}}/>
                </Card>
                <Card>
                    <CardTitle title={"ΠΑΡΑΔΟΣΗ ΣΕ"}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_NAME, required: true, placeholder: "Όνομα και Επώνυμο Παραλήπτη"}}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_PHONE_NUMBER, required: true, placeholder: "Τηλέφωνο παραλήπτη"}}/>
                    <EmailInput config={{handlerType: HANDLER_CUSTOMER_EMAIL, required:false, placeholder: "Email παραλήπτη" + " (" + "προαιρετικός" + ")"}}/>
                    <AddressInput config={{handlerType: HANDLER_CUSTOMER_ADDRESS, required: true, placeholder: "Διεύθυνση παραλήπτη"}}/>
                    <div>
                        <div className="w-100">
                            <label className="form-label"></label>
                            <DateInput config={{handlerType: HANDLER_EXPECTED_DELIVERY_DATE, required: true, placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE)}}/>
                        </div>
                    </div>
                    <TimeInput config={{handlerType: HANDLER_EXPECTED_DELIVERY_TIME, required: true, placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME)}}/>
                    <TextAreaInput config={{handlerType: HANDLER_DELIVERY_INSTRUCTION, required: true, placeholder: "Παρατηρήσεις αποστολής"}}/>
                    <ValueInput config={{handlerType: HANDLER_TIPS, required: false, placeholder: "Ποσότητα Πακέτων"}}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && "Καταχώρηση παραγγελίας"}
                </button>
            </div>
        </>
    )
}

export default OrderDetailsInputBuilderGreek;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE, sectionTitleJSONCreator, SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_FIELD,
    TIME_24H_INPUT_FIELD,
} from "../../util/formBuilderHelper";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { HANDLER_CUSTOMER_NAME } from "../../reducers/customerNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { HANDLER_EXPECTED_PICKUP_TIME } from "../../reducers/expectedPickupTimeReducer";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { courierEmailTemplate1 } from "../../util/emailTemplates/courierEmailTemplate1";
import { ADDRESS_INPUT_WITH_W3W } from "../common/components/addressInputWithW3W";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_PAYMENT_METHOD } from "../../reducers/paymentMethodReducer";

// for trapaniconsegne@gmail.com, TrapaniConsegne

const OrderForm19 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "ex. John Doe", true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Recapito telefonico del punto di ritiro", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.PICK_UP_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
                fieldJSONCreator(TIME_24H_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_TIME)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVERY_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "ex. John Doe", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_RECIPIENT), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.ADDITIONAL_NOTE), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Metodo di pagamento", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_PAYMENT_METHOD, [
                    {label: "Contanti", value: "cash"},
                    {label: "Carta di credito", value: "credit_card"},
                ])
            ]
        },
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={courierEmailTemplate1}/>
        </>
    )
}

export default OrderForm19;

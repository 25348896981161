import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";

import {
    DATE_INPUT_FIELD, EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT, LAYOUT_HALF_SPACED_SINGLE,
    LAYOUT_TANGLE_WITH_NEXT,
    REMEMBER_SECTION,
    sectionTitleJSONCreator,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_ORDER_NUMBER} from "../../reducers/orderNumberReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_REMEMBER_INFO} from "../../reducers/rememberInfoReducer";
import {useTranslation} from "react-i18next";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {RestaurantEmailTemplateSpecial2} from "../../util/customEmailTemplates/restaurantEmailTemplateSpecial";
import {HANDLER_RESTAURANT_EMAIL} from "../../reducers/restaurantEmailReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";

const OrderFormSpecial3 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormSpecialJson = [
        {
            sectionHeader: sectionTitleJSONCreator("Recogida desde"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Número de pedido", 'ex. 1234', true, LAYOUT_DEFAULT, HANDLER_ORDER_NUMBER, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Nombre", '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Teléfono", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER, null, true),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Dirección", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true),
                fieldJSONCreator(REMEMBER_SECTION, "Recordar información de lugar de recogida", '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Entregar a"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Nombre", 'ex. John Doe', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Teléfono", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, "Email", "ex example@gmail.com", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Dirección", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(DATE_INPUT_FIELD, "Fecha de entrega", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_EXPECTED_DELIVERY_DATE),
                fieldJSONCreator(TEXTAREA_FIELD, "Instrucciones especiales", t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormSpecialJson}
                             emailTemplateFunc={RestaurantEmailTemplateSpecial2}/>
        </>
    )
}

export default OrderFormSpecial3;


class PinDropMap {
    constructor(targetModalDiv){
        this.pinTracker = {};
        this.loadAllFiles();
        this.addModal(targetModalDiv);
        this.pinDropLocationHandler = new PinDropLocationHandler();
        this.currentPinPosition = null;
    }

    loadAllFiles() {
        let fileListString = `<link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A==" crossorigin="" />
        <link rel="stylesheet" href="https://unpkg.com/leaflet-control-geocoder/dist/Control.Geocoder.css" />`;

        document.head.insertAdjacentHTML('beforeend', fileListString);
        this.loadFile("https://unpkg.com/leaflet@1.7.1/dist/leaflet.js");
        this.loadFile("https://unpkg.com/leaflet-control-geocoder/dist/Control.Geocoder.js");
    }

    loadFile(link) {
        const script = document.createElement("script")
        script.src = link;
        script.onload = function(){
            console.log("loaded-"+link);
        }
        document.head.appendChild(script);
    }

    addModal(targetModalDiv) {
        let targetSVG = `<?xml version="1.0" encoding="iso-8859-1"?>
                        <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                             y="0px"
                             viewBox="0 0 495 495" style="enable-background:new 0 0 495 495;" xml:space="preserve">
                        <g>
                        \t<path style="fill:#585858" d="M267.5,393.716v40.293c87.487-9.316,157.193-79.021,166.509-166.509h-40.293
                        \t\tC384.814,332.905,332.905,384.814,267.5,393.716z"/>
                            <path style="fill:#585858" d="M393.716,227.5h40.293C424.693,140.012,354.987,70.307,267.5,60.991v40.293
                        \t\tC332.905,110.186,384.814,162.095,393.716,227.5z"/>
                            <path style="fill:#585858" d="M101.284,267.5H60.991c9.316,87.488,79.021,157.193,166.509,166.509v-40.293
                        \t\tC162.095,384.814,110.186,332.905,101.284,267.5z"/>
                            <path style="fill:#585858" d="M227.5,101.284V60.991C140.013,70.307,70.307,140.012,60.991,227.5h40.293
                        \t\tC110.186,162.095,162.095,110.186,227.5,101.284z"/>
                            <rect x="227.5" y="335.153" style="fill:#666;" width="40" height="159.847"/>
                            <rect x="335.152" y="227.5" style="fill:#666;" width="159.848" height="40"/>
                            <rect x="227.5" style="fill:#666;" width="40" height="159.847"/>
                            <rect y="227.5" style="fill:#666;" width="159.848" height="40"/>
                        </g>
                        </svg>`

        let modalDiv = `<div class="modal fade" id="addressCheckerModal" tabindex="-1" aria-labelledby="addressCheckerModalLabel" aria-hidden="true">
                      <div id="addressPlug-modal-dialog" class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <span class="text-center modal-title container-fluid" id="addressCheckerModalLabel">Update the location</span>
                            <button id="addrPlug-close-btn" type="button" class="btn-close close" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                          </div>
                          <div class="modal-body">
                              <div class="materialUI-form-group form-group">
                                <label>Enter your full address</label>
                                <div id="addressPluginInputBarWrapper" data-bs-title="Enter your full address">
                                    <input id="addressPluginInputBar" class="materialUI-form-control form-control" placeholder="e.g. 890 Geneva Ave, San Francisco, CA 94112, United States" title="Enter your full address">
                                <div/>
                                <div id="addressPluginSuggestions" class="addressPluginSuggestions hide"></div>
                              </div>
                     
                              <div id="addressCheckerMapDiv" class="mt-1" style="height: 400px; width: 100%;">
                              </div>
<!--                              <button id="addrPlug-update-bound" class="btn bg-white visually-hidden addressPluginMapPointerBtn" style="z-index: 401"><i class="bi bi-cursor"></i></button>-->
                              <button id="addrPlug-update" class="btn btn-primary addrPlug-update-btn show">
                                <div id="addrPlug-update-loader" class="spinner-border text-light visually-hidden" role="status">
                                </div>
                                <span>UPDATE</span>
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>`;
        if(targetModalDiv) {
            document.getElementById(targetModalDiv).insertAdjacentHTML('beforeend', modalDiv);
        }
        else document.body.insertAdjacentHTML('beforeend', modalDiv);
    }

    addBtn(targetBtnDiv, updateCallback) {
        let _this = this;
        let modalActivateBtn = document.createElement('button');
        modalActivateBtn.classList = "btn map-toggle-btn";
        modalActivateBtn.setAttribute('data-bs-toggle', 'modal');
        modalActivateBtn.setAttribute('data-bs-target', '#addressCheckerModal');
        modalActivateBtn.setAttribute('data-toggle', 'modal');
        modalActivateBtn.setAttribute('data-target', '#addressCheckerModal');
        modalActivateBtn.title = "Click to Pin point your address on the map";
        modalActivateBtn.onclick = (ev) => {
            ev.preventDefault();
            document.getElementById("addrPlug-update-loader").classList.remove("visually-hidden");
            document.getElementById("addrPlug-update-loader").classList.add("visually-hidden");
            document.getElementById('addrPlug-update').classList.remove("disabled");
            document.getElementById("addressPluginInputBar").value = "";
            setTimeout( () => {
                _this.pinDropLocationHandler.getPermission(targetBtnDiv, _this.showMap.bind(this));
            }, 500);
            document.getElementById('addrPlug-update').onclick = function () {
                document.getElementById('addrPlug-update').classList.add("disabled");
                document.getElementById("addrPlug-update-loader").classList.remove("visually-hidden");
                _this.currentPinPosition = window.addrPlugin_currentPinPosition;
                let address = document.getElementById("addressPluginInputBar").value;
                if(address != "") {
                    updateCallback(_this.currentPinPosition.lat, _this.currentPinPosition.lng, address);
                    _this.pinDropLocationHandler.updatePinPosition(targetBtnDiv, _this.currentPinPosition.lat, _this.currentPinPosition.lng, address);
                    document.getElementById('addrPlug-close-btn').click();
                } else {
                    let tooltip = new window.bootstrap.Tooltip(document.getElementById("addressPluginInputBar"),{trigger:"manual", placement: "bottom"})
                    tooltip.show();
                    document.getElementById("addrPlug-update-loader").classList.remove("visually-hidden");
                    document.getElementById("addrPlug-update-loader").classList.add("visually-hidden");
                    document.getElementById('addrPlug-update').classList.remove("disabled");
                    setTimeout(()=>{
                        tooltip.hide();
                    }, 2000);
                }
            }
            document.getElementById('addrPlug-close-btn').addEventListener('click', function(){
                if(window.addrPlugin_map) {
                    window.addrPlugin_map.off();
                    window.addrPlugin_map.remove();
                    window.addrPlugin_map = null;
                }
            });
        }
        modalActivateBtn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                                        <path d="M6.75 15.8719C4.91775 16.3252 3.75 17.011 3.75 17.7785C3.75 19.1435 7.44365 20.25 12 20.25C16.5563 20.25 20.25 19.1435 20.25 17.7785C20.25 17.011 19.0823 16.3252 17.25 15.8719M13.5 9C13.5 9.82843 12.8284 10.5 12 10.5C11.1716 10.5 10.5 9.82843 10.5 9C10.5 8.17157 11.1716 7.5 12 7.5C12.8284 7.5 13.5 8.17157 13.5 9ZM17.25 8.8125C17.25 12 14.25 15.75 12 17.25C9.75 15.75 6.75 12 6.75 8.8125C6.75 6.01656 9 3.75 12 3.75C15 3.75 17.25 6.01656 17.25 8.8125Z" stroke="black" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>`;
        document.getElementById(targetBtnDiv).insertAdjacentElement('afterend',modalActivateBtn);
        let desiredWidth = document.getElementsByTagName("form")[0].offsetWidth;
        document.getElementById("addressPlug-modal-dialog").style.width = desiredWidth;
        document.getElementById("addressPlug-modal-dialog").style.maxWidth = desiredWidth;
    }

    showMap(lat, lng) {
        let _this = this;
        window.addrPlugin_currentPinPosition = {lat:lat, lng:lng};
        _this.pinDropLocationHandler.getAddressFromLatLng(lat, lng, (address)=> {
            document.getElementById("addressPluginInputBar").value = address;
        })
        if(window.addrPlugin_map) {
            window.addrPlugin_map.off();
            window.addrPlugin_map.remove();
        }
        // if(!document.getElementById("addrPlug-update-bound").classList.contains("visually-hidden")) document.getElementById("addrPlug-update-bound").classList.add("visually-hidden");
        let map = window.L.map('addressCheckerMapDiv', {zoomControl: false});
        window.addrPlugin_map = map;
        map.setView([lat, lng], 18);
        window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

        let geoCoder = window.L.Control.geocoder({
            defaultMarkGeocode: false
        })

        let marker = window.L.marker([lat, lng], {draggable:true, riseOnHover: true, autoPan: true}).addTo(map)
            .bindPopup("Drag the marker to change the location")
            .openPopup();

        marker.on("dragstart", (ev) => {
            let classList = document.getElementById("addrPlug-update").classList;
            classList.remove("show");
            classList.add("hide");
        })

        marker.on("dragend", (ev) => {
            map.flyTo(ev.target._latlng, 18, {animate:true});
            marker.bindPopup("Is this your exact location ?").openPopup();
            let classList = document.getElementById("addrPlug-update").classList;
            classList.remove("hide");
            classList.add("show");
            window.addrPlugin_currentPinPosition = ev.target._latlng;
            _this.pinDropLocationHandler.getAddressFromLatLng(ev.target._latlng.lat, ev.target._latlng.lng, (address)=> {
                document.getElementById("addressPluginInputBar").value = address;
            })
        });

        // map.on("dragstart", function (){
        //     document.getElementById("addrPlug-update-bound").classList.remove("visually-hidden");
        //     document.getElementById("addrPlug-update-bound").classList.add("visually-hidden");
        // })

        // map.on("dragend", function() {
        //     document.getElementById("addrPlug-update-bound").classList.remove("visually-hidden");
        // })

        // document.getElementById("addrPlug-update-bound").onclick = function() {
        //     if(window.addrPlugin_currentPinPosition) map.flyTo(window.addrPlugin_currentPinPosition, 18, {animate: true});
        //     document.getElementById("addrPlug-update-bound").classList.remove("visually-hidden");
        //     document.getElementById("addrPlug-update-bound").classList.add("visually-hidden");
        // }

        window.L.control.zoom({
            position: 'bottomright'
        }).addTo(map);

        //let addressInputTimer = null;

        // document.getElementById("addressPluginInputBar").onkeyup = function(ev) {
        //     clearTimeout(addressInputTimer);
        //     addressInputTimer = setTimeout(() => {
        //         _this.pinDropLocationHandler.findLatLngFromAddress(ev.target.value, (lat, lng) => {
        //             map.setView([lat, lng], 18);
        //             marker.setLatLng([lat, lng]);
        //         })
        //     }, 300);
        // }
    }

    updateData(data, btnDivId, dataType) {
        let latLng = this.pinDropLocationHandler.pinTracker[btnDivId];
        if(dataType === "customer") {
            data.deliveryLatitude = latLng.lat;
            data.deliveryLongitude = latLng.lng;
        } else {
            data.pickupLatitude = latLng.lat;
            data.pickupLongitude = latLng.lng;
        }
        return data;
    }
}

class PinDropLocationHandler {
    constructor(country) {
        this.findCountryDefaultPin(country);
        this.defaultCountryPin = null;
        this.pinTracker = {};
    }

    updatePinPosition(targetDiv, lat, lng, addr) {
        this.pinTracker[targetDiv] = {lat, lng, addr};
    }

    findCountryDefaultPin(country) {
        let _this = this;
        let xmlhttp = new XMLHttpRequest();
        let url = "https://nominatim.openstreetmap.org/search?format=json&limit=3&q=" + country;
        xmlhttp.onreadystatechange = function()
        {
            if (this.readyState == 4 && this.status == 200)
            {
                let addrDetailsArr = JSON.parse(this.responseText);
                addrDetailsArr.map((addr)=>{
                    if(addr.type === "administrative" && _this.defaultCountryPin == null) {
                        _this.defaultCountryPin = {lat: addr.lat, lng: addr.lon};
                    }
                })
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }

    findLatLngFromAddress(address, callback) {
        let _this = this;
        let xmlhttp = new XMLHttpRequest();
        let url = "https://nominatim.openstreetmap.org/search?format=json&limit=3&q=" + address;
        xmlhttp.onreadystatechange = function()
        {
            if (this.readyState == 4 && this.status == 200)
            {
                document.getElementById("addressPluginSuggestions").innerHTML = "";
                let addrDetailsArr = JSON.parse(this.responseText);
                if(addrDetailsArr.length != 0) {
                    addrDetailsArr.map(addr => {
                        document.getElementById("addressPluginSuggestions").classList.remove("show");
                        document.getElementById("addressPluginSuggestions").classList.remove("hide");
                        document.getElementById("addressPluginSuggestions").classList.add("show");
                        let suggestionList = document.createElement('div');
                        suggestionList.innerText = addr.display_name;
                        suggestionList.classList = "addressPluginSuggestionsChild";
                        suggestionList.onclick = function () {
                            callback(addr.lat, addr.lon);
                            document.getElementById("addressPluginSuggestions").innerHTML = "";
                            document.getElementById("addressPluginSuggestions").classList.remove("hide");
                            document.getElementById("addressPluginSuggestions").classList.remove("show");
                            document.getElementById("addressPluginSuggestions").classList.add("hide");
                            document.getElementById("addressPluginInputBar").value = addr.display_name;
                        }
                        document.getElementById("addressPluginSuggestions").insertAdjacentElement('beforeend',suggestionList);
                    })
                } else {
                    document.getElementById("addressPluginSuggestions").classList.remove("hide");
                    document.getElementById("addressPluginSuggestions").classList.remove("show");
                    document.getElementById("addressPluginSuggestions").classList.add("hide");
                }
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }

    getPermission(btnDiv, callBack) {
        let _this = this;
        if(_this.pinTracker[btnDiv]) {
            document.getElementById("addressPluginInputBar").value = _this.pinTracker[btnDiv].addr;
            callBack(_this.pinTracker[btnDiv].lat, _this.pinTracker[btnDiv].lng);
            return;
        }

        try{
            navigator.permissions.query({name: 'geolocation'}).then(
                res => {
                    if(res.state === 'granted' || 'prompt') {
                        _this.retrievePosition(callBack)
                    }
                    else {
                        _this.getDefaultPin(callBack);
                    }
                });
        }
        catch(e) {
            _this.retrievePosition(callBack)
        }
    }

    retrievePosition(callback) {
        let _this = this;
        navigator.geolocation.getCurrentPosition(
            pos => {
                let lat = pos.coords.latitude;
                let lng = pos.coords.longitude;
                callback(lat, lng);
            },
            err => {
                console.error(err);
                _this.getDefaultPin(callback);
            }
        )
    }

    getDefaultPin(callBack) {
        callBack(this.defaultCountryPin.lat, this.defaultCountryPin.lng);
    }

    getAddressFromLatLng(lat, lng, callBack) {
        let xmlhttp = new XMLHttpRequest();
        let url = "https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=" + lat + "&lon=" +lng;
        xmlhttp.onreadystatechange = function()
        {
            if (this.readyState == 4 && this.status == 200)
            {
                let addrDetails = JSON.parse(this.responseText);
                let shortNames = addrDetails.display_name.split(',');
                let shortName = shortNames[0] + ',' + shortNames[1] + ',' + shortNames[2];
                callBack(shortName);
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }
}

export default PinDropMap;
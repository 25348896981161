import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import Card from "../common/paidComponents/card";


const SimpleForm = () => {
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <div id="mainDiv" className="paidForm pb-5 w-100">
                <div className="col-sm-6 offset-sm-3">
                    <Card>
                        <div className="fs-5 text-center">Order Form Not Found</div>
                        <div className="text-center">Please verify the link.</div>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default SimpleForm;
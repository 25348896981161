import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    getItemJson,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    REMEMBER_SECTION,
    sectionTitleJSONCreator,
    TEXT_INPUT_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {HANDLER_ORDER_NUMBER} from "../../reducers/orderNumberReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {HANDLER_TOTAL_ORDER_COST} from "../../reducers/totalOrderCostReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_REMEMBER_INFO} from "../../reducers/rememberInfoReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {HANDLER_DELIVERY_FEE} from "../../reducers/deliveryFeeReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import {OrderEmailTemplate4} from "../../util/emailTemplates/orderEmailTemplate4";
import {ADDRESS_INPUT_WITH_W3W} from "../common/components/addressInputWithW3W";
import { HANDLER_CUSTOMER_EMAIL } from "../../reducers/customerEmailReducer";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";
import { useDeliveryCompanyInfos } from "../customHooks/deliveryComapnyInfosHook";

const OrderForm4 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [pickupName, pickupAddress, pickupPhone] = useDeliveryCompanyInfos();

    const orderForm4Json = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_FORM)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.ORDER_NUMBER), 'ex. 1234', true, LAYOUT_DEFAULT, HANDLER_ORDER_NUMBER, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME,  null, true, {initialValue: pickupName}),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER, null, true, {initialValue: pickupPhone}),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true, {initialValue: pickupAddress}),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_EXPECTED_PICKUP_TIME),
                fieldJSONCreator(REMEMBER_SECTION, t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO), '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), 'ex. John Doe', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'example@gmail.com', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(VALUE_INPUT_FIELD, t(FORM_LOCALE_KEYS.ORDER_PRICE), '00', true, LAYOUT_DEFAULT, HANDLER_TOTAL_ORDER_COST),
                fieldJSONCreator(VALUE_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_FEE), '00', true, LAYOUT_DEFAULT, HANDLER_DELIVERY_FEE),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_TIME), '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_EXPECTED_DELIVERY_TIME),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]
        },
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, []);

    const preProcessFunc = (data) => {
        const unitPrice = data["totalOrderCost"] - data["deliveryFee"];
        data["orderItem"] = getItemJson("item", 1, undefined, unitPrice, "");
        return data;
    }

    const template = onlyDispatcherMail(OrderEmailTemplate4)

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderForm4Json} emailTemplateFunc={template} preProcessFunc={preProcessFunc}/>
        </>
    )
}

export default OrderForm4;
export const HANDLER_NUMBER_OF_EXTRA_ITEMS = "HANDLER_NUMBER_OF_EXTRA_ITEMS";

export default (state = 0, action) => {
    switch (action.type) {
        case HANDLER_NUMBER_OF_EXTRA_ITEMS:
            return action.payload;
        default:
            return state;
    }
}

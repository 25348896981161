import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import FieldLabel from "./fieldLabel";
import {getFromCache} from "../../../util/localCache";

const EmailInput = ({config}) => {
    const dispatch = useDispatch();
    const emailRef = useRef(null);

    useEffect(() => {
        if (config.prepopulate) {
            updateFromCache();
            document.getElementById("externalOrderForm").addEventListener("reset", updateFromCacheAfterSomeTime)
        }
    }, [config])

    if (typeof config === "undefined") {
        return null;
    }

    const updateFromCache = () => {
        emailRef.current.value = getFromCache(config.handlerType) || "";
        dispatch({type: config.handlerType, payload: emailRef.current.value});
    }

    const updateFromCacheAfterSomeTime = () => {
        setTimeout(()=>{
            updateFromCache();
        }, 500)
    }

    const inputChanged = (ev) => {
        dispatch({type: config.handlerType, payload: ev.target.value});
    }

    return (
        <div>
            <FieldLabel config={config}/>
            <input ref={emailRef} type="email" className="form-control" aria-describedby="email"
                   required={config.required || false}
                   placeholder={config.placeholder} onChange={inputChanged}/>
        </div>
    )
}

export default EmailInput;

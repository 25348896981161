import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import { PAID_FORM_ERROR_CLEAR } from "../../../reducers/paidFormErrorReducer";

export default function PaidFormError() {
    const paidFormError = useSelector(state => state.paidFormError);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        if(paidFormError.show) {
            const timer1 = setTimeout(() => {
                dispatch({type: PAID_FORM_ERROR_CLEAR});
            }, 10000);
            const timer2 = setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 500)

            return () => {
                clearTimeout(timer1);
                clearTimeout(timer2);
            };
        }
    }, [paidFormError.show])
    
    return <div>
        {paidFormError.show && <div className={`alert alert-danger text-center`}>
                <strong>{t(FORM_LOCALE_KEYS.SORRY)}</strong>
                <br />
                <span>{paidFormError.message}</span>
        </div>}
    </div>;
}

export const HANDLER_TAX = "HANDLER_TAX";

export default (state = 0, action) => {
    switch (action.type) {
        case HANDLER_TAX:
            return action.payload;
        default:
            return state;
    }
}

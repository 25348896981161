import React, {useState} from "react";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {useSelector} from "react-redux";
import {HANDLER_RESTAURANT_EMAIL} from "../../../reducers/restaurantEmailReducer";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import AddressInput from "../../common/components/addressInput";
import TimeInput from "../../common/components/timeInput";
import EmailInput from "../../common/components/emailInput";
import DateInput from "../../common/components/dateInput";
import TextAreaInput from "../../common/components/textAreaInput";


const OrderDetailsInputBuilder = ({configJSON, loading}) => {
    const {t} = useTranslation();
    const companyInfo = useSelector(state => state.companyDetails);
    const deliveryOnly = companyInfo?.isDeliveryOnly();
    const deliveryAddress = companyInfo?.getDeliveryOnlyAddress();
    const deliveryPhone = companyInfo?.getDeliveryOnlyPhone();
    const companyName = companyInfo?.getCompanyName();
    return (
        <>
            <div>
                 <Card>
                    <CardTitle title={"Abholung von"}/>
                    <NameInput deliveryOnly={deliveryOnly} info={companyName} config={{handlerType: HANDLER_RESTAURANT_NAME, required: true, placeholder: "Vollständiger Name der Person/Abholstelle"}}/>
                    <NameInput deliveryOnly={deliveryOnly} info={deliveryPhone} config={{handlerType: HANDLER_RESTAURANT_PHONE_NUMBER, required: true, placeholder: "Telefonnummer der Person/Abholstelle"}}/>
                    <AddressInput deliveryOnly={deliveryOnly} info={deliveryAddress} config={{handlerType: HANDLER_RESTAURANT_ADDRESS, required: true, placeholder: "Anschrift der Person/Abholstelle"}}/>
                    <TimeInput config={{handlerType: HANDLER_EXPECTED_PICKUP_TIME, required: true, placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME)}}/>
                 </Card>
                <Card>
                    <CardTitle title={"Liefern an"}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_NAME, required: true, placeholder: "Vollständiger Name des Empfängers/der Abholstelle"}}/>
                    <NameInput config={{handlerType: HANDLER_CUSTOMER_PHONE_NUMBER, required: false, placeholder: "Telefonnummer des Empfängers/der Abholstelle (optional)"}}/>
                    <EmailInput config={{handlerType: HANDLER_CUSTOMER_EMAIL, required:true, placeholder: "Meine E-Mail Adresse (Pflichtfeld)"}}/>
                    <AddressInput config={{handlerType: HANDLER_CUSTOMER_ADDRESS, required: true, placeholder: "Empfängeradresse/Lieferadresse"}}/>
                    <div>
                        <div className="w-100">
                            <label className="form-label"></label>
                            <DateInput config={{handlerType: HANDLER_EXPECTED_DELIVERY_DATE, required: true, placeholder:t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE)}}/>
                        </div>
                    </div>
                    <TimeInput config={{handlerType: HANDLER_EXPECTED_DELIVERY_TIME, required: true, placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME)}}/>
                    <TextAreaInput config={{handlerType: HANDLER_DELIVERY_INSTRUCTION, required: true, placeholder: "Notizen für Deinen Kurier"}}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && t(FORM_LOCALE_KEYS.CHECKOUT)}
                </button>
            </div>
        </>
    )
}

export default OrderDetailsInputBuilder;

export const HANDLER_ORDER_ITEMS = "HANDLER_ORDER_ITEMS";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_ORDER_ITEMS:
            return action.payload;
        default:
            return state;
    }
}

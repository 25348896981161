import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {ORDER_SUBMIT_STATE} from "../../../reducers/orderSubmitStateReducer";
import {ORDER_SUBMIT_STATE_FAILED, ORDER_SUBMIT_STATE_SUCCESS} from "../../../actions/orderSubmitAction";

const OrderAlerts = () => {
    const orderSubmitState = useSelector(state => state.orderSubmitState);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [showAlert, setShowAlert] = useState(false);
    const [state, setState] = useState(null);
    let msgTitle = t(FORM_LOCALE_KEYS.SUCCESS);
    let msgDet = t(FORM_LOCALE_KEYS.THANKS_MSG);
    if (orderSubmitState === ORDER_SUBMIT_STATE_FAILED) {
        msgTitle = t(FORM_LOCALE_KEYS.SORRY);
        msgDet = "Order could not be placed."
    }

    useEffect(() => {
        if (orderSubmitState === ORDER_SUBMIT_STATE_SUCCESS || orderSubmitState?.STATE === ORDER_SUBMIT_STATE_FAILED) {
            setState(orderSubmitState);
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false)
            }, 10000);
            dispatch({type: ORDER_SUBMIT_STATE, payload: null});
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight)
            }, 500);
        }
    }, [orderSubmitState]);

    return (
        <div>
            {showAlert && <div className={`alert alert-${state === ORDER_SUBMIT_STATE_SUCCESS ? "success": "danger"} text-center`}>
                <strong>{state == ORDER_SUBMIT_STATE_SUCCESS ? t(FORM_LOCALE_KEYS.SUCCESS): t(FORM_LOCALE_KEYS.SORRY)}</strong>
                <span> {state == ORDER_SUBMIT_STATE_SUCCESS ? t(FORM_LOCALE_KEYS.THANKS_MSG) : state?.errMsg}</span>
            </div>}
        </div>
    )
}

export default OrderAlerts;
export const CourierEmailTemplateShort = (data) => {
    if (data.expectedDeliveryDate === data.submitDate) data.expectedDeliveryTime = "ASAP";
    let mailBody = `<div style=" font: Arial,Helvetica,sans-serif;font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;font-size:14px;"> 
        <div></p> 
        <P><b>Order No:</b> ${data.orderNumber}</P> 
        <P><b>Date:</b> ${data.submitDate}</P> 
        <P><b>Order Placement Time:</b> ${data.submitTime}</P> 
        </div> 
        <hr> 
        <div> 
        <table width="100%">
        <tr> 
        <td width="50%"> 
        <div> 
        <h5>Deliver to:</h5> 
        <p>Name: ${data.customerName}</p> 
        <p>Address: ${data.customerAddress}</p> 
        <p>Phone: ${data.customerPhoneNumber}</p> 
        <p>Email: ${data.customerEmail}</p> 
        <p>Delivery date: ${data.submitDate}</p> 
        <p>Delivery time: ${data.expectedDeliveryTime}</p> 
        </div>  
        </td> 
        <td width="50%"> 
        <div> 
        <h5>Pick up from:</h5> 
        <p>Name: ${data.restaurantName}</p> 
        <p>Address: ${data.restaurantAddress}</p> 
        <p>Pick up date: ${data.submitDate}</p> 
        <p>Pick up time: ${data.expectedPickupTime}</p> 
        <p>&nbsp;</p> 
        <p>&nbsp;</p> 
        </div>  
        </td> 
        </tr> 
        </table> 
        </div> 
        <hr> 
        <div> 
        <h5>Order Details:</h5> 
        <p style="width:300px; text-align: justify;text-justify: inter-word;">Delivery Details: ${data.deliveryInstruction}</p> 
        <p>Number of items: ${data.numberOfItems}</p> 
        </div> 
        <hr> 
        </div>`;

    let customerMailFooter = `<div> 
        <p>Sincerely,</p> 
        <p><b>${data.companyName}</b></p> 
        </div>`;

    let customerMailHeader =`<table width="100%" style="margin:0 !important;padding:0 !important; height:50px !important;"> 
        <tr> 
        <td width="5%"> 
        <div style="margin-top:10px;"> 
        <img src="${data.companyLogo}" width="50"> 
        </div>  
        </td> 
        <td width="95%"> 
        <div> 
        <span style="font-size:40px;font-weight:600;">${data.companyName}</span> 
        </div>  
        </td> 
        </tr> 
        </table> 
        <p>Thank you for your order. We are on it!</p>`;

    let dispatcherMailHeader = `<table width="100%" style="margin:0 !important;padding:0 !important; height:50px !important;">
        <tr>
        <td width="5%">
        <div style="margin-top:10px;">
        <img src="${data.companyLogo}" width="50">
        </div>
        </td>
        <td width="95%">
        <div>
        <span style="font-size:40px;font-weight:600;">${data.companyName}</span>
        </div>
        </td>
        </tr>
        </table>
        <p>You have a new order from ${data.customerName}.</p>`;

    let dispatcherEmailBody = dispatcherMailHeader + mailBody;
    let mailData = {
        to: data.companyEmail,
        subject: "New Order",
        body: dispatcherEmailBody
    };
    let customerEmailBody = customerMailHeader + mailBody + customerMailFooter;
    let mailData2 = {
        to: data.customerEmail,
        subject: "Order Confirmation",
        body: customerEmailBody
    };

    return [mailData, mailData2];
}
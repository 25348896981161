import React from "react";
import useStateMapper from "../../customHooks/stateMapperHook";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";


export const CustomOrderDetails = () => {
    const [stateMapper] = useStateMapper();
    const deliveryDetails = stateMapper(HANDLER_DELIVERY_INSTRUCTION);
    const tips = stateMapper(HANDLER_TIPS);
    const size = stateMapper(HANDLER_ITEM_SIZE);
    const type = stateMapper(HANDLER_ITEM_TYPE);
    const service = stateMapper(HANDLER_SERVICE_TYPE);
    const numOfItems = stateMapper(HANDLER_NUMBER_OF_ITEMS);
    const estimatedInfo = useSelector(state => state.estimatedInfo);
    const companyDetails = useSelector(state => state.companyDetails);
    const {t} = useTranslation();

    const getUnit = () => {
        switch (estimatedInfo.getDistanceUnit()) {
            case "mile":
                return "Mile(s)";
            case "km":
                return "KM"
            default:
                return "Mile(s)";
        }
    }

    if(estimatedInfo === null) return <></>;

    return (
        <>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>{t(FORM_LOCALE_KEYS.DISTANCE)}:</span>
                <div>
                    {estimatedInfo.distance} {getUnit()}
                </div>
            </div>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>Vehicle:</span>
                <div>
                    {type}
                </div>
            </div>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>Problem:</span>
                <div>
                    {deliveryDetails}
                </div>
            </div>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>Service Type:</span>
                <div>
                    {service}
                </div>
            </div>
            <hr className="hr-line"/>

            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>{t(FORM_LOCALE_KEYS.DELIVERY_FEE)}</span>
                <span>{companyDetails.currency}{Number(estimatedInfo.getChargeAmountInDollar() - tips).toFixed(2)}</span>
            </div>
            <div className="d-flex flex-direction-row justify-content-between secondary-text">
                <span>{t(FORM_LOCALE_KEYS.DELIVERY_TIPS)}</span>
                <span>{companyDetails.currency}{Number(tips).toFixed(2)}</span>
            </div>
            <div className="d-flex flex-direction-row justify-content-between secondary-text fs-6">
                <span className="fw-bold">{t(FORM_LOCALE_KEYS.TOTAL)}</span>
                <span className="fw-bold">{companyDetails.currency}{estimatedInfo.getChargeAmountInDollar()}</span>
            </div>
        </>
    )
}

export default CustomOrderDetails;

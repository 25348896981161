import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    DATE_INPUT_FIELD,
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE, LAYOUT_TANGLE_WITH_NEXT,
    REMEMBER_SECTION,
    sectionTitleJSONCreator, SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {HANDLER_ORDER_NUMBER} from "../../reducers/orderNumberReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {HANDLER_TOTAL_ORDER_COST} from "../../reducers/totalOrderCostReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_REMEMBER_INFO} from "../../reducers/rememberInfoReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {HANDLER_DELIVERY_FEE} from "../../reducers/deliveryFeeReducer";
import {HANDLER_CUSTOMER_LAST_NAME} from "../../reducers/customerLastNameReducer";
import {HANDLER_PICKUP_COMPANY_NAME} from "../../reducers/pickUpCompanyNameReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";
import {HANDLER_EXPECTED_PICKUP_DATE} from "../../reducers/expectedPickUpDateReducer";
import {HANDLER_RESTAURANT_LAST_NAME} from "../../reducers/restaurantLastNameReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_ORDER_ITEMS} from "../../reducers/orderItemsReducer";
import {HANDLER_ITEM_TYPE} from "../../reducers/itemTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../reducers/itemSizeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../../reducers/numberOfItemsReducer";
import {HANDLER_SERVICE_TYPE} from "../../reducers/serviceTypeReducer";
import {courierEmailTemplate1} from "../../util/emailTemplates/courierEmailTemplate1";
import {ADDRESS_INPUT_WITH_W3W} from "../common/components/addressInputWithW3W";
import {HANDLER_PAYMENT_METHOD} from "../../reducers/paymentMethodReducer";

const OrderForm14 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "ex. John Doe", true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(DATE_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_DATE), '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_EXPECTED_PICKUP_DATE),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_TIME),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.PICK_UP_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVERY_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "ex. John Doe", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.RECIPIENT_PHONE_NUMBER), '(000) 000-00-00', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(DATE_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_DATE), '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_EXPECTED_DELIVERY_DATE),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_DELIVERY_TIME),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.ITEM_AND_SERVICE_TYPE)),
            sectionFields: [
                fieldJSONCreator(SELECTOR_INPUT_FIELD, t(FORM_LOCALE_KEYS.SERVICE_TYPE), '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_SERVICE_TYPE, [
                    {label: "Express Delivery", value: "Express Delivery"},
                    {label: "Urgent Delivery", value: "Urgent Delivery"},
                    {label: "Bulk Delivery", value: "Bulk Delivery"}
                ]),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, t(FORM_LOCALE_KEYS.ITEM_TYPE), '', true, LAYOUT_DEFAULT, HANDLER_ITEM_TYPE,
                    [
                        {label: t(FORM_LOCALE_KEYS.PARCELS), value: t(FORM_LOCALE_KEYS.PARCELS)},
                        {label: "Box", value: "Box"},
                        {label: t(FORM_LOCALE_KEYS.DOCUMENTS), value: t(FORM_LOCALE_KEYS.DOCUMENTS)},
                        {label: "Food", value: "Food"},
                        {label: t(FORM_LOCALE_KEYS.OTHER_ITEMS), value: t(FORM_LOCALE_KEYS.OTHER_ITEMS)}]),
                fieldJSONCreator(VALUE_INPUT_FIELD, t(FORM_LOCALE_KEYS.NUM_OF_ITEMS), '00', true, LAYOUT_DEFAULT, HANDLER_NUMBER_OF_ITEMS),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Fragile", '', true, LAYOUT_DEFAULT, HANDLER_ITEM_SIZE,
                    [
                        {label: "Fragile Items", value: "Fragile Items"},
                        {label: "Non-Fragile Items", value: "Non-Fragile Items"},
                    ]),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_INSTRUCTIONS), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Payment Method", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_PAYMENT_METHOD, [
                        {label: "Cash", value: "cash"},
                        {label: "Credit Card", value: "credit_card"}
                ])
            ]
        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={() => {}}/>
        </>
    )
}

export default OrderForm14;

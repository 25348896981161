import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    DATE_INPUT_FIELD,
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    LAYOUT_TANGLE_WITH_NEXT,
    sectionTitleJSONCreator,
    SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {HANDLER_PICKUP_COMPANY_NAME} from "../../reducers/pickUpCompanyNameReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";
import {HANDLER_EXPECTED_PICKUP_DATE} from "../../reducers/expectedPickUpDateReducer";
import {HANDLER_RESTAURANT_LAST_NAME} from "../../reducers/restaurantLastNameReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_ITEM_TYPE} from "../../reducers/itemTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../reducers/itemSizeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../../reducers/numberOfItemsReducer";
import {HANDLER_SERVICE_TYPE} from "../../reducers/serviceTypeReducer";
import {courierEmailTemplate1} from "../../util/emailTemplates/courierEmailTemplate1";
import {ADDRESS_INPUT_WITH_W3W} from "../common/components/addressInputWithW3W";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_ORDER_NUMBER} from "../../reducers/orderNumberReducer";
import {HANDLER_DELIVERY_FEE} from "../../reducers/deliveryFeeReducer";

const CourierForm11 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch({
            type: HANDLER_SERVICE_TYPE,
            payload: ""
        });
    },[])


    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.CUSTOMER_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Booking reference", 'ex. 1234', false, LAYOUT_HALF_SPACED_SINGLE, HANDLER_ORDER_NUMBER, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Name", "John Doe", true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'example@gmail.com', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Flight Number", "ex. 242272", false, LAYOUT_DEFAULT, HANDLER_SERVICE_TYPE)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.PICK_UP_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
                fieldJSONCreator(DATE_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_DATE), '', true, LAYOUT_TANGLE_WITH_NEXT, HANDLER_EXPECTED_PICKUP_DATE),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_TIME)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVERY_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.DELIVERY_PERSON_OR_COMPANY_NAME), "ex. John Doe", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.RECIPIENT_PHONE_NUMBER), '(000) 000-00-00', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Booking type"),
            sectionFields: [
                fieldJSONCreator(SELECTOR_INPUT_FIELD, t(FORM_LOCALE_KEYS.ITEM_TYPE), '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_ITEM_TYPE,
                    [{
                        label: "Airport pick-up",
                        value: "Service: Airport pick-up",
                        selected: true,
                    }, {
                        label: "Airport drop-off",
                        value: "Service: Airport drop-off",
                    }]),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Booking vehicle", '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_PICKUP_COMPANY_NAME, [
                    {label: "Sedan", value: "Sedan", selected: true},
                    {label: "MPV", value: "MPV"},
                    {label: "MINIVAN 8 SEATER", value: "MINIVAN 8 SEATER"},
                    {label: "COACH 12 SEATER", value: "COACH 12 SEATER"}
                ]),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Number of bags", "00", true, LAYOUT_DEFAULT, HANDLER_ITEM_SIZE, undefined, false, undefined, "Bag Count: "),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Number of passengers", '00', true, LAYOUT_DEFAULT, HANDLER_NUMBER_OF_ITEMS),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Delivery fee", '00', true, LAYOUT_DEFAULT, HANDLER_DELIVERY_FEE),
                fieldJSONCreator(TEXTAREA_FIELD, "Free waiting time & terminal number", "ex 12 min, Terminal: 1", false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]

        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={courierEmailTemplate1}/>
        </>
    )
}

export default CourierForm11;

import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Selector from "../../common/components/selector";
import {HANDLER_ITEM_TYPE} from "../../../reducers/itemTypeReducer";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import {HANDLER_RESTAURANT_NAME} from "../../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../../reducers/restaurantAddressReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../../reducers/expectedPickupTimeReducer";
import {HANDLER_RESTAURANT_CONTACT_NAME} from "../../../reducers/restaurantContactNameReducer";
import {HANDLER_RESTAURANT_EMAIL} from "../../../reducers/restaurantEmailReducer";
import {HANDLER_PICKUP_COMPANY_NAME} from "../../../reducers/pickUpCompanyNameReducer";
import Card from "../../common/paidComponents/card";
import CardTitle from "../../common/paidComponents/cardTitle";
import NameInput from "../../common/components/nameInput";
import AddressInput from "../../common/components/addressInput";
import TimeInput from "../../common/components/timeInput";
import {HANDLER_CUSTOMER_NAME} from "../../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../../reducers/customerPhoneNumberReducer";
import EmailInput from "../../common/components/emailInput";
import {HANDLER_CUSTOMER_EMAIL} from "../../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../../reducers/customerAddressReducer";
import DateInput from "../../common/components/dateInput";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../../reducers/deliveryInstructionReducer";
import {HANDLER_TIPS} from "../../../reducers/tipsReducer";
import ValueInput from "../../common/components/valueInput";
import {HANDLER_SERVICE_TYPE} from "../../../reducers/serviceTypeReducer";
import {HANDLER_ITEM_SIZE} from "../../../reducers/itemSizeReducer";
import {useDispatch} from "react-redux";
import {HANDLER_NUMBER_OF_ITEMS} from "../../../reducers/numberOfItemsReducer";
import RememberInfo from "../../common/components/rememberInfo";
import {HANDLER_REMEMBER_INFO} from "../../../reducers/rememberInfoReducer";
import TextAreaInput from "../../common/components/textAreaInput";


const CustomInputBuilder = ({configJSON, loading}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: HANDLER_SERVICE_TYPE, payload: "Carro"});
    }, [])
    return (
        <>
            <div>
                <Card>
                    <CardTitle title={"Sender Info"}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_NAME,
                        required: true,
                        placeholder: "Company Name",
                        prepopulate: true
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_CONTACT_NAME,
                        required: true,
                        placeholder: "Contact Name",
                        prepopulate: true
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_RESTAURANT_PHONE_NUMBER,
                        required: true,
                        placeholder: "Company Phone/Cell",
                        prepopulate: true
                    }}/>
                    <EmailInput config={{
                        handlerType: HANDLER_RESTAURANT_EMAIL,
                        required: true,
                        placeholder: "Company Email"
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_RESTAURANT_ADDRESS,
                        required: true,
                        placeholder: "Pick up Address",
                        prepopulate: true
                    }}/>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_PICKUP_TIME,
                        required: true,
                        placeholder: "Pick up Ready Time"
                    }}/>
                    <RememberInfo config={{
                        handlerType: HANDLER_REMEMBER_INFO,
                        required: false,
                        label: t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO)
                    }}/>
                </Card>
                <Card>
                    <CardTitle title={"Recipient Info"}/>
                    <NameInput config={{
                        handlerType: HANDLER_PICKUP_COMPANY_NAME,
                        required: false,
                        placeholder: "Company Name",
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_NAME,
                        required: true,
                        placeholder: "Contact Name"
                    }}/>
                    <NameInput config={{
                        handlerType: HANDLER_CUSTOMER_PHONE_NUMBER,
                        required: true,
                        placeholder: "Recipient Cell"
                    }}/>
                    <EmailInput config={{
                        handlerType: HANDLER_CUSTOMER_EMAIL,
                        required: true,
                        placeholder: "Recipient email"
                    }}/>
                    <AddressInput config={{
                        handlerType: HANDLER_CUSTOMER_ADDRESS,
                        required: true,
                        placeholder: "Recipient Address"
                    }}/>
                    <div>
                        <div className="w-100">
                            <label className="form-label"></label>
                            <DateInput config={{
                                handlerType: HANDLER_EXPECTED_DELIVERY_DATE,
                                required: true,
                                placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE)
                            }}/>
                        </div>
                    </div>
                    <TimeInput config={{
                        handlerType: HANDLER_EXPECTED_DELIVERY_TIME,
                        required: true,
                        placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME)
                    }}/>
                </Card>
            </div>
            <div className="text-center my-3 mx-4">
                <button id="estimateBtn" type="submit" className="btn btnPay btnEstimate">
                    {loading && <div className="spinner-border" role="status"/>}
                    {!loading && t(FORM_LOCALE_KEYS.CHECKOUT)}
                </button>
            </div>
        </>
    )
}

export default CustomInputBuilder;

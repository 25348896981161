import React from "react";
import {
    DATE_INPUT_FIELD,
    EMAIL_INPUT_FIELD,
    LAYOUT_HALF_SPACED_SINGLE,
    LAYOUT_TANGLE_WITH_NEXT,
    REMEMBER_SECTION,
    SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD,
    TIME_24H_INPUT_FIELD,
    TIME_INPUT_FIELD,
    VALUE_INPUT_FIELD
} from "../../../util/formBuilderHelper";
import NameInput from "../components/nameInput";
import EmailInput from "../components/emailInput";
import ValueInput from "../components/valueInput";
import RememberInfo from "../components/rememberInfo";
import Selector from "../components/selector";
import HalfSpaceSingleInputGrid from "./halfSpaceSingleInputGrid";
import DoubleInputGrid from "./doubleInputGrid";
import AddressInput from "../components/addressInput";
import TimeInput from "../components/timeInput";
import DateInput from "../components/dateInput";
import TextAreaInput from "../components/textAreaInput";
import AddressInputWithW3W, {ADDRESS_INPUT_WITH_W3W} from "../components/addressInputWithW3W";
import AdditionalInfo, {ADDITIONAL_INFO_DIV} from "../components/additionalInfo";
import PaymentType, {PAYMENT_METHOD_FIELD} from "../components/paymentType";
import DynamicExpectedDateTime from "../components/dynamicExpectedDateTime";
import Time24hInput from "../components/time24hInput";

const SectionWrapper = ({sectionFieldsJSON}) => {

    const getSectionFields = (sectionFieldsJSON) => {
        let prevComponent = null;
        return sectionFieldsJSON.map((fieldConfig) => {
            let field = null;
            switch (fieldConfig.layout) {
                case "HIDDEN":
                    field = <DynamicExpectedDateTime timeDifference={30}/>
                    break;

                case LAYOUT_HALF_SPACED_SINGLE:
                    field = <HalfSpaceSingleInputGrid targetComponent={getFormField(fieldConfig)}/>
                    break;

                case LAYOUT_TANGLE_WITH_NEXT:
                    prevComponent = getFormField(fieldConfig);
                    break;

                default:
                    if (prevComponent) {
                        field = <DoubleInputGrid firstComponent={prevComponent}
                                                 secondComponent={getFormField(fieldConfig)}/>
                        prevComponent = null;
                    } else field = getFormField(fieldConfig);
                    break;
            }

            return field;
        });
    }

    const getFormField = (fieldConfig) => {
        let field = null;
        switch (fieldConfig.fieldType) {
            case TEXT_INPUT_FIELD:
                field = <NameInput config={fieldConfig}/>
                break;

            case EMAIL_INPUT_FIELD:
                field = <EmailInput config={fieldConfig}/>;
                break;

            case TEXTAREA_ADDRESS_FIELD:
                field = <AddressInput config={fieldConfig}/>;
                break;

            case ADDRESS_INPUT_WITH_W3W:
                field = <AddressInputWithW3W config={fieldConfig}/>;
                break;

            case TEXTAREA_FIELD:
                field = <TextAreaInput config={fieldConfig}/>;
                break;

            case DATE_INPUT_FIELD:
                field = <DateInput config={fieldConfig}/>;
                break;

            case TIME_INPUT_FIELD:
                field = <TimeInput config={fieldConfig}/>;
                break;

            case TIME_24H_INPUT_FIELD:
                field = <Time24hInput config={fieldConfig}/>;
                break;

            case VALUE_INPUT_FIELD:
                field = <ValueInput config={fieldConfig}/>
                break;

            case REMEMBER_SECTION:
                field = <RememberInfo config={fieldConfig}/>
                break;

            case SELECTOR_INPUT_FIELD:
                field = <Selector config={fieldConfig}/>
                break;

            case ADDITIONAL_INFO_DIV:
                field = <AdditionalInfo config={fieldConfig}/>;
                break;

            case PAYMENT_METHOD_FIELD:
                field = <PaymentType config={fieldConfig}/>;
                break;
        }
        return field;
    }

    return (
        <div className="section border rounded-3 py-4 px-5 shadow">
            <div className="row g-3">
                {getSectionFields(sectionFieldsJSON)}
            </div>
        </div>
    )
}

export default SectionWrapper;

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addMinutesToTime} from "../../../util/defaultTimeHelper";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../../reducers/expectedDeliveryDateReducer";

const DynamicExpectedDateTime = ({timeDifference}) => {
    const pickupTime = useSelector(state => state.expectedPickupTime);
    const dispatch = useDispatch();

    useEffect(() => {
        let {date, time} = addMinutesToTime(pickupTime, timeDifference);
        dispatch({
            type: HANDLER_EXPECTED_DELIVERY_TIME,
            payload: time
        });

        dispatch({
            type: HANDLER_EXPECTED_DELIVERY_DATE,
            payload: date
        });

        console.log(time, date);
    },[pickupTime]);

    return <></>;
}

export default DynamicExpectedDateTime;

export const storeInCache = (itemName, targetItem) => {
    localStorage.setItem(itemName, targetItem);
}

export const getFromCache = (targetItemName) => {
    return localStorage.getItem(targetItemName);
}

export const clearFromCache = (targetItemName) => {
    localStorage.removeItem(targetItemName);
}

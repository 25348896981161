import {postPaymentIntentGettingInfo} from "../../util/APIHelper/queries";
import {buildClientSecret} from "../../factory/paymentDetailsFactory";
import {CLIENT_SECRET} from "../../reducers/clientSecretReducer";

export const retrieveClientSecret = (data) => async  dispatch => {
    try {
        let response = await postPaymentIntentGettingInfo(data);

        if (response.data.secret) {
            let clientSecret = buildClientSecret(response.data.secret);
            dispatch({
                type: CLIENT_SECRET,
                payload: clientSecret
            })
        }
    }catch (e) {
        console.error(e);
        dispatch({
            type: CLIENT_SECRET,
            payload: null
        })
    }
}

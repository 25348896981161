import React from "react";
import {useSelector} from "react-redux";

const PaidFormLogoSection = ({showEdit, backToEdit}) => {
    const companyDetails = useSelector(state => state.companyDetails);

    if (companyDetails == null) return <></>;

    const companyLogo = companyDetails.getCompanyLogo();
    const companyName = companyDetails.getCompanyName();
    return (
        <div className="container-fluid bg-white w-100 p-0 logoBarDiv">
            <div className="container-md">
                <div className="logoBar col-sm-6 container-md">
                    {showEdit && <div className="btnEdit mx-2" onClick={backToEdit}>
                        <i className="bi bi-chevron-left"></i>
                    </div>}
                    {!showEdit && <div></div>}
                    <div className="d-flex justify-content-start flex-direction-row">
                        {companyLogo && <div className="d-flex align-items-center m-1">
                            <img src={companyLogo} alt={companyName}
                                 style={{height: "40px", width: "40px", borderRadius: "5px"}}/>
                        </div>}
                        <div className="d-flex align-items-center ms-1 lh-1">
                            <span className="fs-5 fw-bold">{companyName}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaidFormLogoSection;
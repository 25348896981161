import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE,
    LAYOUT_TANGLE_WITH_NEXT,
    sectionTitleJSONCreator, SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD,
    TEXTAREA_FIELD,
    TIME_INPUT_FIELD,
} from "../../util/formBuilderHelper";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {useDispatch} from "react-redux";
import {findCompanyDetails} from "../../actions/companyDetailsAction";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {useTranslation} from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {HANDLER_ITEM_TYPE} from "../../reducers/itemTypeReducer";
import {courierEmailTemplate1} from "../../util/emailTemplates/courierEmailTemplate1";
import {ADDRESS_INPUT_WITH_W3W} from "../common/components/addressInputWithW3W";
import {HANDLER_RESTAURANT_EMAIL} from "../../reducers/restaurantEmailReducer";
import {HANDLER_ADDITIONAL_ADDRESS} from "../../reducers/additionalAddressInfo";
import {HANDLER_AREA_NAME} from "../../reducers/customerAreaNameReducer";
import {HANDLER_BLOCK_NUMBER} from "../../reducers/customerBlockNumberReducer";
import {HANDLER_FLAT_NUMBER} from "../../reducers/customerFlatNumberReducer";
import {HANDLER_ROAD_NUMBER} from "../../reducers/customerRoadNumberReducer";
import {HANDLER_VILLA_NUMBER} from "../../reducers/customerVillaNumberReducer";


const CourierForm16 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "John", true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, t(FORM_LOCALE_KEYS.EMAIL), 'example@gmail.com', false, LAYOUT_DEFAULT, HANDLER_RESTAURANT_EMAIL),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.PICK_UP_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
                fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_TIME),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVERY_INFORMATION)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.NAME), "ex. John Doe", true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.RECIPIENT_PHONE_NUMBER), '(000) 000-00-00', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.DELIVERY_ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(TEXT_INPUT_FIELD, t("Flat Number"), "5A", false, LAYOUT_DEFAULT, HANDLER_FLAT_NUMBER),
                fieldJSONCreator(TEXT_INPUT_FIELD, t("Villa Number"), "167", false, LAYOUT_DEFAULT, HANDLER_VILLA_NUMBER),
                fieldJSONCreator(TEXT_INPUT_FIELD, t("Road Number"), "Street 3", false, LAYOUT_DEFAULT, HANDLER_ROAD_NUMBER),
                fieldJSONCreator(TEXT_INPUT_FIELD, t("Block Number"), "Block X", false, LAYOUT_DEFAULT, HANDLER_BLOCK_NUMBER),
                fieldJSONCreator(TEXT_INPUT_FIELD, t("Area Name"), "Area Y", false, LAYOUT_DEFAULT, HANDLER_AREA_NAME),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.ITEM_AND_SERVICE_TYPE)),
            sectionFields: [
                fieldJSONCreator(SELECTOR_INPUT_FIELD, t(FORM_LOCALE_KEYS.ITEM_TYPE), '', true, LAYOUT_DEFAULT, HANDLER_ITEM_TYPE,
                    [
                        {label: "Food", value: "Food", selected: true}, 
                        {label: "Product", value: "Product"}
                    ]),
                fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.ADDITIONAL_NOTE), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION)
            ]

        }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    });

    useEffect(() => {

    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={courierEmailTemplate1}/>
        </>
    )
}

export default CourierForm16;

import {orderNumberGenerator} from "../util/formBuilderHelper";

export const HANDLER_ORDER_NUMBER = "HANDLER_ORDER_NUMBER";

export default (state = orderNumberGenerator(), action) => {
    switch (action.type) {
        case HANDLER_ORDER_NUMBER:
            return action.payload;
        default:
            return state;
    }
}

import React, { useEffect } from "react";
import "../../../stylesheets/paidForm.css";
import CustomOrderDetailsInputBuilder from "./customOrderDetailsInputBuilder";
import CustomOrderPaymentPageBuilder from "./customOrderPaymentPageBuilder";
import { findCompanyDetails } from "../../../actions/companyDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaidFormLogoSection from "../../common/paidComponents/paidFormLogoSection";
import { HANDLER_ORDER_NUMBER } from "../../../reducers/orderNumberReducer";
import { HANDLER_RESTAURANT_NAME } from "../../../reducers/restaurantNameReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../../reducers/restaurantAddressReducer";
import { HANDLER_EXPECTED_PICKUP_TIME } from "../../../reducers/expectedPickupTimeReducer";
import { HANDLER_CUSTOMER_NAME } from "../../../reducers/customerNameReducer";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../../reducers/customerPhoneNumberReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../../reducers/customerAddressReducer";
import { HANDLER_EXPECTED_DELIVERY_TIME } from "../../../reducers/expectedDeliveryTimeReducer";
import { HANDLER_EXPECTED_DELIVERY_DATE } from "../../../reducers/expectedDeliveryDateReducer";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../../reducers/deliveryInstructionReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../../reducers/customerEmailReducer";
import { HANDLER_TIPS } from "../../../reducers/tipsReducer";
import { HANDLER_DELIVERY_FEE } from "../../../reducers/deliveryFeeReducer";
import { HANDLER_PAYMENT_METHOD } from "../../../reducers/paymentMethodReducer";
import i18next from "i18next";
import { useStripePaidForm } from "../../customHooks/stripePaidFormHook";
import StripeNotConnected from "../../common/paidComponents/stripeNotConnected";

const configJSON = [HANDLER_ORDER_NUMBER, HANDLER_RESTAURANT_NAME, HANDLER_RESTAURANT_PHONE_NUMBER, HANDLER_RESTAURANT_ADDRESS, HANDLER_EXPECTED_PICKUP_TIME, HANDLER_CUSTOMER_NAME, HANDLER_CUSTOMER_PHONE_NUMBER, HANDLER_CUSTOMER_EMAIL, HANDLER_CUSTOMER_ADDRESS, HANDLER_EXPECTED_DELIVERY_TIME, HANDLER_EXPECTED_DELIVERY_DATE,
    HANDLER_DELIVERY_INSTRUCTION, HANDLER_TIPS, HANDLER_DELIVERY_FEE, HANDLER_PAYMENT_METHOD]

// for italiagofood@gmail.com, Bemyrider PRO

const CustomPaidForm = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const companyInfo = useSelector(state => state.companyDetails);
    const [stripeDetails, isEstimating, estimationComplete, estimate, resetEstimation] = useStripePaidForm(companyInfo);

    const showPayment = estimationComplete;
    const showSpinner = isEstimating;

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, [params.id])

    useEffect(() => {
        if (companyInfo !== null)
            i18next.changeLanguage(companyInfo.getLanguage());
    }, [companyInfo?.getLanguage()]);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        estimate();
    }

    const handleBackToEdit = (ev) => {
        ev.preventDefault();
        resetEstimation();
    }

    if (stripeDetails === null || companyInfo === null) return <></>;

    if(!stripeDetails.isConnected()) return (
        <StripeNotConnected>
            <CustomOrderDetailsInputBuilder visible={!showPayment} loading={showSpinner}/>
        </StripeNotConnected>
    );

    return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <PaidFormLogoSection showEdit={showPayment} backToEdit={handleBackToEdit}/>
            <div className="container-md">
                <form className={`col-lg-6 offset-lg-3 pb-5 ${showPayment ? "d-none" : ""}`} onSubmit={handleSubmit}>
                    <CustomOrderDetailsInputBuilder visible={!showPayment} loading={showSpinner}/>
                </form>
            </div>
            <div id="paymentSection" className={`container-md ${showPayment ? "" : "d-none"}`}>
                <div className="col-lg-6 offset-lg-3">
                    <CustomOrderPaymentPageBuilder configJSON={configJSON} visible={showPayment}/>
                </div>
            </div>
        </div>
    )
}

export default CustomPaidForm;

const EXTERNAL_FORM_KEYS = {
    ORDER_NUMBER: "common.orderNumber",
    PLACE_AN_ORDER: "common.placeAnOrder",
    ORDER_DETAILS: "common.orderDetails",
    ITEM_DETAILS: "common.itemDetails",
    ITEM_TYPE: "common.itemType",
    ITEMS: "common.items",
    SELECT_CATEGORY: "common.selectCategory",
    NAME: "common.name",
    DATE: "common.date",
    EMAIL: "common.email",
    PHONE_NO: "common.phoneNo",
    ADDRESS: "common.address",
    DELIVERY_INFORMATION: "common.deliveryInformation",
    DELIVER_TO: "common.deliverTo",
    DELIVERY_PHONE: "common.deliveryPhone",
    DELIVERY_ADDRESS: "common.deliveryAddress",
    DELIVERY_DATE: "common.deliveryDate",
    DELIVERY_TIME: "common.deliveryTime",
    CUSTOMER_INFORMATION: "common.customerInformation",
    FIRST_NAME: "common.firstName",
    LAST_NAME: "common.lastName",
    CUSTOMER_NAME: "common.customerName",
    COMPANY_NAME: "common.companyName",
    CUSTOMER_OR_RECEIVER_PHONE_NUMBER: "common.customerOrReceiverPhoneNumber",
    CUSTOMER_EMAIL: "common.customerEmail",
    DELIVERY_PERSON_OR_COMPANY_NAME: "common.deliveryPersonOrCompanyName",
    RECIPIENT_PHONE_NUMBER: "common.recipientPhoneNumber",
    PICK_UP_INFORMATION: "common.pickUpInformation",
    PICK_UP_FORM: "common.pickUpFrom",
    PICK_UP_PHONE: "common.pickUpPhone",
    PICK_UP_ADDRESS: "common.pickUpAddr",
    PICK_UP_DATE: "common.pickUpDate",
    PICK_UP_TIME: "common.pickUpTime",
    INFO_FOR_DRIVER: "common.infoForDriver",
    ORDER_PRICE: "common.orderPrice",
    DELIVERY_FEE: "common.deliveryFee",
    DELIVERY_TIPS: "common.deliveryTips",
    DRIVER_TIPS: "common.driverTips",
    CUSTOMER_PAYS: "common.customerPays",
    DELIVERY_INSTRUCTIONS: "common.deliveryInstructions",
    SPECIAL_INSTRUCTION: "common.specialInstruction",
    WRONG_INFO: "common.wrongInfo",
    PLACE_ORDER: "common.placeOrder",
    OKAY: "common.okay",
    REMEMBER_PICK_UP_INFO: "common.rememberPickUpInfo",
    SORRY: "common.sorry",
    WRONG_INPUT_MSG: "common.wrongInputMsg",
    INSERTION_FAILED_MSG: "common.insertionFailedMsg",
    SUCCESS: "common.success",
    THANKS_MSG: "common.thanksMsg",
    ENTER_LOCATION: "common.enterLocation",
    SPECIAL_INSTRUCTION_NOTE: "common.specialInstructionNote",
    STREET_ADDRESS: "common.streetAddress",
    SHIP_TO_ADDRESS: "extFactoryDirect.shipToAddress",
    REQ_SHIP_DAY: "extFactoryDirect.reqShipDate",
    ENTERED_BY: "extFactoryDirect.enteredBy",
    ITEM_AND_SERVICE_TYPE: "extOrderForm.itemAndServiceType",
    DOCUMENTS: "extOrderForm.documents",
    PARCELS: "extOrderForm.parcels",
    MEDICAL_ITEMS: "extOrderForm.medicalItems",
    OTHER_ITEMS: "extOrderForm.otherItems",
    ITEM_SIZE: "extOrderForm.itemSize",
    SMALL_ITEM: "extOrderForm.smallItem",
    MEDIUM_ITEMS: "extOrderForm.mediumItems",
    LARGE_ITEMS: "extOrderForm.largeItems",
    NUM_OF_ITEMS: "extOrderForm.numOfItems",
    SERVICE_TYPE: "extOrderForm.serviceType",
    SAME_DAY: "extOrderForm.sameDay",
    SAME_DAY_3_HOURS: "extOrderForm.sameDay3Hours",
    SAME_DAY_6_HOURS: "extOrderForm.sameDay6Hours",
    SAME_DAY_ANYTIME: "extOrderForm.sameDayAnytime",
    SAME_DAY_AFTER_HOURS: "extOrderForm.sameDayAfterHours",
    _24_HOURS: "extOrderForm.24hours",
    NEXT_DAY: "extOrderForm.nextDay",
    ADDITIONAL_NOTE: "extOrderForm.additionalNote",
    DELIVERY_TIME_OTHER_THEN_ASAP: "orderFormShort.deliveryTimeOtherThenASAP",
    RESTAURANT_NAME: "restaurantShortOrderFormA.restaurantName",
    RESTAURANT_CONTACT_NAME: "restaurantShortOrderFormA.restaurantContactName",
    BUSINESS_YOU_WANT_TO_PICK_UP_FROM: "orderForm3.businessYouWantToPickUpFrom",
    ADDRESS_OF_BUSINESS: "orderForm3.addressOfBusiness",
    ITEMS_FOR_PICK_UP_AND_DELIVER: "orderForm3.itemsForPickUpAndDeliver",
    TIME_TO_RECEIVE_DELIVERY_ORDER: "orderForm3.timeToReceiveDeliveryOrder",
    AUTHORIZE_COMPANY_TEXT: "orderForm3.authorizeCompanyText",
    AGREEMENT_TEXT: "orderForm3.agreementText",
    FULL_NAME_OF_PICKUP : "paidForm.fullNameOfPickup",
    PHONE_NUMBER_OF_PICKUP: "paidForm.phoneNumberOfPickup",
    STREET_ADDRESS_OF_PICKUP: "paidForm.streetAddressOfPickup",
    EXPECTED_PICKUP_TIME: "paidForm.expectedPickupTime",
    OPTIONAL: "paidForm.optional",
    FULL_NAME_OF_RECIPIENT: "paidForm.fullNameOfRecipient",
    PHONE_NUMBER_OF_RECIPIENT: "paidForm.phoneNumberOfRecipient",
    EMAIL_ADDRESS_OF_RECIPIENT: "paidForm.emailAddressOfRecipient",
    STREET_ADDRESS_OF_RECIPIENT: "paidForm.streetAddressOfRecipient",
    EXPECTED_DELIVERY_DATE: "paidForm.expectedDeliveryDate",
    EXPECTED_DELIVERY_TIME: "paidForm.expectedDeliveryTime",
    CHECKOUT: "paidForm.checkout",
    PICKUP_DETAILS: "paidForm.pickupDetails",
    DELIVERY_DETAILS: "paidForm.deliveryDetails",
    ORDER_SUMMERY: "paidForm.orderSummery",
    DISTANCE: "paidForm.distance",
    DELIVERY_NOTE: "paidForm.deliveryNote",
    TOTAL: "paidForm.total",
    SECURE_PAYMENT: "paidForm.securePayment",
    SECURE_PAYMENT_WITH_STRIPE: "paidForm.securedPaymentWithStripe",
    DISTANCE_ABOVE_LIMIT: "paidForm.distanceAboveLimit"
}

export default EXTERNAL_FORM_KEYS;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    EMAIL_INPUT_FIELD,
    fieldJSONCreator,
    LAYOUT_DEFAULT, REMEMBER_SECTION, sectionTitleJSONCreator, TEXT_INPUT_FIELD,
    TEXTAREA_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { courierEmailTemplate1 } from "../../util/emailTemplates/courierEmailTemplate1";
import { ADDRESS_INPUT_WITH_W3W } from "../common/components/addressInputWithW3W";
import { HANDLER_ITEM_TYPE } from "../../reducers/itemTypeReducer";
import { HANDLER_ITEM_SIZE } from "../../reducers/itemSizeReducer";
import { HANDLER_SERVICE_TYPE } from "../../reducers/serviceTypeReducer";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../reducers/customerEmailReducer";
import { HANDLER_REMEMBER_INFO } from "../../reducers/rememberInfoReducer";

// for josem.lopez@glidewelldental.mx, Glidewell

const OrderForm21 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator("Información del consultorio"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Nombre completo del doctor", "ex. John Doe", true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME),
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, "Dirección de recogida", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Información de entrega - Glidewell"),
            sectionFields: [
                fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, "Dirección de Glidewell", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Número telefónico del consultorio (para notificación)", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER, null, true),
                fieldJSONCreator(EMAIL_INPUT_FIELD, "Correo electrónico del consultorio (para notificación)", 'example@gmail.com', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL, null, true),
                fieldJSONCreator(REMEMBER_SECTION, "Recordar información de entrega", '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Tipo de servicio y artículo"),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Número de cuenta Glidewell", "", true, LAYOUT_DEFAULT, HANDLER_SERVICE_TYPE),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Material a trabajar", '', true, LAYOUT_DEFAULT, HANDLER_ITEM_SIZE),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Órgano dentario", '', true, LAYOUT_DEFAULT, HANDLER_ITEM_TYPE),
                fieldJSONCreator(TEXTAREA_FIELD, "Comentarios de la recolecta y datos del paciente", t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), true, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
            ]
        },
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={courierEmailTemplate1}/>
        </>
    )
}

export default OrderForm21;

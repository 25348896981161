import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";

import {
    fieldJSONCreator,
    LAYOUT_DEFAULT,
    LAYOUT_HALF_SPACED_SINGLE, REMEMBER_SECTION, sectionTitleJSONCreator,
    SELECTOR_INPUT_FIELD,
    TEXT_INPUT_FIELD, TEXTAREA_FIELD,
    TIME_INPUT_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { HANDLER_ORDER_NUMBER } from "../../reducers/orderNumberReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { useTranslation } from "react-i18next";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { RestaurantEmailTemplateSpecial } from "../../util/emailTemplates/restaurantEmailTemplateSpecial";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";
import { HANDLER_EXPECTED_PICKUP_TIME } from "../../reducers/expectedPickupTimeReducer";
import { HANDLER_CUSTOMER_NAME } from "../../reducers/customerNameReducer";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_REMEMBER_INFO } from "../../reducers/rememberInfoReducer";
import { ADDRESS_INPUT_WITH_W3W } from "../common/components/addressInputWithW3W";
import { HANDLER_RESTAURANT_CONTACT_NAME } from "../../reducers/restaurantContactNameReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_ITEM_TYPE } from "../../reducers/itemTypeReducer";
import { HANDLER_SERVICE_TYPE } from "../../reducers/serviceTypeReducer";
import { HANDLER_PAYMENT_METHOD } from "../../reducers/paymentMethodReducer";

// for edgardoarrieta1@gmail.com

const OrderForm30 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
const formConfigJson = [
    {
        sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_FORM)),
        sectionFields: [
            fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.RESTAURANT_NAME), '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, null, true),
            fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.RESTAURANT_CONTACT_NAME), 'John Doe', false, LAYOUT_DEFAULT, HANDLER_RESTAURANT_CONTACT_NAME, null, true),
            fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.PHONE_NO), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER, null, true),
            fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true),
            fieldJSONCreator(REMEMBER_SECTION, t(FORM_LOCALE_KEYS.REMEMBER_PICK_UP_INFO), '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO)
        ]
    },
    {
        sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
        sectionFields: [
            fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.ORDER_NUMBER), 'ex. 1234', true, LAYOUT_DEFAULT, HANDLER_ORDER_NUMBER),
            fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.CUSTOMER_NAME), 'ex. John Doe', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
            fieldJSONCreator(TEXT_INPUT_FIELD, t(FORM_LOCALE_KEYS.CUSTOMER_OR_RECEIVER_PHONE_NUMBER), '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
            fieldJSONCreator(ADDRESS_INPUT_WITH_W3W, t(FORM_LOCALE_KEYS.ADDRESS), t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
            fieldJSONCreator(TEXTAREA_FIELD, t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION), t(FORM_LOCALE_KEYS.SPECIAL_INSTRUCTION_NOTE), false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
            fieldJSONCreator(TEXT_INPUT_FIELD, "Descripción del producto", '', true, LAYOUT_DEFAULT, HANDLER_ITEM_TYPE),
            fieldJSONCreator(TEXT_INPUT_FIELD, "Número de comprobante de pago", '', true, LAYOUT_DEFAULT, HANDLER_SERVICE_TYPE),
            fieldJSONCreator(SELECTOR_INPUT_FIELD, "Método de Pago", '', true, LAYOUT_DEFAULT, HANDLER_PAYMENT_METHOD, [
                {label: "Efectivo", value: "cash"},
                {label: "Tarjeta", value: "credit_card"},
                {label: "En línea", value: "online"},
            ]),
            fieldJSONCreator(TIME_INPUT_FIELD, t(FORM_LOCALE_KEYS.PICK_UP_TIME), '', true, LAYOUT_HALF_SPACED_SINGLE, HANDLER_EXPECTED_PICKUP_TIME),
        ]
    }
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    })

    const template = onlyDispatcherMail(RestaurantEmailTemplateSpecial);

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={formConfigJson}
                             emailTemplateFunc={template}/>
        </>
    )
}

export default OrderForm30;

export const HANDLER_VILLA_NUMBER = "HANDLER_VILLA_NUMBER";

export default (state = "", action) => {
    switch (action.type) {
        case HANDLER_VILLA_NUMBER:
            return action.payload;
        default:
            return state;
    }
}
